/*

binary-to-text encoding
// รองรับ Unicode แล้ว

// creates a Base64-encoded ASCII string
btoa = binary to ASCII

decodes a Base64-encoded string
atob = ASCII to binary

ใช้คำสั่ง
encodeURIComponent
decodeURIComponent

แปลงเป็น Unicode
และกลับเป็น Unicode

*/

export function encrypt(plainString){

  return window.btoa(
          encodeURIComponent(plainString)
          )

}


export function decrypt(encodedString){

  let _plainText = "";
  if (encodedString != undefined && encodedString != ""){
      _plainText = decodeURIComponent(
                      window.atob(encodedString)
                    );
  }

  return _plainText;
}

