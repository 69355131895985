import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import VueSocialSharing from 'vue-social-sharing'

const _app = createApp(App)

_app.config.globalProperties.$GBBlogsData = {}
_app.use(VueSocialSharing)
_app.use(router)

_app.mount('#app')

