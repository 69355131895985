<template>
  <div class="pagenotfound">
    <h1 class="h1-big1">About</h1>
    <h1 class="h1-big2">เกี่ยวกับ</h1>
    <p>Dynamic Website</p>
    <p>เว็บไซต์นี้ (FrontEnd) ถูกพัฒนาด้วยเครื่องมือ ที่มีชื่อว่า VueJs 3 (วิว-เจ-เอส-สาม)</p>
    <p>โดย วรเพชร  เรืองพรวิสุทธิ์</p>
    <p>เผยแพร่เมื่อ 1 ตุลาคม 2565</p>
    <br/>
    <p>
      <router-link to="/"> กลับหน้าแรก Home </router-link>
    </p>
  </div>
</template>


<style scoped>

.pagenotfound {
  margin: auto;
  text-align: center;
  min-height: 420px;
}
.h1-big1{
  font-size: 24vh;
  color: brown;
}

.h1-big2{
  font-size: 6vh;
  color: rgb(20, 5, 105);
}

a{
  margin: 10px;
  padding: 8px;
  border-radius: 8px;
  outline: 5px solid rgba(4, 91, 253, 0.5);
  outline-offset: 0px;
  color: black;
}

a:hover{
  margin: 10px;
  padding: 8px;
  border-radius: 8px;
  outline: 5px solid #f86dbe;
  outline-offset: 0px;
  background-color: #f8cfe7;
}

</style>