<template>
  <CpnDataAuthor @cbDataLoaded="getAuthorDataLoaded" />
  <div class="container">
  <div class="row">
        <div class="column1">
            <img
                v-bind:src="calc_author_avatar_url"
                alt="author-image"
                class="author-image"
                />
        </div>

        <div class="column2">
            <span class="author-name" >{{ AuthorData.author_name }}</span>
            <p class="author-description">{{ AuthorData.author_description }}</p>
        </div>

        <div class="column3">
        </div>
    </div>
    <br />
  </div>

</template>

<script>
    import apiConfig from './api-config';
    import CpnDataAuthor from './cpn-data-author.vue';

    export default {
      data() {
        return {
          AuthorData : Object,
        }
      },

    computed: {
        calc_author_avatar_url() {
            return apiConfig.URL_IMG_AUTHOR() + "/" + this.AuthorData.author_avatar_url;
        },
      },
      methods: {
      getAuthorDataLoaded(data){
                this.AuthorData = data;
                //console.log('AuthorData',this.AuthorData);
            },
      },
    components: { CpnDataAuthor }
}
</script>

<style scoped>

.container{
  margin: auto;
  text-align: center;
  width: 80%;
}

.column1 {
    float: left;
    width: 12%;
    height: 75px;
    background-color: transparent;
}
.column2 {
    float: left;
    width: 60%;
    padding: 0px;
    height: 75px;
    background-color: #fff;
}

.column3 {
    float: left;
    width: 28%;
    padding: 0px;
    height: 75px;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.author-name{
    padding-left: 10px;
    font-size: 20px;

}

.author-image{
  height: 75px;
}

</style>