<template>
    <CpnDataUser ref="UserDataCPN" @cbDataLoaded="getUserDataLoaded" />

    <CpnDialogService ref="DialogService"
            @cbOkClick="getCallbackOkClick"
            @cbCancelClick="getCallbackCancelClick"
            @cbClearTokenOK="getClearTokenOK"
        />

    <div class="wrapper">
            <div class="overlay-box-member" :style ="customStyle" alt="Member info">

                <div class="container-member">

                    <div class="header-menu">
                        <a  @click="btnUserInfoClick">
                            <div class="header-menu-member-text"> ข้อมูลสมาชิก </div>
                        </a>
                    </div>

                    <div class="content-menu">
                        <div class="menu-signout-member" v-if="UserData.user_signedIn === true" @click="btnSignOutClick">
                            ลงชื่อออก (สมาชิก)
                        </div>
                        <div v-else class="menu-signin-member">
                            <CpnAppLink :to="{ path: '/signin-member'}"> ลงชื่อเข้า (สมาชิก) </CpnAppLink></div>
                    </div>

                    <div class="footer-menu" v-show="UserData.jwtToken != ''">
                            <a  @click="btnClearToken">
                                <div class="footer-menu-member-text">เคลีย Token</div>
                            </a>
                            <hr>
                            <div class="menu-signup-member" >
                                <CpnAppLink :to="{ path: '/signup-member'}"> สมัครสมาชิก </CpnAppLink>
                            </div>

                    </div>

                </div>
            </div>
    </div>

</template>

<script>

    import CpnAppLink from './cpn-applink.vue';
    import CpnDataUser from './cpn-data-user.vue';
    import libCommon, { RandomStr } from './lib-common';
    import CpnDialogService from './cpn-dialog-service.vue';

    export default {
        emits:['cbClearTokenOK'],
        components:{ CpnAppLink, CpnDataUser, CpnDialogService },
        props:{
            text: {type: String ,default: "ข้อมูลสมาชิก"},
            boxWidth: {type: String ,default: "200px"},
            boxHeight: {type: String ,default: "250px"},
            background: {type: String,default: '#fff'},
            borderLeft: {type: String,default: '1px solid #023e7d'},
            borderRight: {type: String,default: '1px solid #023e7d'},
            borderRadius: {type: String,default: '0px 0px 0px 0px'},
            position_top: {type: String,default: '10%'},
            position_right: {type: String,default: '10px'},
            opacity: {type: Number,default: 0.9}
        },
        data() {
            return {
                UserData : Object,
            }
        },
        methods:{
            btnUserInfoClick(){
                console.log('btnUserInfoClick' , this.UserData.user_id );
                if (libCommon.isTrue(this.UserData.user_signedIn) == true){
                    this.$router.push({name: "userinfo-member" ,parames:{user_id: "UserData.user_id"} });
                } else {
                    this.$router.push({name: "signin-member" , });
                }
            },
            btnSignOutClick(){
                this.$refs.DialogService.showDlgConfirm("ลงชื่อออก (สมาชิก)","ยืนยันลงชื่อออก ?<br />(เป็นการเปลี่ยนสถานะ ของการทำงาน)");
            },
            btnClearToken(){
                this.$refs.DialogService.showDlgConfirm("เคลีย Token","ยืนยันเคลีย Token ?",'cbClearTokenOK');
            },
            getCallbackOkClick(){
                //console.log('getCallbackOkClick');
                this.$refs.UserDataCPN.setValue('user_signedIn',false);
                console.log('setValue-finished');

                this.$router.push({ path: "/#"+ RandomStr() });
                this.$refs.UserDataCPN.loadFromLocal();
            },
            getCallbackCancelClick(){
                console.log('getCallbackCancelClick');
                this.$refs.DialogService.hideDlg();
            },
            getClearTokenOK(){
                console.log('getClearTokenOK');
                sessionStorage.removeItem('jwtToken');
                //localStorage.removeItem('udata');
                //this.$refs.UserDataCPN.loadFromLocal();
            },
            getUserDataLoaded(data){
                this.UserData = data;
                //console.log('getUserDataLoaded',data);
            },
        },
        computed: {
            customStyle () {
                return {
                    'position': 'fixed',
                    'z-index': 9999,
                    'background': this.background,
                    'height': this.boxHeight,
                    'width': this.boxWidth,
                    'right': this.position_right,
                    'top': this.position_top,
                    'opacity': this.opacity,
                    'border-left': this.borderLeft,
                    'border-right': this.borderRight,
                    'border-radius': this.borderRadius,
                    'color': 'black',
                    'cursor': 'pointer',
                    'user-select': 'none',
                }
            } ,
        },
    }
</script>


<style scoped>

.header-menu-member-text{
    color: white;
    font-size: 16px;
    text-align: center;
    align-content: center;
    padding: 5px;
    background-color: rgb(4, 46, 138);
    border: 1px solid rgb(152, 183, 250);
}

.header-menu-member-text:hover{
    color: #002855;
}


.menu-signup-member{
    font-size: 16px;
    text-align: center;
    align-content: center;
    padding: 5px;
    grid-area: content-menu-member;
    background-color: rgb(8, 113, 252);
    border: 1px solid rgb(152, 183, 250);
}


.menu-signin-member{
    font-size: 16px;
    text-align: center;
    align-content: center;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    grid-area: content-menu-member;
    background-color: rgb(6, 184, 115);
    border: 1px solid rgb(4, 114, 72);

}


.menu-signout-member{
    font-size: 16px;
    text-align: center;
    align-content: center;
    padding: 5px;
    grid-area: content-menu-member;
    background-color: rgb(238, 119, 7);
    border: 1px solid rgb(255, 240, 175);
    color: #fff;
}


.menu-signup-member:hover{
    background-color: #002855;
}


.menu-signin-member:hover{
    background-color: #002855;
}


.menu-signout-member:hover{
    background-color: #002855;
}


.header-menu-member-text:hover{
    background-color: rgb(255, 180, 0);
}


.overlay-box-member{
    background-repeat: no-repeat;
    border: 2px solid #023e7d;
    min-height: 125px;
    max-height: 178px;
}


.overlay-container-member {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 0.5fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}



.container-member {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    align-content: center;
    text-align: center;
    grid-template-areas:
    "header-menu"
    "content-menu"
    "footer-menu";
}
.header-menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px;
    grid-template-areas:". .";
    grid-area: header-menu;
}

.content-menu {
    grid-area: content-menu;
    font-size: 16px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    align-content: center;
    background-color: rgb(116, 182, 253);
    border: 1px solid rgb(116, 182, 253);
}

.footer-menu {
    grid-area: footer-menu;
    font-size: 16px;
    padding: 5px;
    text-align: center;
    align-content: center;
    background-image: linear-gradient(rgb(74, 151, 252), rgb(3, 57, 128));
    border: 1px solid rgb(152, 183, 250);
    color: white;
}

.footer-menu-member-text{
    color: black;
}

.footer-menu-member-text:hover{
    color: #002855;
}

.footer-menu:hover{
    background-image: linear-gradient(rgb(255, 180, 0), rgb(3, 57, 128));
}
</style>