<template>
    <CpnAppNav />

    <header class="app-header">
            <slot name="header"></slot>
    </header>

        <article class="app-article">
            <router-view :key="$route.fullPath"></router-view>
        </article>

        <div class="div-reserved-layout">
        </div>

        <slot name="footer"></slot>

        <footer class="app-footer">
            <CpnAppFooter />
        </footer>

</template>

<script>

    import CpnAppNav from '@/components/cpn-nav-main.vue';
    import CpnAppFooter from '@/components/cpn-page-footer.vue';

    export default {
        components: { CpnAppNav, CpnAppFooter},
    };

</script>