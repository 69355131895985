<template>
    <div>
        <CpnDataAuthor ref="AuthorDataObj"
            @cbDataLoaded="getAuthorDataLoaded" />
        <CpnDialogService ref="DialogService" @cbOkClick="getDlgOkClick" @cbPopUpClosed="getPopUpClosed"/>

        <h2> Blog Control จัดการโพสต์ของผู้แชร์ข้อมูล </h2>

        <CpnBannerAuthorMini />

        <div class="block-button">
            <button type="button" class="btn-new" @click="doNewBlog"> สร้างใหม่ </button>
            <button type="button" v-if="selectedData['blog_id'] > 0" class="btn-edit" @click="doEditBlog"> แก้ไข </button>
            <button type="button" v-if="selectedData['blog_id'] > 0" class="btn-delete" @click="doDeleteBlog"> ลบ </button>
            <button type="button" class="btn-new" @click="doRefreshData"> Refresh Data </button>
        </div>

        <CpnLoader3ball
            :loading="pageloading"
            />

        <div class="grid-data">
            <CpnGridData
                :columns="mycolumns"
                :datas="displayDatas"
                :style="'width: 100%; height: 400px;'"
                @cellclick="getCellClick"
                />
        </div>
        <br />
    </div>
</template>

<script>
    import apiConfig from './api-config';
    import CpnBannerAuthorMini from './cpn-banner-author-mini.vue';
    import CpnGridData from './cpn-grid-data.vue';
    import CpnDataAuthor from './cpn-data-author.vue';
    import { HttpDelete, HttpGet } from './lib-http-helper';
    import { cnvStrToThaiDate, } from './lib-common';
    import CpnDialogService from './cpn-dialog-service.vue';
    import CpnLoader3ball from './cpn-loader-3ball.vue';


    export default {
        components: { CpnBannerAuthorMini, CpnGridData, CpnDataAuthor, CpnDialogService, CpnLoader3ball, },
        data() {
            return {
                AuthorData: Object,
                mycolumns: [
                        { headerName: "วันที่",field: "blog_thaidate"},
                        { headerName: "หมวดหมู่(หลัก)",field: "blog_group_owner"},
                        { headerName: "หมวดหมู่(รอง)",field: "blog_group_code"},
                        { headerName: "Blog ID",field: "blog_id"},
                        { headerName: "หัวข้อ",field: "blog_title"},
                        { headerName: "หัวข้อ(ย่อย)",field: "blog_subtitle"},
                        { headerName: "รับชม",field: "blog_count_views"},
                        ],
                displayDatas: [],
                selectedData: [],
                pageloading: false,
            }
        },
        methods: {
            getCellClick(rowData){
                console.log('click cell >> ',rowData);
                this.selectedData = rowData;
                console.log('this.selectedData >> ',this.selectedData);
                const _key_id = this.$refs.AuthorDataObj.getValue('keyedit');
                console.log('old keyEdit >> ',_key_id);
            },
            getAuthorDataLoaded(data){
                this.AuthorData = data;
                console.log('blog-AuthorData',this.AuthorData);
            },
            async getBlogByAuthor(){
                this.pageloading = true;
                await HttpGet(apiConfig.URL_BASE_API()+apiConfig.EndPoint_BlogsByAuthor+'/'+this.AuthorData.author_id)
                    .then((response)=>{
                        //console.log('getBlogByAuthor',response);
                        this.pageloading = false;
                        if (response.status == 200){
                            this.displayDatas = [];
                            const _blogDatas = response.data;

                            _blogDatas.forEach((data,index) => {
                                this.displayDatas[index]= {
                                    'blog_date': data['blog_date'],
                                    'blog_thaidate': cnvStrToThaiDate(data['blog_date']),
                                    'blog_group_code': data['blog_group_code'],
                                    'blog_group_owner': data['blog_group_owner'],
                                    'blog_id': data['blog_id'],
                                    'blog_title': data['blog_title'],
                                    'blog_subtitle': data['blog_subtitle'],
                                    'blog_count_views': data['blog_count_views'],
                                }
                                //console.log(data);
                            });

                        }
                });
            },
            doNewBlog(){
                this.$router.push({path:'/blog-new'});
            },
            doRefreshData(){
                this.getBlogByAuthor();
            },
            doEditBlog(){
                let _blog_id = 0;
                if (this.selectedData['blog_id'] > 0){
                    const _key_id = this.$refs.AuthorDataObj.newkeyEdit();
                    console.log('doEdit-getkeyEdit',_key_id);

                    _blog_id = parseInt(this.selectedData['blog_id']);
                    this.$router.push({name:'blogedit',params:{blog_id: _blog_id , key_id: _key_id}});
                    //console.log('push-',_key_id);
                }
            },
            doDeleteBlog(){
                console.log('doDeleteBlog',this.selectedData);
                if (this.selectedData['blog_id'] > 0){
                    const _key_id = this.$refs.AuthorDataObj.newkeyDelete();
                    this.$refs.DialogService.showDlgConfirm("ลบโพสต์และข้อมูลที่เกี่ยวข้อง","ยืนยัน ลบโพสต์ <br />ID: "+
                        this.selectedData['blog_id']+"<br/>หัวข้อ: "+
                        this.selectedData['blog_title']+
                        "<br /> และข้อมูลที่เกี่ยวข้อง ?",{"key_id":_key_id});
                }
            },
            getDlgOkClick(data){
                console.log('click OK-Delete',data);
                HttpDelete(apiConfig.URL_BASE_API()+apiConfig.EndPoint_DeleteBlog+'/'+this.selectedData['blog_id']).then(()=>{
                    this.$refs.DialogService.showPopUp("ลบโพสต์และข้อมูลที่เกี่ยวข้อง [สำเร็จ]",1500,"DeleteOK");

                });
            },
            getPopUpClosed(trackValue){
                if (trackValue == "DeleteOK"){
                    this.getBlogByAuthor();
                }
            },
        },
        mounted() {

            if (this.AuthorData.author_id == undefined)
                {
                    console.log('redirect >> Invalid keyID');
                    this.$router.push('home')
                }
            this.getBlogByAuthor();
        },
}
</script>

<style lang="scss" scoped>
.block-container{
    position: flex;
    text-align: center;
    top: 120px;
    margin-top: 50px;
    width: 100%;
}

.grid-data{
    margin: auto;
    width: 90%;
    align-content: center;
}

.block-button{
    margin: auto;
    text-align: center;
    margin-bottom: 10px;
}

.btn-new{
    margin: 5px;
    padding: 10px;
    font-size: 16px;
}

.btn-edit{
    margin: 5px;
    padding: 10px;
    font-size: 16px;
}

.btn-delete{
    margin: 5px;
    padding: 10px;
    font-size: 16px;
}



</style>

<!--
author_id: "a93d6059cf9bd6c3442fc4fcbbfe7f2a3",
blog_id: 25651001125807,
blog_date: "2022-10-01",
author_avatar_url: "av01.png"
author_description: null
author_id: "a93d6059cf9bd6c3442fc4fcbbfe7f2a3"
author_name: "มือใหม่"
blog_can_comment: "Y"
blog_content: "-"
blog_count_views: 1
blog_date: "2022-10-01"
blog_group_code: "learning"
blog_group_owner: "python"
blog_id: 25651001125807
blog_image_url: "https://portswigger.net/cms/images/66/61/1cc4-article-220525-pypi-main.jpg"
blog_package_code: null
blog_playlist: null
blog_playlist_order: null
blog_recommend: "N"
blog_source: "-"
blog_status: "Y"
blog_subtitle: "ทดสอบ-ย่อย"
blog_tag: "ไพธอน"
blog_time: "2022-10-01 12:58:07.260762"
blog_title: "ทดสอบ"
create_datetime: "2022-10-01"
modify_datetime: null
 -->