<template>
    <CpnDialogService ref="DialogService" />
    <CpnDataUser ref="UserDataCPN" @cbDataLoaded="getUserDataLoaded" />
    <CpnOverlayBox
        />

    <CpnOverlayBox
        text="🢀 BACK"
        href= 'back()'
        position_bottom= "20%"
        background= '#FFFFCC'
        borderLeft= '20px solid #ffb703'
    />

    <div class="container-blogs">
        <!-- * if status == loading > Show loading * -->
        <CpnLoader3Ball :loading="pageloading"/>

        <!-- /* ข้อมูลมี 1 รายการ แต่มาใน Array ก็ Loop ไป */ -->
        <div v-for=" blog,index  in blog_datas" v-bind:key="index">

            <CpnBlockBlogDetail
                :blog_title="blog['blog_title']"
                :blog_subtitle="blog['blog_subtitle']"
                :blog_content= "blog['blog_content']"
                :blog_image_url="blog['blog_image_url']"
                :blog_id="blog['blog_id']"
                :blog_group_code="blog['blog_group_code']"
                :blog_date="blog['blog_date']"
                :blog_tag="blog['blog_tag']"
                :author_id="blog['author_id']"
                :author_name="blog['author_name']"
                :author_avatar_url="blog['author_avatar_url']"
            >

                <div class="cardend-footer">
                    <span>
                        <button class="blog-view-qty">{{ blog['view_count'] }} เยี่ยมชม</button>
                        <button class="blog-star-qty">{{ starCount }} &#9733;</button>
                        <button class="btn-star" @click="clickBlogDetailAddStar"> + ดาว &#9734;</button>
                    </span>

                    <span>
                        <button class="blog-comment-qty">{{ blog['comment_count'] }} ความคิดเห็น</button>
                        <button class="btn-comment" @click="clickBlogDetailNeedReply"> 💬 ความคิดเห็น </button>
                    </span>
                </div>

            </CpnBlockBlogDetail>

                <div v-if="showCommentEditor == true">

                <!-- // new Main-Comment // -->
                <CpnEditorComment
                    listenToEvent="cbPostReply0Finished"
                    :blog_id="blog['blog_id']"
                    :author_id="blog['author_id']"
                    :reply_to="0"
                    @cbPostReply0Finished="doOnPostMainCommentFinish"
                    @cbCommentPostCancel="doOnPostMainCommentCancel"
                />
                <!-- // END Main-Comment // -->
                </div>

        </div>


        <section class="wrapper">
            <!-- comment-list -->
            <h2 class="comments-header">รายการแสดงความคิดเห็น ::</h2>

                <div
                    v-for="comment,index in CommentDatas"
                    :key="index"
                >

                <!-- // All Comments // -->
                <CpnBlockCommentMain
                    :blog_id = "parseInt(comment['blog_id'])"
                    :blog_title = "comment['blog_title']"
                    :blog_subtitle = "comment['blog_subtitle']"
                    :blog_tag = "comment['blog_tag']"
                    :reply_to = "parseInt(comment['reply_to'])"
                    :author_id = "comment['author_id']"
                    :user_id = "comment['user_id']"
                    :user_name = "comment['user_name']"
                    :user_avatar_url = "comment['user_avatar_url']"
                    :comment_id = "parseInt(comment['comment_id'])"
                    :comment_item_no = "parseInt(comment['comment_item_no'])"
                    :create_datetime = "comment['create_datetime']"
                    :comment_approved = "comment['comment_approved']"
                    :comment_content = "comment['comment_content']"
                    :star_count="comment['star_count']"
                    :comment_count="comment['comment_count']"
                    @cbCommentPostCancel="doOnCommentToHide"
                />
                <!-- // END -- All Comments // -->

                </div>
        </section>

</div>

</template>

<script>
    import { HttpGet, HttpPost, ValidateToken } from "@/components/lib-http-helper";
    import apiConfig from "@/components/api-config";
    import CpnLoader3Ball from "@/components/cpn-loader-3ball.vue";
    import CpnOverlayBox from "@/components/cpn-menu-overlay-box.vue";
    import CpnBlockBlogDetail from "@/components/cpn-blockblog-detail.vue";
    import CpnBlockCommentMain from '@/components/cpn-block-comment-main.vue';
    import CpnDataUser from "@/components/cpn-data-user.vue";
    import CpnEditorComment from "@/components/cpn-editor-comment.vue";
    import CpnDialogService from "@/components/cpn-dialog-service.vue";

    export default {
        emits: ['cbCommentPostFinish','cbCommentPostCancel'],
        components: { CpnBlockBlogDetail, CpnBlockCommentMain, CpnLoader3Ball, CpnOverlayBox, CpnDataUser, CpnEditorComment, CpnDialogService },
        props: {
            blog_id: { type: String },
        },
        data() {
            return {
                UserData: Object,
                CommentDatas: Object,
                blog_datas: {},
                pageloading: false,
                blog_title: "" ,
                ReplyComment_id: 0,
                ReplyComment_itemNo :0,
                showCommentEditor: false,
                showSubCommentEditor: false,
                viewqty: 0,
                starCount: 0,
            };
        },
        computed:{

            calcblog_id(){
                return this.blog_datas[0]['blog_id']
            },

            calcautohr_id(){
                return this.blog_datas[0]['author_id']
            },
        },
        mounted() {

            //console.log("params", this.$route.params);
            //console.log("blog_id", this.blog_id);

            this.getAllData();
        },

        methods: {
            async getAllData(){
                await ValidateToken('blogs').then((success) => {
                    if (success){
                        this.pageloading = true;
                        this.getblog_item_detail(),
                        this.getMainComments();
                    }
                })
            },
            clickBlogDetailNeedReply(){
                //console.log("UserData.user_signIn", this.UserData.user_signedIn);
                if (this.UserData.user_signedIn == true){
                    this.showCommentEditor = !this.showCommentEditor;
                } else {
                    this.$refs.DialogService.showPopUp("ต้องเป็นสมาชิก / ลงชื่อเข้าใช้",1500);
                }
            },

            // funcGetPathToShare(blogID){
            //     return apiConfig.URL_SHARE_BLOG(blogID);
            // },

            async getblog_item_detail() {

                await HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_blog_item_detail+'/'+ this.blog_id,"getblog_item_detail ")
                    .then((response) => {
                        this.blog_datas = response.data;
                        console.log('this.blog_datas >> ',response.data);
                        if (response.status == 200){
                            if (response.data != []){
                                this.blog_title = response.data[0]['blog_title'];
                                this.starCount =  response.data[0]['star_count'];
                            }
                        }
                    });
            },

            async getMainComments() {

                await HttpGet(
                    apiConfig.URL_BASE_API() + apiConfig.EndPoint_GetCommentMain +'/'+ this.blog_id , "getMainComments-main"
                ).then((response) => {
                    console.log('getMainComments',response);
                    if (response.status == 200){
                        this.CommentDatas = response.data;
                    }

                    this.pageloading = false;
                });
            },

            getUserDataLoaded(data){
                //console.log('blog-loaded',data);
                this.UserData = data;
            },

            docbCommentPostFinish(){
                this.getMainComments();
            },
            doOnPostMainCommentFinish(){
                this.ReplyComment_id = 0;
                this.ReplyComment_itemNo = 0;
                this.getMainComments();
            },
            doOnPostMainCommentCancel(){
                this.ReplyComment_id = 0;
                this.ReplyComment_itemNo = 0;
                this.showCommentEditor = false;
            },
            doOnCommentToHide(){
                this.showSubCommentEditor = false;
            },
            ReplyThisComment(cId,cNo){
                const _Replythis = (this.ReplyComment_id == cId &&
                    this.ReplyComment_itemNo ==  cNo)

                return _Replythis;
            },
            async clickBlogDetailAddStar(){
                let formdata = new FormData();
                formdata.append("act_type",'BLOG');
                formdata.append("blog_id",parseInt(this.blog_id));
                formdata.append("comment_id",0);
                formdata.append("act_group",'STAR');
                formdata.append("user_id",this.UserData.user_id);

                await HttpPost(apiConfig.URL_BASE_API()+apiConfig.EndPoint_PostActivity,formdata)
                    .then((response) => {
                        console.log('star-count',response.data.count);
                        this.starCount = response.data.count;
                })
            }
        },
    };
</script>

<style >

.blog-image{
    width: 80%;
    margin: auto;
    align-content: center;
    text-align: center;
}

.wrapper {
  position: relative;
}

.overlay-box {
  position: fixed;
  z-index: 9999;
  background: lightgreen;
  width: 150px;
  left: 10px;
  bottom: 5%;
  height: 7em;
  opacity: 0.9;
}

.comment-container{
	border: 2px solid rgb(224, 221, 221);
	border-radius: 10px;
	-webkit-box-shadow: 4px 4px 7px 4px rgba(0,0,0,0.3);
	box-shadow: 4px 4px 7px 4px rgba(0,0,0,0.3);
	padding: 20px;
}

.cardend-footer{
    color: black;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.blog-star-qty{
    font-size: 1rem;
    border: 1px solid #7e8b9b;
    background: #c5cfda;
    color: black;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px 0px 0px 4px;
}

.btn-star{
    font-size: 1rem;
    border: 1px solid #415a77;
    background: #415a77;
    color: white;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
}

.blog-view-qty{
    font-size: 1rem;
    border: 1px solid #7e8b9b;
    background: #c5cfda;
    color: black;
    margin: 0px;
    margin-right: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
}
.blog-comment-qty{
    font-size: 1rem;
    border: 1px solid #7e8b9b;
    background: #c5cfda;
    color: black;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px 0px 0px 4px;
}

.btn-comment{
    font-size: 1rem;
    border: 1px solid #415a77;
    background: #415a77;
    color: white;
    margin: 0px;
    padding: 8.5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
}

.btn-share{
    font-size: 1rem;
    border: 1px solid #0353a4;
    background: #415a77;
    color: white;
    min-width: 120px;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    cursor: pointer;
}

.section-comment-footer{
    position:relative;
    bottom: -10px;
    left: 0px;
    width: 100%;
    margin-left: 30px;
}
</style>