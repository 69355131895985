<template>

    <CpnDataUser ref="UserDataCPN"
        @cbDataLoaded="getUserDataLoaded"
        />

    <CpnDialogService ref="DialogService" />

    <div class="wrapper">
        <form @submit.prevent="loginUser" class="signin-form" method="post">
            <div class="signin-container">
                <h1>ลงชื่อเข้าใช้งาน-สมาชิก</h1>

                <div class="container">
                    <label for="editemail"><b>Email</b></label>
                    <input v-model="user_email" type="email" placeholder="Enter Email"
                            id="email" name="editemail" required autofocus
                            >

                    <label for="psw"><b>Password</b></label>
                    <input v-model="user_psw" type="password" placeholder="Enter Password" name="psw" required>

                    <details><summary class="details-otp">รหัส OTP (เฉพาะผู้ที่สมัครใหม่ ล็อคอินครั้งแรก)</summary>
                        <label for="otp"><b>รหัส OTP จะถูกส่งไปยัง Email ตามที่ระบุไว้</b></label>
                        <input v-model="user_otp" type="password" placeholder="Enter OTP" name="otp">
                    </details>

                    <CpnImgCaptcha @cbNewImageCaptCHA="getCaptCHANewImage"  @cbInputTextChanged="getCaptCHAInputText" />

                    <div class="container-submit">
                        <button type="submit">ลงชื่อเข้าใช้งาน</button>
                    </div>
                </div>

                <div class="container-footer">
                    <button type="button" class="btncancel" @click="backToCurPage">ยกเลิก</button>

                    <button type="button" class="btnlostpsw" @click="forgotPassword">ลืมรหัสผ่าน</button>
                </div>

            </div>
        </form>

        <div class="div-reserved-layout">
        </div>
    </div>
</template>

<script>
    import apiConfig from '@/components/api-config';
    import { HttpPost } from '@/components/lib-http-helper';
    import { RandomStr } from '@/components/lib-common';
    import CpnDataUser from '@/components/cpn-data-user.vue';
    import CpnImgCaptcha from '@/components/cpn-img-captcha.vue';
    import CpnDialogService from '@/components/cpn-dialog-service.vue';

    export default {
        emits: ['cbNewImageCaptCHA','cbInputTextChanged','btnOkClick'],
        data() {
            return {
                user_email: "",
                user_psw: "",
                user_otp: "",
                CaptCHACodeGen: "",
                CaptCHAInputText: "",
                UserData : Object,
            };
        },
        methods: {
            backToCurPage() {
                this.$router.back();
            },
            forgotPassword(){
                this.$router.replace({ path: '/forgot-user' });
            },
            async loginUser() {
                if (this.CaptCHAInputText != this.CaptCHACodeGen){
                    this.$refs.DialogService.showDlgWarning("ระบบตรวจสอบ","ระบุข้อความไม่ตรงกับข้อความในภาพ");
                    return ;
                }

                let formdata = new FormData();
                formdata.append("user_email", this.user_email);
                formdata.append("user_password", this.user_psw);
                if (this.user_otp === ""){
                    this.user_otp = "NO_OTP"
                }
                formdata.append("user_otp", this.user_otp);

                //console.log("Post loginUser >> ");
                await HttpPost(apiConfig.URL_BASE_API() + apiConfig.EndPoint_LoginUser, formdata)
                    .then((response) => {
                        console.log("response >> ", response);
                        if (response.status == 200){
                        if (response.data["result"] == "success") {

                            console.log("Login >> OK",response);

                            const _data = {
                                "user_id": response.data["user_id"],
                                "user_name": response.data["user_name"],
                                "user_avatar_url": response.data["user_avatar_url"],
                                "user_email": response.data["user_email"],
                                "user_signedIn": true,
                                "user_signedUp": true,
                            }

                            this.$refs.UserDataCPN.saveToLocal(_data);

                            this.$router.push({ path: "/#"+ RandomStr(10) });

                        }
                        else {
                            this.showLoginFail = true;
                            this.user_email= "";
                            this.user_psw= "";
                            this.$refs.DialogService.showDlgError(
                                "ลงชื่อเข้าใช้งานไม่สำเร็จ !!",
                                "อีเมล หรือ รหัสผ่านไม่ถูกต้อง !!<br />"+
                                    "ตรวจสอบอีเมล หรือ รหัสผ่าน<br />ตรวจสอบทำการสมัครหรือยัง ?<br />"+
                                    "ตรวจสอบสถานะการสมัครอนุมัติหรือยัง ?<br />"+
                                    "ตรวจสอบรหัส OTP จาก Email");
                        }}
                    });
            },
            getCaptCHANewImage(code){
                this.CaptCHACodeGen = code;
            },
            getCaptCHAInputText(text){
                this.CaptCHAInputText = text;
            },
            getUserDataLoaded(data){
                this.UserData = data;
                //console.log("getUserDataLoaded-signin",data);
            },

        },
        components: { CpnDataUser, CpnImgCaptcha, CpnDialogService },

    }
</script>

<style lang="css" scoped>

.signin-form{
    margin-top: 50px;
}
.signin-container{
    display: block;
    margin:auto;
    padding: 20px;
    max-width: 50%;
    align-items: center;
    align-content: center;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px grey;
    background-color: #ddd;
}

input[type=email], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #04AA6D;
  font-size: 16px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 60%;
}

.container-submit{
    text-align: center;
}

button:hover {
  opacity: 0.8;
}

.container-footer{
    background-color:#f1f1f1;
    align-content: space-between;
    text-align: center;
}

.btncancel {
  width: 150px;
  padding: 10px 18px;
  background-color: #f44336;
}

.btnlostpsw {
  margin-left: 20px;
  width: 150px;
  padding: 10px 18px;
  background-color: #f4b136;
  color: black;
}


.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .btncancel {
     width: 100%;
  }
}

.details-otp{
    cursor: pointer;
    margin: 10px 0px 10px 0px;
    border: 1px solid rgb(201, 200, 200);
    padding: 4px;
}

.div-reserved-layout{
    min-height: 70px;
    height: auto;
    overflow: hidden;
}
</style>