<template>
    <!-- <BlogItemData
        :blog_id="blog_id"
        :blog_date="blog_date"
        :blog_time="blog_time"
        blog_group_code=""
        blog_group_owner=""
        blog_status=""
        blog_can_comment=""
        blog_tag=""
        blog_count_views=""
        blog_count_comments=""
        blog_source=""
    /> -->

    <!-- <router-link
            :to="{ name: `blogs.view`, params: { blog_id: blog_id } }"
        > -->
        <div class="myrow">
            <div class="mycol">
                <header class="blog-header">
                <!-- /* TITLE  */ -->
                    <h2> {{ blog_title }} </h2>
                <!-- /* AUTHOR  */ -->
                    <p class="blog-author">{{ author_name }} ,<span class="date">&nbsp;{{ showThaiDate(blog_date) }}</span></p>
                </header>
                <!-- /* รูปภาพ  */ -->
                <img
                        v-bind:src="blog_image_url"
                        alt="blog-image"
                        class="blog-image-main"
                    />
            </div>
        </div>
    <!-- </router-link> -->

            <!-- /* CONTENT  */ -->
            <div class="blog-content-subtitle" >
                <div class="blog-content-markdown" >
                    <MdEditor :modelValue="blog_subtitle"
                                language="en-US"
                                previewTheme="vuepress"
                                :showCodeRowNumber="false"
                                hideToolbar previewOnly />
                </div>
            </div>


            <!-- /* BUTTONS  */ -->
            <div class="blog-footer">
                <div class="blog-btm-id">#{{ blog_id }} </div>
                <div class="blog-btm-enter">
                    <router-link
                        :to="{ name: `blogs.view`, params: { blog_id: blog_id } }">
                        <button class="enter-view" >รายละเอียด .. <span class="count">{{ formatfloat(blog_count_views) }}</span></button>
                    </router-link>
                </div>
            </div>

</template>

<script>
    import { showThaiDate } from "@/components/lib-thaidate";
    import apiConfig from "./api-config";
    import MdEditor from 'md-editor-v3';
    import 'md-editor-v3/lib/style.css';


    export default {
        props: {
            blog_title: { type: String, required: true },
            blog_subtitle: { type: String, required: true ,default: "" },
            blog_content: { type: String, required: true },
            blog_image_url: { type: String, required: true },
            blog_id: { type: Number, required: true },
            blog_group_code: { type: String, required: true },
            blog_date: { type: String, required: true },
            blog_count_views: { type: Number, required: true ,default:0},
            author_name: { type: String, required: true },
            author_avatar_url: { type: String, required: true },
        },
        components:{
            MdEditor,
        },
        methods: {
            getURLImageAtServer(url) {
                console.log('author_avatar_url',url);
                return apiConfig.URL_IMG_AUTHOR() + "/" + url;
            },
            showThaiDate(dateStr) {
                return showThaiDate(dateStr);
            },
            formatfloat(value){
                if (value == undefined || value == null){
                    value = 0;
                }
                return Intl.NumberFormat().format(value)
            },

        },

    };

</script>

<style scoped>
  @import "@/css/main.css";

  .blog-author{
    color: blue;
  }

  .count{
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
  }


.blog-footer{
    align-content: space-between;
}

.blog-btm-enter{
    text-align: right;
}

.blog-btm-id{
    text-align: left;
    font-size: 12px;
    color: rgb(105, 105, 105);
}
</style>

<style>
.blogs{
	border: 2px solid rgb(224, 221, 221);
	border-radius: 10px;
	-webkit-box-shadow: 1px 1px 5px 0px rgba(145, 144, 144, 0.3);
	box-shadow: 1px 1px 5px 2px rgba(145, 144, 144, 0.3);

	padding: 20px;
}
/*
.blog-content-subtitle{
    background-color:#EEEEEE;
}

.blog-content-markdown{
    background-color:#EEEEEE;
} */

</style>