<template>
  <p class="footer-top"></p>
    <div class="row">
        <div class="column1">
        </div>

        <div class="column2">
            <p class="footer-text-header"> ประกาศ ::</p>
            <p class="footer-text">© ๒๕๖๕-ปัจจุบัน  | วรเพชร  เรืองพรวิสุทธิ์ 🇹🇭 รักเธอประเทศไทย</p>
        </div>

        <div class="column3">
            <p class="footer-text-header">การติดต่อ :: </p>
            <p class="footer-text">warapetch.rpvs@gmail.com</p>
        </div>

        <div class="column4">
          <pre><code> Front end:
Back end:
Database:</code></pre>
        </div>
        <div class="column5">
            <pre class="footer-text-2"><code>Vue3
FastAPI (Python 3.10)
SQLite (SQLAlchemy 1.4)</code></pre>
        </div>

        <div class="column6">
        </div>

  </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>
.container.cz-color-0{
  min-height: 750px;
}


code{
  color: #e0e1dd;
  font-size: 16px;
}
footer p {
    font-size: 16px;
}

* {
  box-sizing: border-box;
}

.column1 {
    float: left;
    width: 2%;
    height: 100px;
    /* background-color: #0E1A2C; */
    background-color: #182438;
    /* background-color: #121c2c; */
}
.column2 {
    float: left;
    width: 33%;
    padding: 0px;
    padding-left: 10px;
    height: 100px;
    background-color: #263751;
    border-left: 5px solid red;
}
.column3 {
  float: left;
  width: 33%;
  padding: 0px;
  padding-left: 10px;
  height: 100px;
  background-color: #263751;
  border-left: 5px solid red;
}

.column4 {
  float: left;
  width: 9%;
  padding: 0px;
  padding-left: 10px;
  height: 100px;
  background-color: #263751;
  border-left: 5px solid red;
}

.column5 {
  float: left;
  width: 22%;
  padding: 0px;
  padding-left: 10px;
  height: 100px;
  background-color: #263751;
  color: aqua;

}

.column6 {
  float: left;
  width: 0;
  padding: 0px;
  padding-left: 10px;
  height: 100px;
  background-color: #263751;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
}

.footer-text-header{
    color: #f8de4d;
    font-weight: bold;
    text-align: left;
}

.footer-text{
  color: #e0e1dd;
  text-align: left;
}

.footer-text-2{
  text-align: left;
  color: #0bf10b;
  width: 100%;
}

</style>