<template>
    <CpnDataAuthor ref="AuthorDataCPN" @cbDataLoaded="getAuthorDataLoaded" />

    <CpnDialogService ref="DialogService"
            @cbOkClick="getCallbackOkClick"
            @cbCancelClick="getCallbackCancelClick"
            @cbClearTokenOK="getClearTokenOK"
        />

    <div class="wrapper">
            <div class="overlay-box-author" :style ="customStyle" alt="author info">

                <div class="container-author">

                    <div class="header-menu">
                        <a  @click="btnAuthorInfoClick">
                            <div class="header-menu-author-text"> ข้อมูลผู้แชร์ข้อมูล </div>
                        </a>
                    </div>

                    <div class="content-menu">
                        <div class="menu-signout-author" v-if="AuthorData.author_signedIn === true" @click="btnSignOutClick">
                            ลงชื่อออก (ผู้แชร์ข้อมูล)
                        </div>
                        <div v-else class="menu-signin-author">
                            <CpnAppLink :to="{ path: '/signin-author'}"> ลงชื่อเข้า (ผู้แชร์ข้อมูล) </CpnAppLink></div>
                    </div>

                    <div class="footer-menu" v-show="AuthorData.jwtToken != ''">
                            <a  @click="btnClearToken">
                                <div class="footer-menu-author-text">เคลีย Token</div>
                            </a>
                            <hr>
                            <div class="menu-signup-author" >
                                <CpnAppLink :to="{ path: '/signup-author'}"> สมัครผู้แชร์ข้อมูล </CpnAppLink>
                            </div>

                    </div>

                </div>
            </div>
    </div>

</template>

<script>

    import CpnAppLink from './cpn-applink.vue';
    import libCommon, { RandomStr } from './lib-common';
    import CpnDialogService from './cpn-dialog-service.vue';
    import CpnDataAuthor from './cpn-data-author.vue';

    export default {
        emits:['cbClearTokenOK'],
        components:{ CpnAppLink, CpnDataAuthor, CpnDialogService, },
        props:{
            text: {type: String ,default: "ข้อมูลผู้แชร์ข้อมูล"},
            boxWidth: {type: String ,default: "200px"},
            boxHeight: {type: String ,default: "250px"},
            background: {type: String,default: '#fff'},
            borderLeft: {type: String,default: '1px solid #023e7d'},
            borderRight: {type: String,default: '1px solid #023e7d'},
            borderRadius: {type: String,default: '0px 0px 0px 0px'},
            position_top: {type: String,default: '40%'},
            position_right: {type: String,default: '10px'},
            opacity: {type: Number,default: 0.9}
        },
        data() {
            return {
                AuthorData : Object,
            }
        },
        methods:{
            btnAuthorInfoClick(){
                console.log('btnAuthorInfoClick' , this.AuthorData.author_id );
                if (libCommon.isTrue(this.AuthorData.author_signedIn) == true){
                    this.$router.push({name: "userinfo-author" ,parames:{author_id: "AuthorData.author_id"} });
                } else {
                    this.$router.push({name: "signin-author" , });
                }
            },
            btnSignOutClick(){
                this.$refs.DialogService.showDlgConfirm("ลงชื่อออก (สมาชิก)","ยืนยันลงชื่อออก ?<br />(เป็นการเปลี่ยนสถานะ ของการทำงาน)");
            },
            btnClearToken(){
                this.$refs.DialogService.showDlgConfirm("เคลีย Token","ยืนยันเคลีย Token ?",'cbClearTokenOK');
            },
            getCallbackOkClick(){
                //console.log('getCallbackOkClick');
                this.$refs.AuthorDataCPN.setValue('author_signedIn',false);
                console.log('setValue-finished');

                this.$router.push({ path: "/#"+ RandomStr() });
                this.$refs.AuthorDataCPN.loadFromLocal();
            },
            getCallbackCancelClick(){
                console.log('getCallbackCancelClick');
                this.$refs.DialogService.hideDlg();
            },
            getClearTokenOK(){
                console.log('getClearTokenOK');
                sessionStorage.removeItem('jwtToken');
                //localStorage.removeItem('udata');
                //this.$refs.AuthorDataCPN.loadFromLocal();
            },
            getAuthorDataLoaded(data){
                this.AuthorData = data;
                console.log('getAuthorDataLoaded',data);
            },
        },
        computed: {
            customStyle () {
                return {
                    'position': 'fixed',
                    'z-index': 9999,
                    'background': this.background,
                    'height': this.boxHeight,
                    'width': this.boxWidth,
                    'right': this.position_right,
                    'top': this.position_top,
                    'opacity': this.opacity,
                    'border-left': this.borderLeft,
                    'border-right': this.borderRight,
                    'border-radius': this.borderRadius,
                    'color': 'black',
                    'cursor': 'pointer',
                    'Author-select': 'none',
                }
            } ,
        },
    }
</script>


<style scoped>

.header-menu-author-text{
    color: black;
    font-size: 16px;
    text-align: center;
    align-content: center;
    padding: 5px;
    background-color: #ffb400;
    border: 1px solid rgb(152, 183, 250);
}

.header-menu-author-text:hover{
    color: #002855;
}


.menu-signup-author{
    font-size: 16px;
    text-align: center;
    align-content: center;
    padding: 5px;
    grid-area: content-menu-author;
    background-color: rgb(8, 113, 252);
    border: 1px solid rgb(152, 183, 250);
}


.menu-signin-author{
    font-size: 16px;
    text-align: center;
    align-content: center;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    grid-area: content-menu-author;
    background-color: rgb(6, 184, 115);
    border: 1px solid rgb(4, 114, 72);

}


.menu-signout-author{
    font-size: 16px;
    text-align: center;
    align-content: center;
    padding: 5px;
    grid-area: content-menu-author;
    background-color: rgb(238, 119, 7);
    border: 1px solid rgb(255, 240, 175);
    color: #fff;
}


.menu-signup-author:hover{
    background-color: #002855;
}


.menu-signin-author:hover{
    background-color: #002855;
}


.menu-signout-author:hover{
    background-color: #002855;
}


.header-menu-author-text:hover{
    background-color: #ffb400;
    background-color: #002855;
    color: #fff;
}


.overlay-box-author{
    background-repeat: no-repeat;
    border: 2px solid #023e7d;
    min-height: 125px;
    max-height: 178px;
}


.overlay-container-author {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 0.5fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}



.container-author {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    align-content: center;
    text-align: center;
    grid-template-areas:
    "header-menu"
    "content-menu"
    "footer-menu";
}
.header-menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px;
    grid-template-areas:". .";
    grid-area: header-menu;
}

.content-menu {
    grid-area: content-menu;
    font-size: 16px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    align-content: center;
    background-color: rgb(253, 221, 147);
    border: 1px solid rgb(253, 221, 147);
}

.footer-menu {
    grid-area: footer-menu;
    font-size: 16px;
    padding: 5px;
    text-align: center;
    align-content: center;
    background-image: linear-gradient(rgb(255, 180, 0), rgb(3, 57, 128));
    border: 1px solid rgb(253, 221, 147);
    color: white;
}

.footer-menu-author-text{
    color: black;
}

.footer-menu-author-text:hover{
    color: #002855;
}

.footer-menu:hover{
    /* background-image: linear-gradient(rgb(255, 180, 0), rgb(3, 57, 128)); */
    background-image: linear-gradient(rgb(3, 57, 128), rgb(255, 180, 0));
}
</style>