import emailjs from '@emailjs/browser';

const FormMail = Object.freeze({
        MEMBER: "member",
        FORGOT: "forgot",
        UPDATE: "update",
      });

export default FormMail;


export async function SendEmail(formType,to_email,to_name,password,message,from_name,otp) {

        let templateParams = {
            to_email: to_email,
            to_name: to_name,
            userpassword: password,
            from_name: from_name,
            message: message,
            to_otp:otp
        };

        let formID = "";
        switch (formType) {
                case FormMail.MEMBER:
                        formID = 'template_8yoywgq';
                        break;
                case FormMail.FORGOT:
                        formID = 'template_nu43hf4';
                        break;
                default:
                        formID = 'template_8yoywgq';
                        break;
        }

        const PublicKey  = 'mtctvjk0DQedFZwj4';
        const ServiceID  = 'service_99wlj1e';
//        const PrivateKey = 'KR6EB6_FEG2qvPf89jaOG';
/*
    Public Key =  mtctvjk0DQedFZwj4
    private Key = KR6EB6_FEG2qvPf89jaOG

    template_8yoywgq ตอบรับสมาชิก
    template_nu43hf4 ลืมรหัสผ่าน
*/
        let send_success = false;
        await emailjs.send(ServiceID, formID, templateParams, PublicKey)
                .then((result) => {
                console.log('ส่งสำเร็จ', result.text);
                    /* ส่งสำเร็จ คือ ส่งไปยัง GMail provider แต่ส่งถึงมือ Email ปลายทาง หรือปล่าวไม่รู้ */
                send_success = true;
                }, (error) => {
                        console.log('ส่งไม่สำเร็จ !!', error.text);
                        alert(JSON.stringify(error));
                        send_success = false;
                });

        return send_success;
}
