<template>

    <div class="wrapper">
        <div class="overlay-box" :style ="customStyle">
            <a  @click="buttonClick">
                <div class="overlay-text">{{ text }}</div>
            </a>
        </div>
    </div>

</template>

<script>
    export default {
        props:{
            text: {type: String ,default: "TOP 🢁"},
            href: {type: String ,default: '#'},
            boxWidth: {type: String ,default: "110px"},
            boxHeight: {type: String ,default: "50px"},
            background: {type: String,default: '#a7d0fa'},
            borderLeft: {type: String,default: '20px solid #0353a4'},
            borderRadius: {type: String,default: '0px 10px 10px 0px'},
            position_bottom: {type: String,default:'14%'},
            position_left: {type: String,default:'10px'},
            opacity: {type: Number,default: 0.9}
        },
        methods:{
            buttonClick(){
                // pack page
                if (this.href == "back()"){
                    this.$router.back()
                } else {
                    // go to top
                    document.body.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }
        },
        computed: {
            customStyle () {
                return {
                    'position': 'fixed',
                    'z-index': 9999,
                    'background': this.background,
                    'height': this.boxHeight,
                    'width': this.boxWidth,
                    'left': this.position_left,
                    'bottom': this.position_bottom,
                    'opacity': this.opacity,
                    'border-left': this.borderLeft,
                    'border-radius': this.borderRadius,
                    'color': 'black',
                    'cursor': 'pointer',
                    'user-select': 'none',
                }
            }
        },
    }
</script>

<style scoped>
.overlay-text{
    color: black;
    font-size: 18px;
    text-align: center;
    align-content: center;
    padding: 10px;
}

.overlay-box{
  background-repeat: no-repeat;
  background-size: auto;

}

</style>


<!-- String
Number
Boolean
Array
Object
Date
Function
Symbol -->