<template>
  <CpnDataUser ref="UserDataCPN" />
  <nav class="main-nav">
  <div class="main-links">
      <!--
        * if status == loading > Show loading *
      -->
      <CpnLoader3Ball
                :loading="pageloading"
                :loadingtext="loadingtext" />

      <!--
        * Loop ข้อมูลจากเซิฟ :: Group Content *
      -->
      <span>
        <CpnAppLink :to="{ name: 'home' }" > หน้าแรก </CpnAppLink>
      </span>
      <!--
        * Dynamic Nav-Link *
      -->
      <span class="group-links" v-for="(group,index) in groups" :key="index">

        <span v-if="group.is_link_external == 'Y'" >
          <CpnAppLink :thisLink_is_ExternalLink=true  :to="group.url_external_link" > {{ group.group_name }}
          </CpnAppLink>
        </span>
        <span v-else>
        <CpnAppLink :to="{ name: 'group.main' ,
                        params: {group_code: group.group_code ,
                                 group_owner: group.group_owner,} }"
                        @click="OnClinkLink($event)">
                        {{ group.group_name }}
        </CpnAppLink>
        </span>
      </span>

  </div>
  <div class="main-links">
      <!--
        * Static Nav-Link *
      -->
    <CpnAppLink :to="{ path: '/markdown-editor' } "> Markdown Editor </CpnAppLink>
    <CpnAppLink :to="{ name: 'about'}"> เกี่ยวกับ </CpnAppLink>

    <!-- <CpnAppLink to="https://"> </CpnAppLink>     -->
  </div>
</nav>

</template>



<script>
    import CpnAppLink from './cpn-applink.vue';
    import { HttpGet,ValidateToken, } from "@/components/lib-http-helper";
    import apiConfig from "@/components/api-config";
    import CpnLoader3Ball from '@/components/cpn-loader-3ball.vue';
    //import libCommon from './lib-common';
    import CpnDataUser from './cpn-data-user.vue';

    export default {
        components: { CpnAppLink, CpnLoader3Ball, CpnDataUser },
        data() {
            return {
                groups: [],
                groupId: "",
                groupName: "",
                loadingtext: "Group Navigator",
                pageloading: false,
            }
        },
        mounted() {

            // const _user_id          = this.$refs.UserDataCPN.getValue('user_id');
            // const _user_signedUp    = this.$refs.UserDataCPN.getValue('user_signedUp');
            // console.log('get _user_id >',_user_id);
            // if (_user_id && _user_signedUp == false) {
            //     this.$refs.UserDataCPN.setValue('user_signedUp',true);
            // }

            // this.user_signedIn = false;
            // console.log('nav user_signedIn >',this.user_signedIn);

            // if (libCommon.isTrue(this.user_signedIn) == true) {
            //     console.log('after isTrue >> ',this.user_signedIn);
            //     this.user_signedIn = true;
            // }

            // console.log('nav localStorage.user_signedIn >',this.user_signedIn);

            this.getValues();
        },
        methods: {
            async getValues() {
                ValidateToken('nav-main').then((success) => {
                    if (success){
                        this.getGroupNav();
                    }
                    this.pageloading = false;
                });
            },

            async getGroupNav() {
                //console.log('getGroupNav >> start');
                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_MainGroup,'All Group Main').then(
                    (response) => {
                        this.groups = response.data;
                        //console.log('getGroupNav >>',this.groups);
                    }
                );
            },

            OnClinkLink(e){
                console.log('Click ',e);
            },
        }

    }
</script>

<style >
    @import "@/css/main.css";

a {
    color: white;
}

/* .active {
    color: yellow;
    font-weight: bold;
} */

.exact-active-link {
    color: rgb(0, 225, 255);
    /* font-weight: bold; */
}

a.active-link{
    border-bottom: 3px solid red;
    color: yellow;
  }

div.sub-links{
    color:white;
}


.button-clicked {
  background: red;
}
</style>