<template >
    <div>
        <CpnDialogBase
            :dialogHeader       = "textHeader"
            :dialogContent      = "textContent"
            :dialogFooter       = "textFooter"
            :btnOkCaption       = "btnOkCaption"
            :btnCancelCaption   = "btnCancelCaption"
            :showDialog         = "IsShowDialog"
            :showHeader         = "IsShowHeader"
            :showFooter         = "IsShowFooter"
            :showBtnCancel      = "IsShowBtnCancel"
            :showBtnOk          = "IsShowBtnOk"

            :styleContainer     = "styleContainer"
            :styleHeader        = "styleHeader"
            :styleContent       = "styleContent"
            :styleFooter        = "styleFooter"
            :styleBtnOk         = "styleBtnOk"
            :styleBtnCancel     = "styleBtnCancel"

            @cbOkClick     = "getCallbackOkClick()"
            @cbCancelClick = "getCallbackCancelClick()"
            >
            <template v-slot:header>
                <div v-if="templateHeader">
                    <span v-html = templateHeader></span>
                </div>
            </template>

            <template v-slot:body>
                <div v-if="templateBody">
                    <span v-html = templateBody></span>
                </div>
            </template>

            <template v-slot:footer>
                <div v-if="templateFooter">
                    <span v-html = templateFooter></span>
                </div>
            </template>

        </CpnDialogBase>
    </div>
</template>

<script>
    import apiConfig from './api-config';
    import CpnDialogBase from "./cpn-dialog-base.vue";
    import libCommon from "./lib-common";

    export default {
        emits:['cbOkClick','cbCancelClick','cbDeleteAccount',
                'cbPopUpClosed','cbPolicyOK','cbPolicyCancel',
                'cbClearTokenOK','cbClearUData','cbClearAData'],
        data() {
            return {
                // Listent to Event (Callback)
                callbackOkClick:"cbOkClick",
                callbackCancelClick:"cbCancelClick",
                callbackData: null,

                // countDonw
                countDown: 0,
                textHeader: "",
                textContent: "",
                textFooter: "",
                btnOkCaption: "ตกลง",
                btnCancelCaption:"ยกเลิก",

                styleContainer: {},
                styleHeader: {},
                styleContent: {},
                styleFooter: {},
                styleBtnOk: {},
                styleBtnCancel: {},

                templateHeader: "",
                templateBody: "",
                templateFooter: "",

                IsShowHeader: true,
                IsShowFooter: true,
                IsShowDialog: false,
                IsShowBtnOk: true,
                IsShowBtnCancel: false,
            };
        },
        methods: {
            getCallbackOkClick(value){
                this.IsShowDialog = false;
                //console.log('getCallbackOkClick',value);
                console.log('refer callback > ',this.callbackOkClick);
                this.$emit(this.callbackOkClick,[value,this.callbackData]);
            },
            getCallbackCancelClick(value){
                this.IsShowDialog = false;
                //console.log('getCallbackCancelClick',value);
                console.log('refer callback > ',this.callbackCancelClick);
                this.$emit(this.callbackCancelClick,value);
            },
            showDlgInfo(header,text) {
                console.log("showDlgInfo");
                this.dialogID = this.randomID();
                this.textHeader = this.setHeaderFormat(header);
                this.textContent = text;
                this.styleContainer = {'width': '500px','height':'300px'};
                this.styleHeader = {'background-color': '#04b3f8'};
                this.styleFooter = {'background-color': '#6dd4fd'};
                this.IsShowDialog=true;
            },
            showDlgWarning(header,text) {
                this.dialogID = this.randomID();
                this.textHeader = this.setHeaderFormat(header);
                this.textContent = text;
                this.styleContainer = {'width': '500px','height':'300px'};
                this.styleHeader = {'background-color': '#fdbb04'};
                this.styleFooter = {'background-color': '#fad675'};
                this.IsShowDialog=true;
            },
            showDlgError(header,text) {
                console.log("showDlgError");
                this.dialogID = this.randomID();
                this.textHeader = this.setHeaderFormat(header);
                this.textContent = text;
                this.styleContainer = {'width': '500px','height':'300px'};
                this.styleHeader = {'background-color': '#fa5306'};
                this.styleFooter = {'background-color': '#f8e0d5'};
                this.IsShowDialog=true;
            },
            showDlgConfirm(header,text,callbackData,listenToEvent) {
                console.log("showDlgConfirm");
                this.dialogID = this.randomID();
                this.textHeader = this.setHeaderFormat(header);
                this.textContent = text;
                this.styleContainer = {'width': '500px','height':'300px'};
                this.styleHeader = {'background-color': '#fdbb04'};
                this.styleFooter = {'background-color': '#f8edcf'};
                this.btnOkCaption = "ยืนยัน";
                this.btnCancelCaption = "ยกเลิก";
                this.IsShowDialog=true;
                this.IsShowBtnOk = true;
                this.IsShowBtnCancel=true;

                if (callbackData != undefined){
                    this.callbackData=callbackData;
                }

                if (listenToEvent != undefined){
                    this.callbackOkClick=listenToEvent;
                }
            },
            async showPopUp(text,timeOut,trackValue){
                this.dialogID = this.randomID();
                this.textHeader = "";
                this.textContent = text;
                this.styleContainer = {'width': '500px','height':'auto'};
                this.styleHeader = {};
                this.styleFooter = {};
                this.IsShowBtnOk = false;
                this.IsShowBtnCancel=false;
                this.IsShowDialog=true;

                setTimeout(async () => {
                            this.IsShowDialog = false;
                            if (trackValue !== "" && trackValue !== undefined){
                                this.$emit('cbPopUpClosed',trackValue);
                            }
                },timeOut);
            },
            showDlgPolicy(listenToEventOk,listenToEventCancel){
                this.dialogID = this.randomID();
                console.log("showDlgPolicy",this.dialogID);
                this.styleContainer = {'width': '55%','height':'500px'};
                this.textHeader = '<h3>นโยบายเกี่ยวกับการใช้งานคุกกี้</h3>';
                this.textContent = '<div class="scroller">'+apiConfig.TEXT_POLICY+'</div>';
                this.btnOkCaption = "รับทราบ/เข้าใจแล้ว";
                this.btnCancelCaption = "ปิดเบราเซอร์";
                this.IsShowBtnOk = true;
                this.IsShowBtnCancel= true;
                this.callbackOkClick= listenToEventOk;
                this.callbackCancelClick= listenToEventCancel;
                this.IsShowDialog=true;
            },
            hideDlg(){
                this.IsShowDialog=false;
            },
            setHeaderFormat(header){
                return "<H3>"+header+"</H3>"
            },
            randomID(){
                const _id = libCommon.RandomStr(6);

                return _id;
            },
        },
        components: { CpnDialogBase },
    }
</script>

<style scoped>
*{
    z-index: 100000;
}
</style>