import {createRouter, createWebHistory,} from 'vue-router'
import Home from '@/views/page-home.vue'
import PageGroup from '@/views/page-group.vue';
import PageSubGroup from '@/views/page-subgroup.vue';
import PageAbout from '@/views/page-about.vue';
import PageMarkdownEditor from '@/components/cpn-editor-markdown.vue';

import PageBlogControl    from '@/components/cpn-blog-control.vue';
import PageBlogNew        from '@/components/cpn-editor-blog-new.vue';
import PageBlogEdit       from '@/components/cpn-editor-blog-edit.vue';
import PageBlogDetail       from '@/views/page-blog-detail.vue';

import FormSignUpMember   from '@/forms/form-signup_member.vue';
import FormSignInMember   from '@/forms/form-signin_member.vue';
import FormSignOutMember  from '@/forms/form-signout_member.vue';
import FormSignUpAuthor   from '@/forms/form-signup_author.vue';
import FormSignInAuthor   from '@/forms/form-signin_author.vue';
import FormSignOutAuthor  from '@/forms/form-signout_author.vue';
import FormUserInfoMember from '@/forms/form-userinfo_member.vue';
import FormUserInfoAuthor from '@/forms/form-userinfo_author.vue';
import FormForgotPasswordUser   from '@/forms/form-forgot_password-user.vue';
import FormForgotPasswordAuthor from '@/forms/form-forgot_password-author.vue';


const routes = [
  {
    path: '/',  name: 'home',  alias: "/home",
    component: Home,    },


  {
    path: '/signup-member',  name: 'signup-member',
    component: FormSignUpMember,    },


  {
    path: '/signup-author',  name: 'signup-author',
    component: FormSignUpAuthor,    },


  {
    path: '/signin-author',  name: 'signin-author',
    component: FormSignInAuthor,    },


  {
    path: '/signin-member',  name: 'signin-member',
    component: FormSignInMember,    },


  {
    path: '/signout-author',  name: 'signout-author',
    component: FormSignOutAuthor,    },


  {
    path: '/signout-member',  name: 'signout-member',
    component: FormSignOutMember,    },


  {
    path: '/userinfo-member',  name: 'userinfo-member',
    component: FormUserInfoMember,    },


  {
    path: '/userinfo-author',  name: 'userinfo-author',
    component: FormUserInfoAuthor,    },


  {
    path: '/forgot-user',  name: 'userforgotpsw',
    component: FormForgotPasswordUser,    },


  {
    path: '/forgot-author',  name: 'authorforgotpsw',
    component: FormForgotPasswordAuthor,    },


  {
    path: '/blogcontrol',  name: 'blogcontrol',
    component: PageBlogControl,    },


  {
    path: '/blog-new',  name: 'blognew',
    component: PageBlogNew,    },


  {
    path: '/blog-edit/:blog_id/:key_id',  name: 'blogedit',
    component: PageBlogEdit,
    props: route=> ({...route.params, blog_id: route.params.blog_id ,key_id: route.params.key_id}),
  },



  {
    path: '/blogs/:blog_id',
    name: 'blogs.view',
    // component: ()=>import('@/views/page-blog-detail.vue'),
    component: PageBlogDetail,
    /* กำหนดให้ Route ช่วยในการอ่านค่า Parameters อีก 1 รอบ */
    /* แปลง Object[Json] [2 array ] เป็น Json{} ด้วย JSON.stringify(Value) */
    props: route=> ({...route.params, blog_id: route.params.blog_id , }),
  }
  ,


  /* Page Not Found */
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: ()=> import('@/views/page-not-found.vue')
  }
  ,

  /*
    Navigator
    Path: พารามิเตอร์ กำหนดชื่อแบบไหน กำหนดให้ค่า มาให้ตรง !!
    Name: กำหนดได้หลากหลาย . - ต้องกำหนดมาให้ตรง
  */
  {
    path: '/:group_owner/:group_code',
    name: 'group.main',
    component: PageGroup,
    props: route=> ({...route.params, group_code: route.params.group_code ,group_owner: route.params.group_owner }),

  },

  {
    path: '/:group_owner/:group_code/:key',
    name: 'group.sub',
    component: PageSubGroup,
    props: route=> ({...route.params, group_code: route.params.group_code ,group_owner: route.params.group_owner ,key:route.params.key}),
  },

  {
    path: '/markdown-editor',
    name: 'markdown-editor',
    component: PageMarkdownEditor,
  },
  {
    path: '/search/:group_id/:group_owner',
    name: 'search.group',
    component: PageGroup,
    props: route=> ({...route.params, group_id: route.params.group_id ,group_owner: route.params.group_owner }),
  },
  {
    path: '/about',
    name: 'about',
    component: PageAbout,
  },


]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  mode: "history",
  // mode: 'abstract',
  routes,
  scrollBehavior (to, from, savedPosition) {

    // return savedPosition || new Promise((resolve)=>{
    //   setTimeout(()=> resolve({ top:0 }), 300);
    // });

    if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ''))
        if (element && element.scrollIntoView) {

          element.scrollIntoView({block: 'center', behavior: 'smooth' ,})
        }
      }, 500)

      return {
        el: to.hash,
        behavior: 'smooth',
      }

    }
    else if (savedPosition) {
      return savedPosition
    }
    return {top: 0}
  }
})


export default router
