<template>
    <CpnDataUser ref="UserDataCPN" :reload = "NeedReload" >
    </CpnDataUser>

    <div class="wrapper">

            <div class="signout-container">
                    <h1>ลงชื่อออก-สมาชิก</h1>

                <div class="container">
                    <h4 class="signout-text">ยืนยันลงชื่อออก</h4>
                </div>

                <div class="btn-container" >
                    <button type="button" class="btncancel" @click="backToCurPage">ยกเลิก</button>
                    <button type="submit" class="btnsubmit" @click="signout">ยืนยัน</button>
                </div>

            </div>

        <div class="div-reserved-layout">
        </div>
    </div>
</template>

<script>
    import { RandomStr } from '@/components/lib-common';
    import CpnDataUser from '@/components/cpn-data-user.vue';

    export default {
        data() {
            return {
                showAlert: false,
                NeedReload: false,
            };
        },
        methods: {
            backToCurPage() {
                this.$router.back();
            },
            signout() {
                this.$refs.UserDataCPN.setValue('user_signedIn', false);
                this.$router.push({ path: "/#"+ RandomStr(10) });
            },
            closeDlg(){
                this.showAlert = false;
            },
        },
        components: { CpnDataUser }
    }
</script>

<style lang="css" scoped>


.signout-text{
    text-align: center;
}

.signout-container{
    display: block;
    margin:auto;
    margin-top: 100px;
    padding: 20px;
    max-width: 50%;
    align-items: center;
    align-content: center;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px grey;
    background-color:#f1f1f1
}

.btn-container{
    background-color:#f1f1f1
}

input[type=email], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #04AA6D;
  font-size: 16px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  width: 45%;
}

button:hover {
  opacity: 0.8;
}

.btncancel {
  width: 45%;
  padding: 14px 18px;
  background-color: #f44336;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .btncancel {
    width: 100%;
  }
}

.details-otp{
    cursor: pointer;
}

.div-reserved-layout{
    min-height: 70px;
    height: auto;
    overflow: hidden;
}
</style>