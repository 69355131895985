<template>
    <div class="parent">
      <div class="container">
          <h1> เวบไซต์ </h1>
          <h1> สยามเซ็นเตอร์ ดอท คอม </h1>
      </div>
      <div class="thankyou">ขอขอบคุณที่เป็นส่วนหนึ่งในการทดสอบ</div>
      <h4 class="ribbon">อยู่ระหว่างการทดสอบ<br/>พบข้อผิดพลาดโปรดแจ้ง</h4>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="css" scoped>
.parent {
  overflow: hidden; /* required */
  top: 100px;
  width: 50%; /* for demo only */
  height: 200px /* some non-zero number */;
  margin: 25px auto; /* for demo only */
  margin-bottom: 100px;
  border:1px solid #cca0f8; /* for demo only */
  position: relative; /* required  for demo*/
  box-shadow:  3px 3px #d3d3d3;
  background: rgb(240, 226, 253);
}


.container{
  margin: 60px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.thankyou{
  margin: 10px;
  margin-left: 60px;
}

.ribbon {
  margin: 0;
  padding: 0;
  background: rebeccapurple;
  color:white;
  padding:1em 0;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: rebeccapurple;
}
.ribbon:before {
  right:100%;
}

.ribbon:after {
  left:100%;
}
</style>