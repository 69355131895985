<template>
    <div class="row">
        <div class="column1">
            <img
                v-bind:src="calc_author_avatar_url"
                alt="author-image"
                class="author-image"
                />
        </div>

        <div class="column2">
            <span class="author-name" >{{ author_name }}</span>
            <br/>
            <br/>
            <span class="blog-date">{{ blog_date }}</span>
        </div>

        <div class="column3">
          แชร์
        </div>

        <div class="column4">
          <CpnSharenetwork
            :blog_id="blog_id"
            :blog_title="blog_title"
            :blog_subtitle="blog_subtitle"
            :blog_tag= "blog_tag"
          />
        </div>

        <div class="column5">
            # <span>{{ blog_tag }}</span>

            <p>#{{ blog_id }}</p>
        </div>
    </div>

</template>

<script>
    import apiConfig from './api-config';
    import CpnSharenetwork from "./cpn-share-network.vue";


    export default {
        props: {
            blog_title: { type: String, required: true },
            blog_subtitle: { type: String, required: true },
            blog_group_code: { type: String, required: true },
            blog_id: { type: Number, required: true },
            blog_date: { type: String, required: true },
            blog_tag: { type: String, required: true },
            blog_link: { type: String, required: true },
            author_id: { type: String, required: true },
            author_name: { type: String, required: true },
            author_avatar_url: { type: String, required: true },
        },
        methods: {},
        components: { CpnSharenetwork },

        computed:{
            calc_author_avatar_url(){
                return apiConfig.URL_IMG_AVATAR()+'/'+this.author_avatar_url;
            }
        }
    }
</script>

<style scoped>

* {
  box-sizing: border-box;
}

.column1 {
    float: left;
    width: 8%;
    height: 75px;
    background-color: transparent;
}
.column2 {
    float: left;
    width: 32%;
    padding: 0px;
    height: 75px;
    background-color: #e3eefd;
}
.column3 {
  float: left;
  width: 5%;
  padding: 10px;
  height: 75px;
  background-color:  #c7dffd;
}

.column4 {
  float: left;
  width: 30%;
  padding-top: 4px;
  height: 75px;
  background-color:  #c1dbfd;
  text-align: center;
}

.column5 {
  float: left;
  width: 25%;
  padding: 10px;
  height: 75px;
  background-color: #a1c7fa;
}



/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
}

.footer-text{
    color: rgb(4, 4, 78);
}


.footer-text-header{
    color: rgb(4, 4, 78);
    font-weight: bold;
}

.author-name{
    padding-left: 10px;
}

.blog-group{
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #e0edfa;
  border: 2px solid #023e7d;
  border-radius: 4px;
}

.blog-date{
    padding-left: 10px;
}

</style>