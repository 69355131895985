<template>
        <a v-if="isExternalLink" v-bind="$attrs" :href="to"
                target="_blank" rel="noopener"
                :class="{ 'active-link': isActive, 'exact-active-link': !isActive }"
                >
            <slot />
        </a>

        <router-link
            v-else
                v-bind="$props"
                custom
                v-slot="{href, navigate, isActive, isExactActive }"
            >

            <a
                v-bind="$attrs"
                :href="href"
                @click="navigate"
                :class="{ 'active-link': isActive, 'exact-active-link': isExactActive }"
                :name="isclicked"
            >
            <!--                 :class="{ 'active-link': isActive, exact-active-link': !isActive }" -->
                <slot />
            </a>

            <!-- :class="isActive ? activeClass : inactiveClass" -->
            <!-- :class="{ 'active-link': isActive, 'exact-active-link': isExactActive }" -->
        </router-link>

</template>

<script>
    import { RouterLink } from 'vue-router'

    export default {
        name: 'CpnAppLink',
        inheritAttrs: false,

        data() {
            return {
                isclicked : false,
                isActive: false,
                //isExactActive: false,
            }
        },


        props: {
            // add @ts-ignore if using TypeScript
            ...RouterLink.props,
            inactiveClass: String,
            thisLink_is_ExternalLink: Boolean,
        },

        computed: {
            isExternalLink() {
                return (typeof this.to === 'string' && this.to.startsWith('http')) || (this.thisLink_is_ExternalLink == true)
            },
        },

        methods: {
            handleclick(){
                this.isclicked = !this.isclicked;
            }
        },

    }
</script>