/* eslint-disable no-unused-vars */
import axios from 'axios';
import apiConfig from './api-config';

/* HTTP - GET */
export async function HttpGet(url,desc) {
    console.log('HttpGet URL > ',url)
    if (url){
        let responseData = null;
        try {
            axios.withCredentials  = true,
            await axios.get(url)
                .then(response => {
                    //console.log(`HttpGet "${url}" "${desc}" > `,response)
                    responseData = response;
                    return response;
                })
        } catch(err) {
            console.log(`HttpGet Error "${desc}" > `,err)
            console.log('Error detail > ',err.response.data['detail'])
            // ถ้า Token Expire Delete
            if (err.response.data['detail'] == 'expired token'){
                sessionStorage.removeItem('jwtToken');
            }

            responseData = [{}]
        }

    return responseData;
  }
}


/* HTTP - POST */
export async function HttpPost(url,formdata) {
    console.log('HttpPost URL > ',url)
    //console.log('HttpPost formdata >> ',formdata)
    let responseData = null;

    try {
        axios.withCredentials  = true;
        await axios.post(url,formdata)
            .then(response => {
                console.log('HttpPost response > ',response);
                responseData = response;
            })
    } catch(err) {
        console.log('HttpPost Error > ',err)
        if (err.response.data != undefined){
            console.log('check detail > ',err.response.data)
            if (err.response.data.detail != undefined){
                console.log('Error detail > ',err.response.data['detail'])

                // ถ้า Token Expire Delete
                if (err.response.data['detail'] == 'expired token'){
                    sessionStorage.removeItem('jwtToken');
                }
            }
        }

        responseData = err.response;
    }

    return responseData;

}


/* HTTP - PUT */
export async function HttpPut(url,formdata) {
    //console.log('HttpPut URL > ',url)
    //console.log('HttpPut formdata >> ',formdata)
    let responseData = null;

    try {
        axios.withCredentials  = true,
        await axios.put(url,formdata)
            .then(response => {
                //console.log('HttpPut response > ',response)
                responseData = response;
                return response;
            })
    } catch(err) {
        console.log('HttpPut Error > ',err)
        console.log('Error detail > ',err.response.data['detail'])
    }

    return responseData;

}


/* HTTP - DELETE */
export async function HttpDelete(url,formdata) {
    //console.log('HttpDelete URL > ',url)
    //console.log('HttpDelete formdata >> ',formdata)
    let responseData = null;

    try {
        axios.withCredentials  = true,
        await axios.delete(url,formdata)
            .then(response => {
                //console.log('HttpDelete response > ',response)
                responseData = response;
                return response;
            })
    } catch(err) {
        console.log('HttpDelete Error > ',err)
        if (err.response.data != undefined){
            console.log('Error detail > ',err.response.data['detail'])
        }
    }

    return responseData;

}


/* Post-Get Token , Save and Set Headers */
export async function ValidateToken(userType) {
    //console.log('Enter ValidateToken')
    // clientID
    const _clientID = apiConfig.API_CLIENT_ID()
    const _appID = apiConfig.API_APP_ID

    /* ถ้ามีบันทึกไว้แล้ว ไม่ต้องขอใหม่ !! */
    if (getLocalToken()){
        //console.log('Have Token !!')
        setAuthenHeader(sessionStorage.jwtToken);
        return true;
    }
    else {
        let formdata = new FormData();
        formdata.append("client_id", _clientID);
        formdata.append("app_id", _appID);
        //console.log('Get New Token');
        let _response = false;
        await HttpPost(
            apiConfig.URL_BASE_API() + apiConfig.EndPoint_GetToken,
            formdata
            ).then((response) => {
                let _Token = "";
                //console.log('get New Token `ValidateToken` > ',response);
                if (response.status == 200){
                    _response = true;
                    if (response.data != undefined){
                        _Token = response["data"]["access_token"];

                        /* set Http-Header */
                        setAuthenHeader(_Token);
                    }
                }

            });

        return _response;
    }
}


/* Set Http-Header for Authorization: Bearer {TOKEN} */
export const setAuthenHeader = (token) => {

  if (token) {
      sessionStorage.removeItem('jwtToken');
      sessionStorage.setItem('jwtToken',token);

      axios.defaults.headers = {
          Authorization: 'Bearer '+token,

          }
  }
  else {
      axios.defaults.headers.Authorization = {};
  }

}

export function getLocalToken() {

    if (sessionStorage.jwtToken){
        return sessionStorage.jwtToken;
    }

    return undefined;
}



export default { HttpGet, HttpPost, HttpPut, HttpDelete ,
                ValidateToken, setAuthenHeader , getLocalToken }