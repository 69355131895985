<template>
    <CpnDialogService ref="DialogService" @cbPopUpClosed="getPopUpClosed" />

    <div class="wrapper">
        <form @submit.prevent="forgotpassword" class="forgot-form" method="post">
            <div class="forgot-container">
                    <h1>สมาชิก-ลืมรหัสผ่าน</h1>

                <div class="container">
                    <label for="editemail"><b>ระบุ Email ที่ใช้สมัครสมาชิก</b></label>
                    <input v-model="email" type="email" placeholder="Enter Email"
                            id="email" name="editemail" required autofocus
                            >

                    <CpnImgCaptcha ref="ImageCaptCHA" @cbNewImageCaptCHA="getCaptCHANewImage"  @cbInputTextChanged="getCaptCHAInputText" />

                    <div class="container-footer">
                    <button type="button" class="btncancel" @click="backToCurPage">ยกเลิก</button>
                    <button type="submit" class="submitbtn">ยื่นคำร้อง</button>
                    </div>

                </div>
            </div>
        </form>

        <div class="div-reserved-layout">
        </div>
    </div>
</template>

<script>
    import apiConfig from '@/components/api-config';
    import { HttpPost } from '@/components/lib-http-helper';
    import CpnImgCaptcha from '@/components/cpn-img-captcha.vue';
    import CpnDialogService from '@/components/cpn-dialog-service.vue';
    import FormMail, { SendEmail } from '@/components/lib-sendemail';

    export default {
        emits: ['btnOkClick'],
        data() {
            return {
                email: "",
                CaptCHACodeGen: "",
                CaptCHAInputText: "",
            };
        },
        methods: {
            backToCurPage() {
                this.$router.back();
            },
            getPopUpClosed(trackValue){
                if (trackValue == "UserForgot"){
                    this.$router.back();
                }
            },
            forgotpassword() {
                if (this.CaptCHAInputText != this.CaptCHACodeGen){
                    this.$refs.DialogService.showDlgWarning("ระบบตรวจสอบ","ระบุข้อความไม่ตรงกับข้อความในภาพ");
                    return ;
                }

                let formdata = new FormData();
                formdata.append("email", this.email);

                console.log("forgot >> ");
                HttpPost(apiConfig.URL_BASE_API() + apiConfig.EndPoint_UserForgotPsw, formdata)
                    .then((response) => {
                        console.log("response >> ", response);
                        if (response.status == 200){
                        if (response.data["result"] == "success") {
                            console.log("Email >> ",response.data['email']);
                            SendEmail(FormMail.FORGOT,response.data['email'], response.data['name'], response.data['password'], 'ลืมรหัสผ่าน', apiConfig.WEBSITE_NAME ,"-")
                                    .then((success) => {
                                        console.log("sendEmail >> ", success);
                                        this.$refs.DialogService.showPopUp("<h3>ส่งรหัสผ่านไปทางอีเมลที่ระบุให้แล้ว</h3>",1500,"UserForgot");
                                    });
                        }
                        else {
                            this.email= "";
                            this.$refs.DialogService.showDlgError(
                                "ไม่พบอีเมล ที่ระบุ !!",
                                    "ตรวจสอบอีเมล Email");

                            /* Refresh Image */
                            this.$refs.ImageCaptCHA.getImageCaptCHA();
                        }}
                    });
            },
            getCaptCHANewImage(code){
                this.CaptCHACodeGen = code;
            },
            getCaptCHAInputText(text){
                this.CaptCHAInputText = text;
            },

        },
        components: { CpnImgCaptcha, CpnDialogService },

    }
</script>

<style lang="css" scoped>

.forgot-form{
    margin-top: 50px;
}
.forgot-container{
    display: block;
    margin:auto;
    padding: 20px;
    max-width: 50%;
    align-items: center;
    align-content: center;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px grey;
    background-color: #ddd;
}

input[type=email], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #04AA6D;
  font-size: 16px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}

.container-footer{
    background-color:#f1f1f1;
    align-content: space-between;
    text-align: center;
}

.btncancel {
  width: 150px;
  padding: 10px 18px;
  background-color: #f44336;
}

.submitbtn {
  margin-left: 20px;
  width: 150px;
  padding: 10px 18px;
  background-color: #04AA6D;
  width: 45%;
}


.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .btncancel {
     width: 100%;
  }
}

.details-otp{
    cursor: pointer;
    margin: 10px 0px 10px 0px;
    border: 1px solid rgb(201, 200, 200);
    padding: 4px;
}

.div-reserved-layout{
    min-height: 70px;
    height: auto;
    overflow: hidden;
}
</style>