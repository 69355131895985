<!-- eslint-disable no-unused-vars -->
<template>
    <CpnDataUser ref="UserDataCPN" @cbDataLoaded="getUserDataLoaded" />
    <CpnDataAuthor @cbDataLoaded="getAuthorDataLoaded" />

    <div class="wrapper">
        <div class="blog-container">
            <div class="blog-image">
            <img v-bind:src="blog_image_url" alt="blog-image" />
            </div>

            <CpnAuthorBanner
                :blog_id= blog_id
                :blog_title= blog_title
                :blog_subtitle= blog_subtitle
                :blog_tag= blog_tag
                :blog_date= showThaiDate(blog_date)
                :blog_group_code=blog_group_code
                :blog_link= getURLBlogLink(blog_id)
                :author_id= author_id
                :author_name= author_name
                :author_avatar_url= author_avatar_url
            />

            <div class="blog-body">
                <!-- <span class="tag tag-color">{{ blog_group_code }} </span> -->
                <h2>{{ blog_title }}</h2>

                <p class="blog-content">
                    <span name="code-in-markdown">
                        <MdEditor :modelValue="blog_content"
                                language="en-US"
                                previewTheme="vuepress"
                                :showCodeRowNumber="false"
                                hideToolbar previewOnly />
                    </span>
                </p>
            </div>
            <slot>
                Insert Slot !!
            </slot>

            <!-- <div v-if="AuthorData.author_id == author_id">
                <button type="button" class="btn-edit-blog" @click="doBlogEdit">แก้ไขบทความ</button>
                <button type="button" class="btn-delete-blog" @click="doBlogDelete">ลบบทความ</button>
            </div> -->
        </div>
    </div>

    <div class="container-space-50"></div>

</template>

<script>
    import { showThaiDate } from "@/components/lib-thaidate";
    import apiConfig from "./api-config";
    import MdEditor from 'md-editor-v3';
    import 'md-editor-v3/lib/style.css';
    import CpnAuthorBanner from "./cpn-banner-author.vue";
    import CpnDataUser from "./cpn-data-user.vue";
    import { HttpPost } from './lib-http-helper';
    import CpnDataAuthor from "./cpn-data-author.vue";

    export default {
        props: {
            blog_title: { type: String, required: true },
            blog_subtitle: { type: String, required: true },
            blog_content: { type: String, required: true },
            blog_image_url: { type: String, required: true },
            blog_id: { type: Number, required: true },
            blog_group_code: { type: String, required: true },
            blog_date: { type: String, required: true },
            blog_tag: { type: String, required: true },
            author_id: { type: String, required: true },
            author_name: { type: String, required: true },
            author_avatar_url: { type: String, required: true },
        },
        data(){
            return {
                UserData : Object,
                AuthorData : Object,
                showComment: false,
            }

        },
        components: { MdEditor, CpnAuthorBanner, CpnDataUser, CpnDataAuthor },
        mounted() {
            this.getBlogViewCount();
        },
        methods: {
            doBlogEdit(){
                console.log('doBlogEdit');
            },
            doBlogDelete(){
                console.log('doBlogDelete');
            },
            getURLBlogLink(blogID){
                return apiConfig.URL_SHARE_BLOG(blogID);
            },
            getURLImageAtServer(url) {
                //console.log('author_avatar_url',url);
                return apiConfig.URL_IMG_AUTHOR()  + "/" + url;
            },

            doBtnClickNeedReply(){
                this.showComment = !this.showComment;
            },
            showThaiDate(dateStr) {
                return showThaiDate(dateStr);
            },

            getUserDataLoaded(data){
                this.UserData = data;
            },
            getAuthorDataLoaded(data){
                this.AuthorData = data;
            },
            async getBlogViewCount(){

                let formdata = new FormData();
                formdata.append("act_type",'BLOG');
                formdata.append("blog_id",parseInt(this.blog_id));
                formdata.append("comment_id",0);
                formdata.append("act_group",'VIEW');
                formdata.append("user_id",this.UserData.user_id);

                await HttpPost(apiConfig.URL_BASE_API()+apiConfig.EndPoint_PostActivity,formdata)
                    .then((response) => {
                        console.log('view-count',response.data.count);
                        // response.data {result: 'success',count: X}
                        // response.data {result: 'fail',count: X}
                        //this.$emit('blogviewqty',response.data.count);
                    })
            }
        },
        computed:{
            calcblog_id(){
                return this.blog_id;
            },
            calcauthor_id(){
                return this.author_id;
            },
            calccomment_id(){
                return 123456;
            },
            calcreply_to(){
                return this.reply_to;
            },
            calcuser_id(){
                return this.UserData.user_id
            },
            showNumber(){
                return true;
            },

        }
    };
</script>


<style scoped>
@import "@/css/blog-item.scss";
@import "@/css/responsive-page.scss";
.blog-image{
    align-content: center;

    width: 80%;
    }
</style>

<style>
    @import "@/css/custom-markdown.css";
    @import "@/css/custom-markdown2.css";

.vuepress-theme pre code{
    font-size: 16px;
    background-color: rgb(224, 224, 224);
}

.btn-edit-blog{
    margin: 4px;
    margin-left: 0px;
    padding: 10px;
    font-size: 16px;
    background-color: aquamarine;
    border-radius: 8px;
    cursor: pointer;
}

.btn-delete-blog{
    margin: 4px;
    margin-left: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: red;
    border-radius: 8px;
    cursor: pointer;
}

</style>