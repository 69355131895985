<template>
    <div class="toggle-button-container" >
        <div class="button r" id="button-1">
            <input type="checkbox" :class="enabled==true ? 'checkbox' : 'checkbox-disabled' "
                :checked="!value"
                :value="value"
                :disabled="!enabled"
                @change="getValueChange()"
                >
        <div class="knobs" ></div>
        <div class="layer" ></div>
        </div>
        <slot class="toggle-text"></slot>

    </div>

</template>

<script>

    export default {
        emits:['cbValueChange','cbEditValueChange','cb1ValueChange','cb2ValueChange','cb3ValueChange'],
        props: {
            name:{type:String,default:"toggle",required: true},
            value:{type:Boolean,default:false,required: true},
            enabled:{type: Boolean,default:true,},
            callbackEvent:{type:String,default:"cbValueChange" ,required: true},
        },
        mounted() {
            //console.log("init Toggle value >> ",this.value);
        },
        methods: {
            getValueChange(){
                const _event = 'cb'+this.name+'ValueChange';
                console.log("Toggle callback >>",_event,!this.value);
                this.$emit(_event,!this.value);
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/css/toggle.scss';

    .toggle-text{
        text-align: left;
    }
</style>