<template>

<CpnNavSubGroup
    :group_code="group_code"
    :group_owner="group_owner"
    />
<CpnBannerBetaTest />

<CpnOverlayBox
        />

<section id="home">
    <div class="myrow">
        <div class="mycol">
            <header class="top-intro">
                <h1 class="lead">
                    * {{ calc_group_name }} *
                </h1>
                <p>{{ calc_group_title }} </p>
            </header>
        </div>
    </div>

    <section class="hero0">
        <img :src= "getURLImageAtServer(calc_group_url)" class="group-background">
    </section>
</section>

<div class="myrow-uneven">

    <div class="mycol-left">

            <!-- * MAIN - BLOG * -->
            <div class="container-blog">

                    <!-- * if status == loading > Show loading * -->
                    <div v-if="processIsEnd == false || pageloading == true" >
                    <CpnLoader3Ball
                        :loading="pageloading"
                        :loadingtext="loadingtext"
                    />
                    </div>
                    <div v-else-if="processIsEnd && GroupBlogsInfo?.length == 0" >
                        <PageNoContent />
                    </div>

                    <!-- * Loop ข้อมูลจากเซิฟ * -->
                    <div
                        class="blogs"
                        v-for="blog_1row in blog_datas"
                        :key="blog_1row['blog_id']"
                    >

                    <CpnBlockBlog
                            :blog_title="blog_1row['blog_title']"
                            :blog_subtitle="blog_1row['blog_subtitle']"
                            :blog_content="blog_1row['blog_content'].substr(0, 199)"
                            :blog_image_url="blog_1row['blog_image_url']"
                            :blog_id="blog_1row['blog_id']"
                            :blog_group_code="blog_1row['blog_group_code']"
                            :blog_date="blog_1row['blog_date']"
                            :blog_count_views="blog_1row['blog_count_views']"
                            :author_name="blog_1row['author_name']"
                            :author_avatar_url="blog_1row['author_avatar_url']"
                        />

                    </div>

            </div>
        </div>



  <!-- SIDEBAR -->
  <div class="mycol-right">

        <section class="popular-posts">
            <header class="sidebar-header">
                    <h3 class="header-white">แนะนำ</h3>
            </header>
            <!-- * Top10 -- Loop ข้อมูลจากเซิฟ * -->
            <div class="blogs-top10"
                v-for="blog_top10 in blogTop10_datas" :key="blog_top10['blog_id']">
                  <CpnBlockBlogRecommend
                      :blog_id = "blog_top10['blog_id']"
                      :blog_title = "blog_top10['blog_title']"
                      :blog_image_url = "blog_top10['blog_image_url']"
                      :blog_group_code = "blog_top10['blog_group_code']"
                      :blog_count_views = "blog_top10['blog_count_views']"
                  />
            </div>
        </section>


        <section class="recommend2">
            <header class="sidebar-header">
                <h3 class="header-white"> บทความเป็นชุด </h3>
            </header>

        </section>
    </div>
</div>

</template>


<script>

    import { HttpGet, ValidateToken, } from "@/components/lib-http-helper";
    import { ref, } from 'vue'
    import apiConfig from "@/components/api-config";
    import CpnBlockBlog from '@/components/cpn-blockblog-main.vue';
    import CpnBlockBlogRecommend from '@/components/cpn-block-show-recommends.vue';
    import CpnLoader3Ball from '@/components/cpn-loader-3ball.vue';
    import CpnOverlayBox from '@/components/cpn-menu-overlay-box.vue';
    import CpnNavSubGroup from '@/components/cpn-nav-subgroup.vue';
    import PageNoContent from './page-no-content.vue';
    import CpnBannerBetaTest from '@/components/cpn-banner-betatest.vue';

    export default {
        components: { CpnBlockBlog, CpnLoader3Ball, CpnBlockBlogRecommend, CpnOverlayBox, PageNoContent, CpnNavSubGroup ,CpnBannerBetaTest},
        processIsEnd: ref(false),

        data() {
            return {
                blog_datas: [],
                blogTop10_datas: [],
                GroupBlogsInfo: [],
                errors: [],
                loadingtext: "โปรดรอ กำลังโหลดข้อมูล ...",
                pageloading: false,
            };
        },
        props: {
            group_code: {type: String} ,
            group_owner: {type: String},
        },
        created() {
            this.processIsEnd = false;
            // this.getValues();
        },
        mounted() {
            this.processIsEnd = false;
            //console.log('route>param',this.$route.params);
            this.getValues();
        },
        computed:{
            // calc_BlogsData(){
            //     return this.GroupBlogsInfo;
            // },
            calc_group_name(){
                if (this.GroupBlogsInfo?.length > 0){
                    return this.GroupBlogsInfo[0]['group_name'];
                }
                return "";
            },
            calc_group_url(){

                if (this.GroupBlogsInfo?.length > 0){
                    return this.GroupBlogsInfo[0]['group_background_url'];
                }
                return apiConfig.API_IMG_EMPTY;
            }
            ,
            calc_group_title(){
                if (this.GroupBlogsInfo?.length > 0){
                    return this.GroupBlogsInfo[0]['group_title'];
                }
                return "";
            }

        },
        methods: {
            async getValues() {
                this.pageloading = true;
                this.processIsEnd = false;
                await ValidateToken('page-group').then((success) => {
                    if (success){
                        //console.clear;
                        // this.calc_BlogsData = [];

                        this.getGroupBlogInfo();

                        //this.getBlogsInMainGroup();

                        this.getBlogsByGroupSelected();

                        this.getBlogsTop10AllGroup();

                        this.processIsEnd = true;
                    }

                });
            },
            async getGroupBlogInfo(){
                this.GroupBlogsInfo = [];
                console.log('getGroupBlogInfo > ',this.group_owner+'/'+this.group_code);
                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_GroupInfo+'/'+ this.group_owner+'/'+this.group_code,
                        "getGroupBlogInfo "+this.group_owner+'/'+this.group_code).then(
                            (response) => {
                                //console.log('getGroupBlogInfo > ',response.data);
                                this.GroupBlogsInfo = response.data;
                            }
                        );

            },

            // async getBlogsInMainGroup() {
            //     //console.log("getAll in MainBlogs > ",this.group_owner+'/'+this.group_code);
            //     HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_AllBlogsInMainGroup+'/'+ this.group_owner+'/'+this.group_code,
            //             "getAll in MainBlogs "+this.group_owner).then(
            //                 (response) => {
            //                     console.log('response > getAll in MainBlogs > ',response.data);
            //                     this.blog_datas = response.data;
            //                     // this.calc_BlogsData = response.data;
            //                 }
            //             );
            // },

            async getBlogsByGroupSelected() {
                console.log("getBlogsByGroupSelected > ",this.group_owner+'/'+this.group_code);
                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_AllBlogsByGroup+'/'+ this.group_owner+'/'+this.group_code,
                        "by group "+this.group_owner+'/'+this.group_code).then(
                            (response) => {
                                this.blog_datas = response.data;
                                console.log('response > getBlogsByGroupSelected > ',response.data);
                                // this.calc_BlogsData = response.data;
                            }
                        );
            },

            async getBlogsTop10AllGroup() {
                this.blogTop10_datas = [];
                //console.log("getBlogsTop10AllGroup > ",this.group_owner+'/'+this.group_code);
                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_blog_top10+'/'+this.group_owner+'/'+this.group_code,'Top10 Group').then(
                    (response) => {
                        this.blogTop10_datas = response.data;
                        console.log('response > getBlogsTop10AllGroup > ',response.data);
                        this.pageloading = false;
                        this.processIsEnd = true;
                    }
                );
            },

            getURLImageAtServer(url) {
                return apiConfig.URL_IMG_GROUP() + "/" + url;
            },
        },
    };

</script>

<style>
  @import "@/css/main.css";
  @import "@/css/custom-markdown.css";

  h1,h2,h3,h4,h5,h6{
    color: black;
  }
  .header-white{
    color: white;
  }

  div.blogs{
    background-color: white;
    margin-bottom: 20px;
  }

  .post-btm-bar{
    padding: 10px;
  }
/*
  .blog-content-subtitle{
    background-color: #FAFAFA;
  }

  .blog-content-markdown{
    background-color: #FAFAFA;
  } */

  .blog-group{
    color: black;
  }

  .blog-title{
    color: black;
  }

  .popular-posts{
      margin: 0px;
      display: block;
  }
</style>