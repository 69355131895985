<template>
    <CpnDataUser ref="UserDataCPN" @cbDataLoaded="getUserDataLoaded" />
    <div class="comment-container-sub" :id="comment_id">

                <div class="section-member-sub">
                    <div class="member-image"> <img :src="avatar_url" alt="member image" /> </div>
                    <div class="member-name" >{{ user_name }}</div>
                    <div class="comment-date">{{ funccnvStrToThaiDateTimeAndShowAge(create_datetime) }} </div>
                    <div class="comment-id">#{{ blog_id }} @{{ comment_id }}</div>
                    <div class="member-buttons">
                            <!--
                                login.user_id == comment.user_id
                            -->
                            <button  class="member-button-edit" @click="doBtnClickComment-Edit" > แก้ไข </button>
                            <!--
                                login.user_id == "MOD"
                            -->
                            <button class="member-button-delete" @click="doBtnClickComment-Delete"> ลบ </button>
                    </div>
                </div>


                <div class="section-comment">
                    <div>

                        <MdEditor :modelValue="comment_content"
                                language="en-US"
                                previewTheme="github"
                                :showCodeRowNumber="false"
                                hideToolbar previewOnly />

                                <!-- previewTheme = 'default' | 'github' | 'vuepress' | 'mk-cute' | 'smart-blue' | 'cyanosis'
                                codeTheme= 'atom' | 'a11y' | 'github' | 'gradient' | 'kimbie' | 'paraiso' | 'qtcreator' | 'stackoverflow'
                                -->
                    </div>
                </div>



                <div class="section-footer">
                                <span>
                                    <button class="blog-star-qty">{{ StarCount }} &#9733;</button>
                                    <button class="btn-star" @click="doBtnClickAddStar"> + ดาว &#9734;</button>
                                </span>

                                <span>
                                    <button class="blog-comment-qty" @click="doBtnClickShowReply" >{{ comment_count }} ความคิดเห็น</button>
                                    <button class="btn-comment" @click="doBtnClickNeedReply">💬 ความคิดเห็น</button>
                                </span>
                            </div>

    </div>

    <ul v-if="subcomment_datas.length > 0" >
        <div v-for="comment,index in subcomment_datas"
                    :key="index" >

                <li>
                    <div>
                        <!-- {{ subcomment_datas }} -->
                        <!-- // All SubComment // -->
                        <CpnBlockCommentSub
                            :blog_id = "parseInt(comment['blog_id'])"
                            :blog_title = "comment['blog_title']"
                            :blog_subtitle = "comment['blog_subtitle']"
                            :blog_tag = "comment['blog_tag']"
                            :reply_to = "parseInt(comment['reply_to'])"
                            :author_id = "comment['author_id']"
                            :user_id = "comment['user_id']"
                            :user_name = "comment['user_name']"
                            :user_avatar_url = "comment['user_avatar_url']"
                            :comment_id = "parseInt(comment['comment_id'])"
                            :comment_item_no = "parseInt(comment['comment_item_no'])"
                            :create_datetime = "comment['create_datetime']"
                            :comment_approved = "comment['comment_approved']"
                            :comment_content = "comment['comment_content']"
                            :star_count="comment['star_count']"
                            :comment_count="comment['comment_count']"
                            @onNeedReply = "doUserNeedReply"

                        />

                        <!-- @onSub CommentClickReply="DoOnSubCommentClickReply"                     -->
                        showSubCommentEditor-sub-1  {{ showSubCommentEditor }}
                        {{ this.ReplyComment_id }}
                        {{ comment['comment_item_no'] }}

                        <div v-if="showSubCommentEditor == true">
                            <!-- /* เพิ่ม comment ตัวเอง Level-1

                                */ -->
                            <!-- <div v-if="ReplyThisComment(comment['comment_id'],comment['comment_item_no'])"> -->
                                SUB-COMMENT1
                                    <CpnEditorComment
                                        listenToEvent="cbPostReplySubFinished"
                                        :blog_id="comment['blog_id']"
                                        :author_id="comment['author_id']"
                                        :reply_to="this.ReplyComment_id"
                                        @cbPostReplySubFinished="DocbCommentPostFinishReply2"
                                        @cbCommentPostCancel="DoCommentPostCancel"
                                    />
                            <!-- </div> -->
                        </div>

                    </div>
                </li>
        </div>

    </ul>


    <div v-else>
        <div v-if="showSubCommentEditor == true">
            SUB-COMMENT2
            <CpnEditorComment
                listenToEvent="cbPostReplySubFinished"
                :blog_id="this.blog_id"
                :author_id="this.author_id"
                :reply_to="this.ReplyComment_id"
                @cbPostReplySubFinished="DocbCommentPostFinishReply2"
                @cbCommentPostCancel="DoCommentPostCancel"
                />
        </div>
    </div>

<!-- </div> -->

</template>

<script>

    import MdEditor from 'md-editor-v3';
    import 'md-editor-v3/lib/style.css';
    import apiConfig from './api-config';
    import {funccnvStrToThaiDateTimeAndShowAge} from './lib-common';
    import { HttpGet, HttpPost } from './lib-http-helper';
    import CpnBlockCommentSub from '@/components/cpn-block-comment-sub.vue';
    import CpnEditorComment from './cpn-editor-comment.vue';
    import CpnDataUser from './cpn-data-user.vue';

    // *************************************************************************************
    // Error !!
    // If this is a native custom element,
    // make sure to exclude it from component resolution via compilerOptions.isCustomElement.
    // components:{ ต้องมีตัว S "components" }, ถ้าไม่ใช่ก็ตรวจสอบ ชื่อ property ต่างๆ ใน Script
    // *************************************************************************************

    export default {
        emits: ['cbCommentPostFinish','cbCommentPostCancel','onNeedReply','onNeedShowReply'],
        props: {
            blog_id: { type: Number, required: true },
            blog_title: { type: String, required: true },
            blog_subtitle: { type: String, required: true },
            blog_tag: { type: String, required: true },
            reply_to: { type: Number, required: true },
            author_id: { type: String, required: true },
            user_id: { type: String, required: true },
            user_name: { type: String, required: true },
            user_avatar_url: { type: String, required: true },
            comment_id: { type: Number, required: true },
            comment_item_no: { type: Number, required: true },
            create_datetime: { type: String, required: true },
            comment_approved: { type: String, required: true },
            comment_content: { type: String, required: true },
            star_count: { type: Number, required: true },
            comment_count: { type: Number, required: true },

        },
        data() {
            return {
                UserData : Object,
                subcomment_datas: {},
                showComment: false,
                showSubCommentEditor: false,
                StarCount: 0,
                ReplyBlog_id: 0,
                ReplyComment_id: 0,
                ReplyComment_itemNo:0,
            }
        },
        components:{ MdEditor, CpnBlockCommentSub, CpnEditorComment, CpnDataUser },
        computed:{
            avatar_url(){
                if (this.user_avatar_url != "null" && this.user_avatar_url){
                    return apiConfig.URL_IMG_AVATAR()+'/'+this.user_avatar_url;
                } else {
                    return apiConfig.URL_IMG_AVATAR()+"/empty.png";
                }
            },
        },
        mounted() {
            // get value from prop
            this.StarCount = this.star_count;
        },
        methods: {
            async funcGetSubComments(){
                console.log(this.blog_id+'/'+this.comment_id);
                await HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_getMainCommentsub+'/'+ this.blog_id+'/'+this.comment_id,"getcomment-sub ")
                    .then((response) => {
                        this.subcomment_datas = response.data;
                    });
            },
            funccnvStrToThaiDateTimeAndShowAge(value){
                return funccnvStrToThaiDateTimeAndShowAge(value);
            },
            // funcGetPathToShare(blogID){
            //     return apiConfig.URL_SHARE_BLOG(blogID);
            // },
            getUserDataLoaded(data){
                //console.log('blog-loaded',data);
                this.UserData = data;
            },

            doBtnClickCommentEdit(){

            },
            doBtnClickCommentDelete(){

            },
            doBtnClickNeedReply(){
                console.log('doBtnClickNeedReply');
                this.ReplyBlog_id = this.blog_id;
                this.ReplyComment_id = this.comment_id;
                this.ReplyComment_itemNo =this.comment_item_no;
                this.showSubCommentEditor = !this.showSubCommentEditor;
                console.log('showSubCommentEditor',this.showSubCommentEditor);
                console.log('ReplyBlog_id-sub-240',this.ReplyBlog_id);
                console.log('comment_id',this.comment_id);
                console.log('ReplyComment_itemNo',this.comment_item_no);
                console.log('ReplyComment_id',this.ReplyComment_id);

            },

            doUserNeedReply(data){
                console.log('doUserNeedReply',data);

                this.ReplyBlog_id = data.blog_id;
                this.ReplyComment_id = data.reply_comment_id;
                this.ReplyComment_itemNo =this.reply_comment_itemno;
                this.showSubCommentEditor = !this.showSubCommentEditor;

            },

            doBtnClickShowReply(){
                this.funcGetSubComments();
            },

            async doBtnClickAddStar(){
                let formdata = new FormData();
                formdata.append("act_type",'BLOG');
                formdata.append("blog_id",parseInt(this.blog_id));
                formdata.append("comment_id",parseInt(this.comment_id));
                formdata.append("act_group",'STAR');
                formdata.append("user_id",this.UserData.user_id);

                await HttpPost(apiConfig.URL_BASE_API()+apiConfig.EndPoint_PostActivity,formdata).then((response) => {

                    console.log('count',response.data.count);

                    this.StarCount = response.data.count;
                })
            },
            ReplyThisComment(cId,cNo){
                const _Replythis = (this.ReplyComment_id == cId &&
                    this.ReplyComment_itemNo ==  cNo)
                console.log('_Replythis',_Replythis);
                return _Replythis;
            },
            DocbCommentPostFinishReply2(datas){
                console.log('DocbCommentPostFinishReply2-2',datas);
                this.funcGetSubComments();
            },
            DoCommentPostCancel(){
                console.log('DoCommentPostCancel-2');
                this.showSubCommentEditor = false
            }
        },


    }
</script>

<style scoped>
.overtop-container {
  display: grid;
  gap: 0px 0px;

  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 0.5fr 0.1fr;
}

.comment-container-sub{
	background: rgba(224, 237, 250 , 0.4);
	border-radius: 10px;
	-webkit-box-shadow: 4px 4px 7px 4px rgba(0,0,0,0.3);
	box-shadow: 4px 4px 7px 4px rgba(0,0,0,0.3);
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
}


.section-member-sub{
    display: grid;
    grid-template-columns: 15% 60% 25%;
    grid-template-rows: 0.1fr 0.1fr 0.8fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #c6fce5;
}

.member-image{
    grid-area: 1 / 1 / 3 / 2;
    width: 50%;
    max-width: 60px;
    margin: 5px;
}

.member-name{
    grid-area: 1 / 2 / 2 / 3;
}

.comment-date {
    grid-area: 3 / 2 / 4 / 4;
    margin-top: 20px;
    font-size: 14px;
}

.comment-id{
    grid-area: 1 / 3 / 2 / 4;
    font-size: 14px;
    color: rgb(22, 22, 110);
    text-align: center;
    margin-right: 10px;
}


.member-buttons {
    grid-area: 3 / 3 / 4 / 4;
    text-align: center;
}

.member-button-edit{
    font-size: 1rem;
    border: 1px solid #ffb71b;
    background: #ffb71b;
    color: black;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px 0px 0px 4px;
    cursor: pointer;
    display: none;
}

.member-button-delete{
    font-size: 1rem;
    border: 1px solid #ff221b;
    background: #ff221b;
    color: white;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
    display: none;
}


.section-footer{
    color: black;
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 10px;
    margin-right: 5px;
    margin-bottom: -10px;

}


.blog-comment-qty{
    font-size: 1rem;
    border: 1px solid #d8fce7;
    background: #d8fce7;
    color: #000;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px 0px 0px 4px;
    cursor: pointer;
    align-self:baseline;
}

.btn-comment{
    font-size: 1rem;
    border: 1px solid #92cfbb;
    background: #92cfbb;
    color: #000;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-right: 10px;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
    align-self:baseline;
}

.blog-star-qty{
    font-size: 1rem;
    border: 1px solid #d8fce7;
    background: #d8fce7;
    color: #000;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px 0px 0px 4px;
}

.btn-star{
    font-size: 1rem;
    border: 1px solid #92cfbb;
    background: #92cfbb;
    color: #000;
    margin: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
}

</style>