<template>
    <CpnDataAuthor ref="AuthorDataCPN" @cbDataLoaded="getAuthorDataLoaded" />

    <CpnDialogService ref="DialogService"
        @cbDeleteAccount="getDeleteAuthorAccountOK"
        @cbPolicyOK="getPolicyOK"
        @cbPolicyCancel = "getPolicyCancel"
        @cbPopUpClosed = "getPopUpClosed"
        />

    <CpnCpnLoader3Ball
        :loading="pageloading"
        :loadingtext="loadingtext" />

    <div class="wrapper">
        <form  @submit.prevent="TryUpdateData" class="signup-form">
            <div class="signup-container">
                                <div class="box-small" >

                    <CpnToggle :value="IsEditMode" name="Edit"
                        callbackEvent="cbEditmodeChange"
                        @cbEditValueChange="getEditmodeChang">แก้ไขข้อมูล</CpnToggle>

                    <button type="button" class="btndelete" @click="showDlgDeleteAccount">ลบแอคเคาน์</button>
                </div>
                <h1>ข้อมูลผู้แชร์ข้อมูล</h1>
                <hr>

                <div class="box40per" >
                    <div class="box-avatar-img">
                        <p> รูป Avatar ปัจจุบัน</p>

                        <img :src="calc_avatar_url" class="img_avatar" alt="author avatar"/>
                    </div>
                </div>

                <div class="box50per" >
                    <CpnSlideshows  @userClickAvatar="doUserClickAvatar" />
                </div>

                <div class="box100" ></div>

                <div class="box1" >
                <label for="authorname"><b>Name ชื่อหรือนามแฝง</b></label>
                <input type="text" v-model="AuthorData.author_name"
                        :readonly="!IsEditMode"
                        @change="authornameChange(AuthorData.author_name)"
                        placeholder="Enter author Name" name="authorname" required >
                </div>

                <div class="box2" >
                <label for="psw"><b>Password (!= '' ? NeedUpdate)</b></label>
                <input type="password" v-model="AuthorData.author_psw"
                        :readonly="!IsEditMode"
                        @change="authorpasswordChange(AuthorData.author_psw)"
                        placeholder="ถ้าไม่เท่ากับ '' = ต้องการอัพเดต" name="psw">
                </div>

                <div class="box3" >
                <label for="desc"><b>Description เกี่ยวกับตัวคุณ</b></label>
                <input type="text" v-model="AuthorData.author_description"
                        :readonly="!IsEditMode"
                        @change="authorDescChange(AuthorData.author_description)"
                        placeholder="Enter Description" name="desc" required>
                </div>

                <div class="box3" >
                <label for="email"><b>Email</b></label>
                <input type="text" v-model="AuthorData.author_email"
                        :readonly="!IsEditMode"
                        @change="authoremailChange(AuthorData.author_email)"
                        placeholder="Enter Email" name="email" required>
                </div>

                <div class="clearfix">
                    <button type="button" class="btncancel" @click="backToCurPage">ปิด</button>
                    <button type="submit" :disabled="!IsEditMode" class="btnsubmit">อัพเดต</button>
                </div>

            </div>
        </form>
        <br/>

            <p class="cookies-text">เว็บไซต์นี้ใช้ข้อมูลคุกกี้ เพื่อเพิ่มประสิทธิภาพในการทำงาน เพื่อทำข้อมูลสถิติ และ การตลาด อ่าน
                <a @click="showDlgPolicy" style="color:dodgerblue;cursor: pointer;">
                    ข้อตกลงและนโยบายเกี่ยวกับการใช้งานคุกกี้</a></p>

    </div>
    <div class="div-reserved-layout">
        </div>
</template>

<script>
    import apiConfig from '@/components/api-config';
    import libCommon, { isTrue } from '@/components/lib-common';
    import { HttpPost , HttpDelete, ValidateToken, HttpPut } from '@/components/lib-http-helper';
    import FormMail, { SendEmail } from '@/components/lib-sendemail';
    import CpnSlideshows from '@/components/cpn-slideshows.vue';
    import CpnCpnLoader3Ball from '@/components/cpn-loader-3ball.vue';
    import CpnToggle from '@/components/cpn-toggle.vue';
    import CpnDataAuthor from '@/components/cpn-data-author.vue';
    import CpnDialogService from '@/components/cpn-dialog-service.vue';


    export default {
        emits: ['cbDeleteAccount'],
        components: { CpnSlideshows, CpnCpnLoader3Ball, CpnToggle, CpnDataAuthor, CpnDialogService, },
        data() {
            return {
                AuthorData: Object,
                IsEditMode: false,
                pageloading: false,
                loadingtext: "อัพเดตข้อมูล ...",
                base_author_name: null,
                base_author_email: null,
                base_author_avatar_url: null,
                base_author_psw: null,
                base_author_description: null,

                update_author_name: null,
                update_author_email: null,
                update_author_avatar_url: null,
                update_author_psw: null,
                update_author_description: null,

            };
        },
        mounted(){
            console.log('mounted',this.AuthorData.author_signedIn);
            if (isTrue(this.AuthorData.author_signedIn) == false){
                console.log('redirect >> Author not Login ');
                this.$router.push('home')
            }
        },
        methods: {
            getPolicyCancel(){
                this.$refs.AuthorDataCPN.setValue('accept_cookies',false);
                window.close();
            },
            getPolicyOK(){
                this.$refs.AuthorDataCPN.setValue('accept_cookies',true);
            },
            getEditmodeChang(value){
                this.IsEditMode = value;
                // console.log('getEditmodeChang',value);
                if (value == true){
                    this.$refs.DialogService.showPopUp("<h3>เข้าสู่โหมดแก้ไขข้อมูล</h3>",1500);
                }
            },
            showDlgPolicy() {
                this.$refs.DialogService.showDlgPolicy('cbPolicyOK','cbPolicyCancel');
            },
            backToCurPage() {
                this.$router.back();
            },

            doUserClickAvatar(url){
                if (this.IsEditMode){
                    console.log('doauthorClickAvatar',url)
                    this.AuthorData.author_avatar_url = url;
                    this.update_author_avatar_url = url;
                }
            },

            authornameChange(newValue){
                console.log('authornameChange',newValue)
                if (newValue != ""){
                    this.update_author_name = newValue;
                }
            },

            authorpasswordChange(newValue){
                console.log('authorpasswordChange',newValue)
                if (newValue != ""){
                    this.update_author_psw = newValue;
                }
            },

            authoremailChange(newValue){
                console.log('authoremailChange',newValue)
                if (newValue != ""){
                    this.update_author_email = newValue;
                }
            },

            authorDescChange(newValue){
                console.log('authorDescChange',newValue)
                if (newValue != ""){
                    this.update_author_description = newValue;
                }
            },

            TryUpdateData(){
                /* Keep Change fields */
                if (this.AuthorData.author_name !=  this.base_author_name){
                    this.update_author_name = this.AuthorData.author_name;
                }

                if (this.AuthorData.author_email != this.base_author_email){
                    this.update_author_email = this.AuthorData.author_email;
                }

                if (this.AuthorData.author_psw != this.base_author_psw){
                    this.update_author_psw = this.AuthorData.author_psw;
                }

                if (this.AuthorData.author_avatar_url != this.base_author_avatar_url){
                    this.update_author_avatar_url = this.AuthorData.author_avatar_url;
                }

                //console.log('TryUpdateData name ',this.AuthorData.author_name);
                //console.log('TryUpdateData name ',this.base_author_name);
                this.checkAvailableAuthorName(this.AuthorData.author_name);
            },

            async checkAvailableAuthorName() {
                this.IsEditMode = false;
                this.pageloading = true;
                return await ValidateToken('author-info').then((success) => {
                    console.log("ValidateToken >> ", success);

                    /* มีการแก้ไขข้อมูล ? */
                    if ((this.AuthorData.author_name != this.base_author_name) ||
                        (this.AuthorData.author_email != this.base_author_email) ||
                        (this.AuthorData.author_psw != this.base_author_psw) ||
                        (this.AuthorData.author_description != this.base_author_description) ||
                        (this.AuthorData.author_avatar_url != this.base_author_avatar_url)){

                        // #1 CheckName
                        if (this.AuthorData.author_name == this.base_author_name){
                            // TEST updateData
                            this.updateAuthorData(
                                this.update_author_name,
                                this.update_author_email,
                                this.update_author_psw ,
                                this.update_author_avatar_url,
                                this.update_author_description);

                            //this.TestSendMailAndUpdate();
                        } else
                        {
                            //checkNameOK = false;
                            let formdata = new FormData();
                            formdata.append("author_name", this.AuthorData.author_name);

                            // # CheckName If Request Update
                            HttpPost(apiConfig.URL_BASE_API() + apiConfig.EndPoint_CheckAuthorNameAvailable, formdata)
                                .then((response) => {
                                    //console.log("check-authorName result >> ", response.data["result"]);
                                    if (response.status == 200){
                                    if (response.data["result"] == "available") {
                                        //console.log("checkNameOK>> ", checkNameOK);

                                        // TEST updateData
                                        this.updateAuthorData(
                                            this.update_author_name,
                                            this.update_author_email,
                                            this.update_author_psw ,
                                            this.update_author_avatar_url,
                                            this.update_author_description);

                                        //this.TestSendMailAndUpdate();
                                    }}
                                });
                        }

                    } else {
                        this.pageloading = false;
                        this.IsEditMode  = false;
                        this.$refs.DialogService.showPopUp("<h3>ไม่มีรายการอัพเดต</h3>",1500);
                    }
                });
            },

            async TestSendMailAndUpdate(){
                // #2 sendEmail
                this.message = "อัพเดตข้อมูล แอคเคาน์";
                SendEmail(FormMail.MEMBER,this.AuthorData.author_email, this.AuthorData.author_name, this.AuthorData.author_psw, this.message, this.from_name)
                                .then((success) => {
                                    console.log("sendEmail >> ", success);
                                    if (success) {
                                        // #3 updateData
                                        this.updateAuthorData(
                                            this.update_author_name,
                                            this.update_author_email,
                                            this.update_author_psw ,
                                            this.update_author_avatar_url,
                                            this.update_author_description);
                                    }
                                });

            },

            async updateAuthorData(update_author_name, update_author_email, update_author_psw,
                    update_author_avatar_url,update_author_description) {

                //console.log("updateauthor >> ");
                let formdata = new FormData();

                formdata.append("author_id", this.AuthorData.author_id);
                formdata.append("author_name", update_author_name);
                formdata.append("author_email", null);
                formdata.append("author_password", null);
                formdata.append("author_avatar_url", null);
                formdata.append("author_description", null);

                if (update_author_name != "" && update_author_name != null){
                    formdata.set("author_name", update_author_name);
                }

                if (update_author_email != "" && update_author_email != null ){
                    formdata.set("author_email", update_author_email);
                }

                if (update_author_psw != "" && update_author_psw != null ){
                    formdata.set("author_password", update_author_psw);
                }

                if (update_author_avatar_url != "" && update_author_avatar_url != null ){
                    formdata.set("author_avatar_url", update_author_avatar_url);
                }

                if (update_author_description != "" && update_author_description != null ){
                    formdata.set("author_description", update_author_description);
                }

                //console.log("formdata >> ",formdata);
                await HttpPut(apiConfig.URL_BASE_API() + apiConfig.EndPoint_UpdateAuthor, formdata)
                    .then((response) => {
                        if (response.status == 200){
                        if (response.data["result"] == "success") {
                            console.log("UpdateAuthor >> success");

                            this.$refs.AuthorDataCPN.saveToLocal(this.AuthorData);

                            this.IsEditMode = false;
                            this.pageloading= false,
                            this.showDlgPopUp();
                        }
                        else {
                            this.IsEditMode = false;
                            this.pageloading= false,
                            this.$refs.DialogService.showPopUp("อัพเดตข้อมูลผู้แชร์ข้อมูล [ไม่สำเร็จ] !!",2000)
                        }}
                    });
            },
            showDlgDeleteAccount(){
                this.$refs.DialogService.showDlgConfirm("ลบแอคเคาน์","ยืนยันการลบแอคเคาน์ ?","cbDeleteAccount");
            },
            async showDlgPopUp(){
                this.$refs.DialogService.showPopUp("อัพเดตข้อมูลผู้แชร์ข้อมูล [สำเร็จ] <br />ลงชื่อออกอัตโนมัติ",1500,'update');
            },
            getPopUpClosed(result){
                console.log("getPopUpClosed",result);
                if (result == "update"){
                    this.$router.push({ path: "/#"+ libCommon.RandomStr(10) });
                }
            },
            getDeleteAuthorAccountOK() {

                console.log('ConfirmDeleteAcc >> True');
                HttpDelete(apiConfig.URL_BASE_API()+apiConfig.EndPoint_Deleteauthor+this.AuthorData.author_id)
                    .then((response)=>{

                        console.log('Delete author >> ',response);
                        if (response.status == 200){
                            if (response.data['result'] == 'success'){

                                localStorage.clear();
                                this.$router.push({ path: "/#"+ libCommon.RandomStr(10) });
                            }
                        }
                    })
            },
            getAuthorDataLoaded(data){
                console.log('author-info-loaded',data);
                this.AuthorData = data;
                this.base_author_name  = data.author_name;
                this.base_author_email = data.author_email;
                this.base_author_avatar_url = data.avatar_url;
                this.base_author_psw   = data.author_psw;
                this.base_author_description = data.author_description;

            },

        },
        computed: {
            calc_avatar_url(){
                //console.log('AuthorData.author_avatar_url',apiConfig.URL_IMG_AVATAR() + "/" +this.AuthorData.author_avatar_url);
                return apiConfig.URL_IMG_AUTHOR() + "/" + this.AuthorData.author_avatar_url;
            }
        },
    }
</script>

<style lang="css" scoped>

.box-small{
    max-width: 70%;
    width: 200px;
    float: right;
    margin: 5px;
}

.signup-form{
    margin-top: 50px;
}

.signup-container{
    display: block;
    margin:auto;
    padding: 20px;
    max-width: 50%;
    align-items: center;
    align-content: center;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px grey;
    background-color: #ddd;
}


* {box-sizing: border-box}

.box-avatar-img{
  max-width: 300px;
  position: relative;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 50px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid rgb(69, 69, 70);
  background-color: #a7d0fa;
}

.box40per {
width: 45%;
float: left;
margin-right: 10px;
margin-top: 22px;
}

.box50per {
width: 50%;
float: left;
}

.box1 {
width: 50%;
float: left;
}

.box2 {
width: 48%;
float: left;
margin-left: 5px;
}

.box3 {
width: 100%;
float: left;
}

.box100{
    width: 100%;
    float: left;
    height: 10px;
}


.cookies-text{
  margin: auto;
  text-align: center;
}

input {
width: 50%;
}

/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #fff;
}

input[type=text]:focus, input[type=password]:focus {
  background-color: #e4f4fc;
  outline: none;
}

input:read-only{
    background-color:#f0eeee;
}

hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}


input[type=checkbox]{
    width: 20px;
}

/* Set a style for all buttons */
button {
  background-color: #04AA6D;
  font-size: 16px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

button:hover {
  opacity:1;
}

/* Extra styles for the cancel button */
.btncancel ,.btndelete{
  padding: 14px 20px;
  background-color: #f44336;
}

/* Float cancel and signup buttons and add an equal width */
.btncancel, .btnsubmit {
  float: left;
  width: 50%;
}

.btnsubmit:disabled{
    background-color: grey;
    cursor: not-allowed;
}

/* Add padding to container elements */
.container {
  padding: 16px;
}

/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/* Change styles for cancel button and signup button on extra small screens */
@media screen and (max-width: 300px) {
  .btncancel, .btnsubmit {
     width: 100%;
  }
}

.img_avatar{
    width: 100px;
    border-radius: 50%;
}

.div-reserved-layout{
    height: 30px;
}

pre.policy-body{
    font-size: 16px;
}

.checkbox-container{
    text-align: left;
}

</style>