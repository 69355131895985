<template>
    <div>
        <ag-grid-vue
            class="ag-theme-alpine"
            animateRows="true"
            :style="style"
            :columnDefs="columns"
            :rowData="datas"
            @cell-clicked="getCellClicked"
        >
        </ag-grid-vue>

    </div>
</template>

<script>
    import { AgGridVue } from "ag-grid-vue3";
    import "ag-grid-community/styles/ag-grid.css";
    import "ag-grid-community/styles/ag-theme-alpine.css";
    export default {
        components: { AgGridVue,  },
        props:{
            columns: {type:Array,},
            datas: {type:Array,},
            style:{type:String,default: "width: 700px; min-height: 400px"},
        },

        methods: {
            getCellClicked(event){
                //console.log('cell-click-object',event);
                //console.log('cell-click-data',event.data);
                this.$emit('cellclick',event.data);
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>


<!--
    https://www.npmjs.com/package/ag-grid-vue3

    https://www.ag-grid.com/vue-data-grid/getting-started/?utm_source=ag-grid-readme&utm_medium=repository&utm_campaign=github
-->