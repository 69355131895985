<template>

        <ShareNetwork
            network="facebook"
            :url= "funcGetPathToShare(`${blog_id}`)"
            :title="blog_title"
            :description="blog_subtitle"
            :hashtags="blog_tag"
            :popup="{width: 450, height: 400}"
            >
            <img :src="imgfacebook" class="img-facebook" />
        </ShareNetwork>

        <ShareNetwork
            network="twitter"
            :url= "funcGetPathToShare(`${blog_id}`)"
            :title="blog_title"
            :description="blog_subtitle"
            :hashtags="blog_tag"
            :popup="{width: 450, height: 400}"
            >
            <img :src="imgtwitter" class="img-twitter" />
        </ShareNetwork>


        <ShareNetwork
            network="line"
            :url= "funcGetPathToShare(`${blog_id}`)"
            :title="blog_title"
            :description="blog_subtitle"
            :hashtags="blog_tag"
            :popup="{width: 450, height: 400}"
            >
            <img :src="imgline" class="img-line" />
        </ShareNetwork>


        <ShareNetwork
            network="email"
            :url= "funcGetPathToShare(`${blog_id}`)"
            :title="blog_title"
            :description="blog_subtitle"
            :hashtags="blog_tag"
            :popup="{width: 450, height: 400}"
            >
            <img :src="imgemail" class="img-email" />
        </ShareNetwork>

</template>

<script>
    import apiConfig from './api-config';

    export default {

        props:{
            blog_id: {type: Number },
            blog_title: {type: String },
            blog_subtitle: {type: String },
            blog_tag: {type: String },
        },

        computed:{
            imgfacebook(){
                //console.log(apiConfig.URL_IMG_GROUP()+'/share/facebook.png');
                return apiConfig.URL_IMG_GROUP()+'/share/facebook.png';
            },
            imgtwitter(){
                //console.log(apiConfig.URL_IMG_GROUP()+'/share/twitter.png');
                return apiConfig.URL_IMG_GROUP()+'/share/twitter.png';
            },
            imgline(){
                //console.log(apiConfig.URL_IMG_GROUP()+'/share/line.png');
                return apiConfig.URL_IMG_GROUP()+'/share/line.png';
            },
            imgemail(){
                //console.log(apiConfig.URL_IMG_GROUP()+'/share/email.png');
                return apiConfig.URL_IMG_GROUP()+'/share/email.png';
            },

        },
        methods:{
            funcGetPathToShare(blogID){
                return apiConfig.URL_SHARE_BLOG(blogID);
            },

        },
    }
</script>

<style scoped>

.img-facebook{
    min-width: 100px;
    width: 100px;
    min-height: 30px;
    margin-top: 0px;
    margin-right: 10px;
}

.img-twitter{
    min-width: 60px;
    width: 100px;
    min-height: 30px;
    height: 30px;
}

.img-line{
    min-width: 60px;
    width: 80px;
    min-height: 30px;
    height: 30px;
    margin-right: 10px;
}

.img-email{
    min-width: 60px;
    width: 80px;
    min-height: 30px;
    height: 30px;
}

</style>