<template>
    <div>
        <slot></slot>
    </div>
</template>

<script >
import { RandomStr } from './lib-common';
import { decrypt, encrypt } from './lib-encrypt';

export default {
        // inheritAttrs: false,
        props:{
            reload:{type:Boolean}
        },
        data() {
            return {
                LocalData: {
                    author_id : 0,
                    author_name : "",
                    author_email : "",
                    author_description: "",
                    author_avatar_url : "",
                    author_signedUp: false,
                    author_signedIn: false,
                    keyedit: "",
                    keydelete: "",
                }
            }
        },
        mounted() {
            if (this.keyedit == ""){
                this.keyedit    = RandomStr(8); }

            if (this.keydelete == ""){
                this.keydelete  = RandomStr(8); }

            this.loadFromLocal();
        },

        watch: {
            reload(){
                this.loadFromLocal();
            },
            LocalData(newValue){
                //console.log('watch-LocalData',newValue);
                this.$emit('cbDataChanged',newValue);
            },

        },
        methods: {
            loadFromLocal(){
                // Decrypt
                // Author-Data
                let _decText  = decrypt(localStorage.adata);
                if ((_decText != undefined) && (_decText != "")){
                    this.LocalData = JSON.parse(_decText);
                    console.log('A-LocalData Loaded',this.LocalData);
                    this.$emit('cbDataLoaded',this.LocalData);
                } else {
                    console.log('A-LocalData = ไม่มีข้อมูล',);
                }
            },
            saveToLocal(JsonObj){
                // Encrypt
                //console.log('A-saveToLocal',);
                const _encText =  encrypt(JSON.stringify(JsonObj));
                localStorage.setItem('adata',_encText);
            },
            getValue(key){
                return this.LocalData[key];
            },
            setValue(key,value){
                this.LocalData[key] = value;
                this.saveToLocal(this.LocalData);
            },
            newkeyEdit(){
                this.keyedit  = RandomStr(8);
                console.log('Call newkeyEdit',this.keyedit);
                this.setValue('keyedit',this.keyedit);
                this.$emit('cbKeyEdit',this.keyedit);
                return this.keyedit;
            },
            newkeyDelete(){
                this.keydelete = RandomStr(8);
                console.log('Call newkeyDelete',this.keydelete);
                this.setValue('keydelete',this.keydelete);
                this.$emit('cbKeyDelete',this.keydelete);
                return this.keydelete;
            },

        },

    }
</script>