<template>
        <div v-show="loading">
            <div class="ball-wrapper">
                <div class="ball-circle"></div>
                <div class="ball-circle"></div>
                <div class="ball-circle"></div>
                <div class="ball-shadow"></div>
                <div class="ball-shadow"></div>
                <div class="ball-shadow"></div>
                <p class="text-line-space"> &nbsp;</p>
                <p class="text-loader">{{ loadingtext }}</p>
                <p class="slot-box"><slot></slot></p>
            </div>

        </div>

</template>

<script>

    export default {

        props : {
            loadingtext:{ type: String, default: "โปรดรอ กำลังโหลด ..."},
            loading: { type: Boolean ,default: true },
        },
    }
</script>

<style lang="css" scoped>

.text-loader{
    border-radius: 0px 0px 10px 10px;
    background: rgb(24, 87, 170);
    text-align: center;
    color: #fff;
    margin: 0px;
}

.slot-box{
    background: rgba(250, 36, 21, 1);
    text-align: center;
    color: #fff;
    margin-top: 2px;
}

.text-line-space{
    margin-top: 30px;
}
.ball-wrapper{
    width:250px;
    height:70px;
    position: absolute;
    background-color: rgba(255,255,255,.8);
    border-radius: 10px 10px 0px 0px;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}
.ball-circle{
    width:20px;
    height:20px;
    position: absolute;
    border-radius: 50%;
    /* สีของบอล */
    background-color: rgb(24, 87, 170);
    -webkit-animation: random 2s infinite;
    animation: random 2s infinite;
    left:15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
}
.ball-circle:nth-child(2){
    left:45%;
    animation-delay: .2s;
}
.ball-circle:nth-child(3){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.ball-shadow{
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(58, 58, 54, 0.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes ball-shadow{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
}
.ball-shadow:nth-child(4){
    left: 45%;
    animation-delay: .2s
}
.ball-shadow:nth-child(5){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.ball-wrapper span{
    position: absolute;
    top:75px;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 12px;
    color: #fff;
    left:15%;
}
</style>