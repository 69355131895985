import { Date } from 'core-js';


export function RandomStr(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

// export function RandomStr(){
//     return Math.floor(Math.random() * 10000).toString();
// }


export function StrNotNull(value) {

    if (value == undefined){
        return "";
    } else {
        return value;
    }

}


export function isTrue(value) {
    //console.log('function isTrue >> ',value);
    if (value == null || value == undefined || value == '' || value == false){
        return false;
    } else {
        return true;
    }

}


export function cnvStrToThaiDateTime(value) {

    if (value == undefined){
        return "";
    } else {
        // YYYY-MM-DDTHH:mm:ss.sssZ
        let _DateValue = new Date(value);
        let year = _DateValue.getFullYear() + 543
        let month = ((_DateValue.getMonth() < 9) ? '0' : '') + (_DateValue.getMonth() + 1)
        let day = ((_DateValue.getDate() < 10) ? '0' : '') + _DateValue.getDate();
        let hr = ((_DateValue.getHours() < 9) ? '0' : '') + (_DateValue.getHours())
        let min = ((_DateValue.getMinutes() < 10) ? '0' : '') + _DateValue.getMinutes();

        return day + '/' + month + '/' + year +' '+hr+':'+min;
    }

}

export function cnvStrToThaiDate(value) {

    if (value == undefined){
        return "";
    } else {
        // YYYY-MM-DDTHH:mm:ss.sssZ
        let _DateValue = new Date(value);
        let year = _DateValue.getFullYear() + 543
        let month = ((_DateValue.getMonth() < 9) ? '0' : '') + (_DateValue.getMonth() + 1)
        let day = ((_DateValue.getDate() < 10) ? '0' : '') + _DateValue.getDate();

        return day + '/' + month + '/' + year;
    }

}


export function funccnvStrToThaiDateTimeAndShowAge(value) {

    if (value == undefined){
        return "";
    } else {
        // YYYY-MM-DDTHH:mm:ss.sssZ
        let _DateValue = new Date(value);
        let _Today = new Date();

        let year = _DateValue.getFullYear() + 543
        let month = ((_DateValue.getMonth() < 9) ? '0' : '') + (_DateValue.getMonth() + 1)
        let day = ((_DateValue.getDate() < 10) ? '0' : '') + _DateValue.getDate();
        let hr = ((_DateValue.getHours() < 9) ? '0' : '') + (_DateValue.getHours())
        let min = ((_DateValue.getMinutes() < 10) ? '0' : '') + _DateValue.getMinutes();

        let age =  _Today - _DateValue
        const days = age / (1000 * 60 * 60 * 24)
        let _days = parseInt(days)+' วัน';

        if (parseInt(days) == '0'){
            // ไม่ถึง 1 วัน
            _days = 'ไม่ถึง 24 ช.ม.'
        }

        return day + '/' + month + '/' + year +' '+hr+':'+min +' ('+_days+')'
    }

}


export function GetDateTimeNowStr(){
        let _Today  = new Date();
        let year    = _Today.getFullYear() + 543
        let month   = ((_Today.getMonth() < 9) ? '0' : '') + (_Today.getMonth() + 1)
        let day     = ((_Today.getDate() < 10) ? '0' : '') + _Today.getDate();
        let hr      = ((_Today.getHours() < 9) ? '0' : '') + (_Today.getHours())
        let min     = ((_Today.getMinutes() < 10) ? '0' : '') + _Today.getMinutes();
        let secz    = ((_Today.getSeconds() < 10) ? '0' : '') + _Today.getSeconds();

        return year + month + day + hr + min + secz;
}


export function GetDateNowStr(){
    let _Today  = new Date();
    let year    = _Today.getFullYear()
    let month   = ((_Today.getMonth() < 9) ? '0' : '') + (_Today.getMonth() + 1)
    let day     = ((_Today.getDate() < 10) ? '0' : '') + _Today.getDate();

    return year +'-'+ month +'-'+ day;
}


export function GetTimeNowStr(){
    let _Today  = new Date();
    let hr      = ((_Today.getHours() < 9) ? '0' : '') + (_Today.getHours())
    let min     = ((_Today.getMinutes() < 10) ? '0' : '') + _Today.getMinutes();
    let secz    = ((_Today.getSeconds() < 10) ? '0' : '') + _Today.getSeconds();

    return hr +':'+ min +':'+ secz;
}


export function GetDateTimeNowStrShort(){
    let _Today = new Date();
    let year = _Today.getFullYear() + 543
    let month = ((_Today.getMonth() < 9) ? '0' : '') + (_Today.getMonth() + 1)
    let day = ((_Today.getDate() < 10) ? '0' : '') + _Today.getDate();
    let hr = ((_Today.getHours() < 9) ? '0' : '') + (_Today.getHours())
    let min = ((_Today.getMinutes() < 10) ? '0' : '') + _Today.getMinutes();
    let secz = ((_Today.getSeconds() < 10) ? '0' : '') + _Today.getSeconds();

    return parseInt(year).substring(3, 2) + month + day + hr + min + secz;
}



/* ------------------------------------------------- */
export default { RandomStr, StrNotNull, isTrue, cnvStrToThaiDateTime ,
    GetDateTimeNowStr,funccnvStrToThaiDateTimeAndShowAge ,
}