<template>

    <CpnOverlayBox
            />
    <CpnNavSubgroup
        :group_code="group_code"
        :group_owner="group_owner"
        />

    <CpnBannerBetaTest />

    <section id="home">
        <div class="myrow">
            <div class="mycol">
                <header class="top-intro">
                    <h1 class="lead">
                        * {{ group_name }} *
                    </h1>
                    <p>{{ group_title }} </p>
                </header>
            </div>
        </div>

        <section class="hero0">
            <img :src= "getURLImageAtServer(calc_group_url)" class="group-background" alt="">
        </section>
    </section>

    <div class="myrow-uneven">

        <div class="mycol-left">

                <!-- * MAIN - BLOG * -->
                <div class="container-blog">

                        <!-- * if status == loading > Show loading * -->
                        <div v-if="processIsEnd == false || pageloading == true" >
                        <CpnLoader3Ball
                            :loading="pageloading"
                            :loadingtext="loadingtext"
                        />
                        </div>
                        <div v-else-if="processIsEnd && GroupBlogsInfo?.length == 0" >
                            <PageNoContent />
                        </div>

                        <!-- * Loop ข้อมูลจากเซิฟ * -->
                        <div
                            class="blogs"
                            v-for="blog_1row in blog_datas"
                            :key="blog_1row['blog_id']"
                        >

                        <CpnBlockBlog
                                :blog_title="blog_1row['blog_title']"
                                :blog_subtitle="blog_1row['blog_subtitle']"
                                :blog_content="blog_1row['blog_content'].substr(0, 199)"
                                :blog_image_url="blog_1row['blog_image_url']"
                                :blog_id="blog_1row['blog_id']"
                                :blog_group_code="blog_1row['blog_group_code']"
                                :blog_date="blog_1row['blog_date']"
                                :blog_count_views="blog_1row['blog_count_views']"
                                :author_name="blog_1row['author_name']"
                                :author_avatar_url="blog_1row['author_avatar_url']"
                            />

                        </div>
                </div>
            </div>



      <!-- SIDEBAR -->
      <div class="mycol-right">

            <section class="popular-posts">
                <header class="sidebar-header">
                        <h3 class="header-white">แนะนำ</h3>
                </header>
                <!-- * Top10 -- Loop ข้อมูลจากเซิฟ * -->
                <div class="blogs-top10"
                    v-for="blog_top10 in blogTop10_datas" :key="blog_top10['blog_id']">
                      <CpnBlockBlogRecommend
                          :blog_id = "blog_top10['blog_id']"
                          :blog_title = "blog_top10['blog_title']"
                          :blog_image_url = "blog_top10['blog_image_url']"
                          :blog_group_code = "blog_top10['blog_group_code']"
                          :blog_count_views = "blog_top10['blog_count_views']"
                      />
                </div>
            </section>


            <section class="recommend2">
                <header class="sidebar-header">
                    <h3 class="header-white">บทความเป็นชุด</h3>
                </header>
            </section>

        </div>
    </div>

    </template>


    <script>

    import CpnBlockBlog from '@/components/cpn-blockblog-main.vue';
    import { HttpGet,ValidateToken, } from "@/components/lib-http-helper";
    import apiConfig from "@/components/api-config";
    import CpnLoader3Ball from '@/components/cpn-loader-3ball.vue';
    import CpnBlockBlogRecommend from '@/components/cpn-block-show-recommends.vue';
    import CpnOverlayBox from '@/components/cpn-menu-overlay-box.vue';
    import { ref, } from 'vue'
    import PageNoContent from './page-no-content.vue';
    import CpnNavSubgroup from '@/components/cpn-nav-subgroup.vue';
    import CpnBannerBetaTest from '@/components/cpn-banner-betatest.vue';


    export default {
        components: { CpnBlockBlog, CpnLoader3Ball, CpnBlockBlogRecommend, CpnOverlayBox, PageNoContent, CpnNavSubgroup, CpnBannerBetaTest },
        processIsEnd: ref(false),

        data() {
            return {
                blog_datas: [],
                blogTop10_datas: [],
                GroupBlogsInfo: [],
                errors: [],
                loadingtext: "โปรดรอ กำลังโหลดข้อมูล ...",
                pageloading: false,
                group_name: "",
                group_title: "",
            };
        },
        props: {
            group_code: {type: String} ,
            group_owner: {type: String},
        },
        created() {
            this.processIsEnd = false;
            this.getValues();
        },
        mounted() {
            this.processIsEnd = false;
        },
        computed:{
            calc_group_url(){
                if (this.GroupBlogsInfo?.length > 0){
                    return this.GroupBlogsInfo[0]['group_background_url'];
                }
                return apiConfig.API_IMG_EMPTY;
            }
            ,

        },
        methods: {
            async getValues() {
                this.pageloading = true;
                this.processIsEnd = false;

                await ValidateToken('page-subgroup').then((success) => {
                    if (success){
                        this.GroupBlogsInfo = [];

                        this.getGroupBlogInfo();

                        this.getAllBlogs();

                        this.getBlogsTop10AllGroup();
                    }
                });
            },

            async getGroupBlogInfo(){
                this.GroupBlogsInfo = [];
                //console.log('getGroupBlogInfo > ',this.group_owner+'/'+this.group_code);
                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_GroupInfo+'/'+ this.group_owner+'/'+this.group_code,
                        "getGroupBlogInfo "+this.group_owner+'/'+this.group_code).then(
                            (response) => {
                                //console.log('getGroupBlogInfo > ',response.data);
                                this.GroupBlogsInfo = response.data;
                                this.group_name = response.data[0]['group_name'];
                                this.group_title = response.data[0]['group_title'];
                                console.log('group_name > ',this.group_name);
                            }
                        );

            },

            async getAllBlogs() {
                console.log('sub-getAllBlogs > ',this.group_owner+'/'+this.group_code);
                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_AllBlogsByGroup+'/'+ this.group_owner+'/'+this.group_code,
                        "by sub-group all blog "+this.group_code+'/'+this.group_owner).then(
                            (response) => {
                                this.blog_datas = response.data;
                                this.GroupBlogsInfo = response.data;
                                console.log('sub-getAllBlogs > ',response.data);
                            }
                        );
            },

            async getBlogsTop10AllGroup() {
                this.blogTop10_datas = [];
                console.log('Top10-subgroup');
                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_blog_top10+'/'+this.group_owner+'/'+this.group_code,
                        'Top10-subgroup')
                    .then((response) => {
                        this.blogTop10_datas = response.data;
                        this.pageloading = false;
                        this.processIsEnd = true;
                    }
                    );
            },

            getURLImageAtServer(url) {
                if (url){
                    //console.log('load image >> ',url)
                    return apiConfig.URL_IMG_GROUP() + "/" + url;
                }
                console.log('Not load image !! ',url)
            },
        },
    };

</script>

<style>
      @import "@/css/main.css";
      @import "@/css/custom-markdown.css";

      h1,h2,h3,h4,h5,h6{
        color: black;
      }
      .header-white{
        color: white;
      }

      div.blogs{
        background-color: white;
        margin-bottom: 20px;
      }

      .post-btm-bar{
        padding: 10px;
      }

      /* .blog-content-subtitle{
        background-color: #FAFAFA;
      }

      .blog-content-markdown{
        background-color: #FAFAFA;
      } */

      .blog-group{
        color: black;
      }

      .blog-title{
        color: black;
      }

      .popular-posts{
          margin: 0px;
          display: block;
      }
    </style>
