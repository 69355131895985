<template>
  <div class="wrapper">
  <div class="container">
    <img src="@/assets/markdown.png" class="markdown-logo"/>
    <div class="text-block">
      <span><h2>MarkDown Editor :: สำหรับสร้างไฟล์ `มาร์กดาวน์` </h2></span>
      <p>สามารถบันทึกเป็นไฟล์ ด้วยการกดปุ่ม Save หรือกดแป้น Ctrl+S จะบันทีกแล้วดาวน์โหลดไฟล์อัตโนมัติ</p>
      <p class="text-red">** ไม่รองรับการ Upload File หรือ คัดลอกภาพจากหน่วยความจำ !!</p>
    </div>
    <md-editor v-model="code" language="en-US"
          previewTheme="github"
          :showCodeRowNumber="false"
          @save="onSave"
          placeholder="กรอกข้อความที่ต้องการตรงนี้ ขวามือแสดงตัวอย่าง"
    />
  </div>
</div>
</template>

<script>
    import { ref } from 'vue';
    import moment from 'moment';
    import MdEditor from 'md-editor-v3';
    import 'md-editor-v3/lib/style.css';

    export default {
        setup() {
            const newtext = ref("");
            const code = ref("")
            return {newtext ,code}
        },
        components:{
            MdEditor,
        },
        methods: {

            onSave(value) {
                console.log('onSave',value);
                if (value.length > 0){
                    this.newtext = value;
                    // Download file
                    let blob = new Blob([ value ], { "type" : "text/plain;charset=utf-8;" });
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    let date = new Date();
                    date = moment(date).format('YYYYMMDDHHmmssss')
                    // console.clear;
                    // console.log('date >> ',date);
                    link.download = 'newtext-'+date+'.md';
                    link.click()
                }
                else
                { alert('กรอบข้อความด้านซ้ายมือ ยังไม่มีข้อความ !!') }
            },
            //   async onUploadImgFile (files, cb) {
            //       const res = await Promise.all(
            //       files.map((file) => {
            //       return new Promise((rev, rej) => {
            //             const form = new FormData();
            //             form.append('file', file);
            //             axios.post('/upload/images/', form, {
            //                 headers: {
            //                     'Content-Type': 'multipart/form-data'
            //                   }
            //             })
            //             .then((res) => rev(res))
            //             .catch((error) => rej(error));
            //         });
            //     })
            //   );


            // cb(res.map((item) => item.data.url));

            // }
        },
    }
</script>

<style scoped>
.container{
  margin: auto;
  width: auto;
  padding: 10px;
  display: block;
  min-height: 300px;
}

.markdown-logo{
  float: left;
  width: 100px;
  margin: 10px;
  filter: drop-shadow(1px 2px 2px #04f8f8);
  -webkit-filter: drop-shadow(1px 2px 2px #04f8f8);

}

.text-block{
  display: block;
  line-height:80%;
}

.text-red{
  color: red;
}
</style>

```
# Github
https://github.com/imzbf/md-editor-v3


```