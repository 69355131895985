<template>
    <div class="container-captcha">
        <div class="container-button">
            <div class="captcha-text-header">ระบบสุ่มรหัสยืนยัน CAPTCHA</div>
            <div class="container-img">
            <img :src="imgCaptCHA" class="captcha-image" alt="< image captcha >"/>
            </div>

            <button type="button" @click="getImageCaptCHA" class="captcha-btn-reload">โหลดภาพใหม่</button>
        </div>

        <div class="container-input">
                <p class="input-text-header">
                <span>โปรดกรอกรหัสยืนยันตามภาพ : </span>
                <span><input type="text" class="input-text" required v-model="inputText" @change="cbInputTextChanged" /></span>
                </p>
        </div>

    </div>
    <p></p>
</template>

<script>

    import apiConfig from './api-config';
    import { HttpGet, ValidateToken } from './lib-http-helper'
    export default {
        emits: ['cbNewImageCaptCHA','cbInputTextChanged'],
        data() {
            return {
                imgURL: "",
                inputText: "",
            }
        },
        mounted(){
            this.getImageCaptCHA()
        },
        methods: {

            async getImageCaptCHA(){
                await ValidateToken('img-capcha').then((success) => {
                    if (success){
                        this._getImageCaptCHA();
                    }
                });
            },

            async _getImageCaptCHA(){
                const url = apiConfig.URL_BASE_API()+apiConfig.EndPoint_GetImgCaptCha
                await HttpGet(url).then((response) => {
                    const _key = response.data['key']
                    const _code = response.data['code']
                    const _filename = response.data['filename']
                    const url = apiConfig.URL_IMG_CAPTCHA()+'/'+_filename+'#'+_key

                    this.imgURL = url;
                    this.$emit('cbNewImageCaptCHA',_code);
                })
            },
            cbInputTextChanged(){
                //console.log('cbInputTextChanged',this.inputText);
                this.$emit('cbInputTextChanged',this.inputText);
            }
        },
        computed:{
            imgCaptCHA(){
                return this.imgURL;
            }
        },

    }
</script>

<style lang="css" scoped>

.container-captcha {
    position: relative;
    color:#000;
    border: 2px solid  rgb(130, 159, 177); /* rgb(179, 178, 178) ; */
    background-color: rgb(227, 246, 252);
    border-radius: 10px;
    padding: 10px;
    padding-bottom: 05px;
}

.container-img{
    width: 100%;
    /* min-width: 280px;
    min-height: 90px; */
    margin-top: 10px;
    text-align: center;
}


.input-text-header{
    /* position: relative; */
    bottom: -20px;
    margin-bottom: 20px;
}

.input-text{
    font-size: 16px;
}

.container-button{
    background-color:rgb(193, 239, 250);
    align-content: space-around;
    text-align: center;
}

.container-input{
    font-size: 16px;
    width: 100%;
    text-align: center;
}


.captcha-image{
    border-radius: 4px;
}

.captcha-text-header {
  top: 62%;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  background-color: rgb(48, 106, 139);
  border: 1px solid  rgba(104, 103, 103,0.2);

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.captcha-btn-reload {
    /* top: 58%; */
    width: 40%;
    font-size: 16px;
    background-color: #ff81e0;
    margin: 8px 0;
    border: 1px solid  #aa0481;
    cursor: pointer;
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
}

</style>