import { RandomStr } from "./lib-common";

export default {
    // API เซิฟจริง
    //API_SERVER:'https://www.xn--c3cul4aek7b3a4byjc6f6c.com:8080',

    // API NAS
    //API_SERVER:'http://192.168.1.101:8080',
    API_SERVER:'https://warapetch.synology.me:8080',
    //API_SERVER: 'https://api1.warapetch.synology.me',
    //API_SERVER: 'http://api.dbcenter.trueddns.com',

    // ** Localhost ใช้กับ NAS ไม่ได้ !!


    // API เซิฟทดสอบ Localhost
    //API_SERVER:'http://localhost:8080',


    API_IMG_EMPTY: "empty.png",
    API_APP_ID: 'blog',
    WEBSITE_NAME: "www.สยามเซ็นเตอร์.com",
    WEBSITE_CNAME: "https://www.xn--c3cul4aek7b3a4byjc6f6c.com",
    GREETING_NEW_USER: "ขอแสดงความยินดี ที่ท่านสมัครสมาชิกกับทางชุมชนของเรา",


    /* Client Link */
    URL_ForShare_BlogItem : "/blog-item",

    /*
        Method :: POST
        Endpoint :: /token-request
        FormDat :: {
                'client_id', 'myweb'
                'app_id', 'blog'        }
        Headers :: Authorization : Bear {Token}
        No-Parameter
    */
    EndPoint_GetToken: '/token-request',


    /*
        Method :: GET
        Endpoint :: /imgcaptcha
    */
    EndPoint_GetImgCaptCha: '/imgcaptcha',

    /*
        Method :: GET
        Endpoint :: /blogs-mgroup/{group_owner}
    */
    //EndPoint_AllBlogsInMainGroup: '/blogs-mgroup',


    /*
        Method :: GET
        Endpoint :: /blogs-bygroup/{group_owner}/{group_code}
        group :: '*' == ALL , Others by Group-code
    */
    EndPoint_AllBlogsByGroup: '/blogs-bygroup',


    /*
        Method :: GET
        Endpoint :: /blogs-home/{group_owner}/{group_code}
    */
    EndPoint_BlogsHome: '/blogs-home',

    /*
        Method :: GET
        Endpoint :: /blogs-home
        No-Parameter
    */
    //EndPoint_LastBlogHome: '/blogs-home',



    /*
        Method :: GET
        Endpoint :: /blogs/{blog_id}
        blog_id ::
    */
    EndPoint_blog_item_detail: '/blogs',


    /*
        Method :: GET
        Endpoint :: /blogs-top10
    */
    EndPoint_blog_top10: '/blogs-top10',


    /*
        Method :: GET
        Endpoint :: /maingroup
        No-Parameter
    */
    EndPoint_MainGroup: '/maingroup',


    /*
        Method :: GET
        Endpoint :: /subgroup/{group_owner}/{group_code}
        No-Parameter
    */
    EndPoint_SubGroup: '/subgroup',


    /*
        Method :: GET
        Endpoint :: /groupinfo/group_owner/group_code
        No-Parameter
    */
    EndPoint_GroupInfo: '/groupinfo',


    /*
        Method :: GET
        Endpoint :: /groups/group_owner/group_code
        No-Parameter
    */
    EndPoint_GroupBySelect: '/groups',


    /*
        Method :: GET
        Endpoint :: /blogs-author/{author_id}
        No-Parameter
    */
    EndPoint_BlogsByAuthor: '/blogs-author',


    /*
        Method :: GET
        Endpoint :: /comments-main/{blog_id}
        blog_id ::
    */
    EndPoint_GetCommentMain: '/comments-main',


    /*
        Method :: GET
        Endpoint :: /comments-sub/{blog_id}/{comment_id}
        blog_id ::
    */
    EndPoint_getMainCommentsub: '/comments-sub',

    /*
        Method :: POST
        Endpoint :: /comment
        blog_id ::
    */
    EndPoint_PostComment: '/comment',


/*
        Method :: POST
        Endpoint :: /blog
        blog_id ::
    */
    EndPoint_PostNewBlog: '/blog',


/*
        Method :: PUT
        Endpoint :: /blog
        blog_id ::
    */
    EndPoint_PostEditBlog: '/blog',


    /*
        Method :: POST
        Endpoint :: /activity
        form ::
            act_type   VARCHAR (10)     BLOG/COMMENT
            blog_id    BIGINT
            comment_id BIGINT
            act_group  VARCHAR (10)     VIEW/STAR/SHARE
            user_id    BIGINT
    */
    EndPoint_PostActivity: '/activity',

    /*
        Method :: POST
        Endpoint :: /available-uname
        blog_id ::
    */
    EndPoint_CheckUserNameAvailable: '/available-uname',



    /*
        Method :: POST
        Endpoint :: /available-aname
        blog_id ::
    */
    EndPoint_CheckAuthorNameAvailable: '/available-aname',


    /*
        Method :: POST
        Endpoint :: /author/{formdata}
        blog_id ::
    */
    EndPoint_AddAuthor: '/author',

    /*
        Method :: PUT
        Endpoint :: /author/{formdata}
        blog_id ::
    */
    EndPoint_UpdateAuthor: '/author',


    /*
            Method :: DELETE
            Endpoint :: /author/{author_id}
            blog_id ::
    */
    EndPoint_DeleteAuthor: '/author',


    /*
            Method :: DELETE
            Endpoint :: /blog/{blog_id}
            blog_id ::
    */
    EndPoint_DeleteBlog: '/blog',



    /*
        Method :: POST
        Endpoint :: /username/{formdata}
        blog_id ::
    */
        EndPoint_AddUser: '/user',


    /*
        Method :: POST
        Endpoint :: /edit-user/{formdata}
        blog_id ::
    */
    EndPoint_UpdateUser: '/user',


    /*
        Method :: DELETE
        Endpoint :: /user/{user_id}
        blog_id ::
    */
    EndPoint_DeleteUser: '/user',


    /*
        Method :: POST
        Endpoint :: /username/{formdata}
        blog_id ::
    */
    EndPoint_LoginUser: '/login-user',


    /*
        Method :: POST
        Endpoint :: /userauthor/{formdata}
        blog_id ::
    */
    EndPoint_LoginAuthor: '/login-author',


    /*
        Method :: POST
        Endpoint :: /forgot-user
        blog_id ::
    */
        EndPoint_UserForgotPsw: '/forgot-user',


    /*
        Method :: POST
        Endpoint :: /forgot-user
        blog_id ::
    */
        EndPoint_AuthorForgotPsw: '/forgot-author',



    /* Function list */
    URL_BASE_API,
    API_CLIENT_ID,
    URL_IMG_FOLDER,    URL_IMG_AVATAR,
    URL_IMG_AUTHOR,    URL_IMG_GROUP ,
    URL_IMG_CAPTCHA ,
    URL_SHARE_BLOG,


TEXT_POLICY: `เว็บไซต์ สยามเซ็นเตอร์ ดอทคอม อาจใช้คุกกี้ (Cookies) และ `+
`เทคโนโลยีอื่นใด ที่มีลักษณะคล้ายคลึงกัน <br /> `+
`เพื่อเก็บรวบรวมข้อมูลของท่าน ในขณะที่ท่านได้เข้าเยี่ยมชมหรือใช้บริการเว็บไซต์</code> `+
`<br /> `+
`<br /> `+
`<code><h3>การใช้คุกกี้ (Cookies) เพื่อเก็บรวบรวมข้อมูล</h3></code> `+
``+
`<code><h3>คุกกี้คืออะไร?</h3></code> `+
`คุกกี้ (Cookies) คือ ไฟล์รูปแบบหนึ่งที่ประกอบไปด้วยข้อความตัวอักษร (Text)  <br /> `+
`คุกกี้ (Cookies) จะถูกสร้างขึ้นเมื่อผู้ใช้งานเข้าถึง เว็บไซต์ (Website)   `+
`ซึ่งเซิร์ฟเวอร์ (Server) มีการสร้างคุกกี้ไว้ โดยคุกกี้ (Cookies) `+
`จะถูกบันทึกลงบนบราวเซอร์ (Browser) ของท่านเมื่อท่านแวะเข้าชมเว็บไซต์นั้นๆ `+
`ซึ่งเนื้อหาในคุกกี้จะสามารถเรียกออกมาดูหรืออ่านได้โดยเซิร์ฟเวอร์ (Server)   `+
`ที่สร้างคุกกี้ดังกล่าวเท่านั้น และเนื้อหาดังกล่าวจะถูกส่งกลับไปยังเว็บไซต์ (Website)   `+
`ต้นทางในการเยี่ยมชมแต่ละครั้ง ข้อความที่อยู่ในคุกกี้ (Cookies)   `+
`มักประกอบด้วยข้อมูลระบุตัวตน ชื่อเว็บไซต์ <br />รวมถึงตัวเลขและตัวอักษรบางอย่าง  `+
`โดยคุกกี้จะมีการจัดเก็บรายละเอียดพฤติกรรมการเยี่ยมชมเว็บไซต์และ  `+
`สิ่งที่ถูกเลือกถึงบ่อยโดยท่านและบราวเซอร์ (Browser) ของท่าน `+
`<br />`+
`<code><h3>บราวเซอร์ (Browser)</h3></code> ส่วนใหญ่รองรับการใช้คุกกี้  `+
`อย่างไรก็ตามท่านสามารถตั้งค่า หรือปรับค่า บราวเซอร์ (Browser)   `+
`เพื่อไม่ให้มีการใช้หรือส่งข้อมูลสำหรับคุกกี้ (Cookies) บางประเภทได้  `+
`และ/หรืออาจลบข้อมูลที่มีการเก็บไว้ใน คุกกี้ (Cookies) เมื่อไรก็ได้ `+
`<br />`+
`<code><h3>ทำไมจึงต้องใช้คุกกี้?</h3></code> `+
`เราใช้คุกกี้ (Cookies) บน Website เพื่อช่วยในการเก็บรวบรวมข้อมูลต่างๆ  `+
`จากผู้ที่มาเยี่ยมชมและใช้บริการต่างๆ บนเว็บไซต์ โดยคุกกี้ (Cookies)   `+
`ซึ่งจะช่วยให้สามารถปรับปรุงพัฒนาประสบการณ์การใช้งานของท่านทาง เว็บไซต์ให้ดียิ่งขึ้น `+
`<br />`+
`<code><h3>ใช้คุกกี้ประเภทใดบ้าง</h3></code> `+
`ในระหว่างการใช้เยี่ยมชม หรือใช้บริการเว็บไซต์ ท่านอาจพบว่าเรามีการใช้งานคุกกี้ (Cookies) ต่อไปนี้ `+
`<br />`+
`<br />`+
`<code><h3>คุกกี้ประเภทจำเป็นถาวร</h3></code> `+
`คุกกี้ (Cookies) ประเภทจำเป็นถาวรของเว็บไซต์เป็นคุกกี้ (Cookies)  ที่จำเป็นต้องมีในเว็บไซต์ เพื่อให้เว็บไซต์ `+
`สามารถปฏิบัติการได้อย่างเหมาะสม และเป็นปกติ หากไม่มีคุกกี้ของเว็บไซต์หรือท่านเลือกที่จะกำหนดให้คุกกี้ (Cookies)   `+
`ประเภทนี้<br />หยุดทำงานบนบราวเซอร์ของท่าน อาจก่อให้เกิดผลกระทบทำให้ Website ไม่สามารถปฏิบัติการได้ตามปกติ   `+
`<br />ซึ่งคุกกี้ประเภทนี้ ประกอบด้วย `+
`<br />`+
`<code><h3 class="topic1">#คุกกี้เพื่อการจัดการเว็บไซต์</h3></code> `+
`คุกกี้ (Cookies)  ประเภทนี้ถูกนำมาใช้เพื่อให้เว็บไซต์สามารถที่จะรับรู้และระบุตัวตนและข้อมูลของท่านตลอดเวลาที่ท่านมีการใช้บริการบนเว็บไซต์ `+
`เพื่อป้องกันไม่ให้ท่านถูกตัดการเชื่อมต่อจากเว็บไซต์อย่างไม่ทันคาดคิด ท่านไม่สามารถจะกำหนดค่าให้เฉพาะคุกกี้ (Cookies)   `+
`ประเภทนี้เท่านั้นที่หยุดการดำเนินการได้ หากจะให้คุกกี้ (Cookies)  ประเภทนี้หยุดการปฏิบัติงานจำเป็นต้องกำหนดค่าให้คุกกี้ (Cookies)   `+
`ทุกประเภทหยุดการทำงานทั้งหมด `+
`<br />`+
`<code><h3 class="topic1">#คุกกี้เพื่อการวิเคราะห์และวัดผลการทำงาน</h3></code> `+
`คุกกี้ (Cookies)  เพื่อการวิเคราะห์จะคอยตรวจดูว่าผู้ใช้บริการ / ผู้เข้าชมเว็บไซต์ นั้น มีการเข้าสู่เว็บไซต์  `+
`ผ่านทางวิธีการใด และมีการตอบสนองอย่างไรต่อเว็บไซต์ และมีการเข้าชมเนื้อหาส่วนใดในเว็บไซต์บ้าง  `+
`คุกกี้ (Cookies) ประเภทนี้<br />จะช่วยเก็บข้อมูลทำให้เว็บไซต์ได้รับทราบว่า ลักษณะการปฏิบัติงาน / `+
`ฟังก์ชัน-ความสามารถ (features) ใดในเว็บไซต์  `+
`ที่ปฏิบัติงานได้ดีที่สุด และลักษณะการปฏิบัติงาน / ฟังก์ชัน-ความสามารถ (features) ใดที่ควรต้องปรับปรุง  `+
`เพื่อให้เว็บไซต์ ทำงานได้ดียิ่งขึ้น คุกกี้ประเภทนี้ช่วยให้เว็บไซต์สามารถวัดผลการทำงาน เช่น การประมวลจำนวนหน้าที่ท่านเข้าใช้งานจำนวนลักษณะ `+
`เฉพาะของกลุ่มผู้ใช้งานนั้นๆ โดยข้อมูลดังกล่าวจะนำมาใช้ในการวิเคราะห์รูปแบบพฤติกรรมของผู้ใช้งาน `+
`ซึ่งคุกกี้ประเภทนี้จะอนุญาตให้บุคคลภายนอกอื่นที่มีการให้บริการบนเว็บไซต์นั้นเก็บรวบรวมข้อมูลของท่านได้ด้วย `+
`<br />`+
`<code><h3 class="topic1">#คุกกี้เพื่อการโฆษณา</h3></code> `+
`คุกกี้ (Cookies)เพื่อการโฆษณาจะถูกบันทึกลงในบราวเซอร์ของท่านโดยเซิร์ฟเวอร์โฆษณา (ad servers)  `+
`เพื่อทำการนำเสนอและโฆษณาผลิตภัณฑ์ต่อท่าน โดยเซิร์ฟเวอร์โฆษณา (ad servers) จะใช้คุกกี้ (Cookies)  `+
`ประเภทนี้เพื่อรวบรวมข้อมูลของท่านเมื่อท่านเข้าเยี่ยมชมเว็บไซต์ หรือเว็บไซต์อื่นๆ และเซิร์ฟเวอร์โฆษณา (ad servers)  `+
`จะทำการเลือกโฆษณาและเสนอไปที่บราวเซอร์ของท่าน จากนั้นจะเก็บข้อมูลว่าโฆษณาดังกล่าวได้รับการเปิดชมหรือไม่ `+
`คุกกี้อาจใช้ข้อมูลที่มีการส่งต่อข่าวสารในสื่อออนไลน์และเนื้อหาที่จัดเก็บจากการให้บริการ เพื่อเข้าใจความต้องการของผู้ใช้งาน `+
`โดยมีวัตถุประสงค์ในการปรับแต่งเว็บไซต์ แคมเปญโฆษณาให้เหมาะสมกับความสนใจของท่าน `+
`<u>ซึ่งคุกกี้ประเภทนี้จะอนุญาตให้บุคคลภายนอกอื่น ที่มีการให้บริการบนเว็บไซต์นั้นเก็บรวบรวมข้อมูลของท่านได้ด้วย</u> `+
`<br />`+
`<code><h3 class="topic1">#คุกกี้ประเภทการทำงานจดจำการปรับตั้งค่าเฉพาะตัวของผู้ใช้บริการ</h3></code> `+
`คุกกี้ (Cookies) ประเภทนี้ถูกใช้เพื่อให้เว็บไซต์สามารถจดจำผู้ที่เคยเข้าเยี่ยมชม/ใช้บริการบนเว็บไซต์ และ  `+
`การกำหนดและตั้งค่าของผู้ที่เคยเข้าเยี่ยมชม/ใช้บริการบน เว็บไซต์ได้  `+
`คุกกี้ (Cookies) ประเภทนี้จะใช้เพื่อบันทึกประวัติในการเปิดดูเว็บไซต์ต่างๆ ของผู้ใช้บริการ  `+
`และจดจำการตั้งค่าและความชอบของผู้ใช้บริการแต่ละครั้งที่เข้าเยี่ยมชมเว็บไซต์  `+
`เพื่อที่เวลาผู้ที่เคยเข้าเยี่ยมชม/ใช้บริการบนเว็บไซต์ นั้นกลับเข้ามาเยี่ยมชมเว็บไซต์ อีกครั้งจะได้ไม่ต้องตั้งค่าต่างๆ ใหม่อีกครั้ง  `+
`ตัวอย่างเช่น การตั้งค่าภาษา หรือขนาดตัวอักษรที่ปรากฏหน้าเว็บไซต์ที่ท่านเคยเข้าเยี่ยมชมแล้ว เป็นต้น  `+
`<br />`+
`<br />`+
`<code><h3 class="topic2">การปรับปรุงนโยบายฉบับนี้</h3></code> `+
`เว็บไซต์ขอสงวนสิทธิในการปรับปรุงนโยบายฉบับนี้เมื่อมีความจำเป็นตามสมควร `+
`โดยเมื่อมีการปรับปรุงนโยบายฉบับนี้เว็บไซต์จะทำการแจ้งให้ท่านได้รับทราบการเปลี่ยนแปลงแก้ไขดังกล่าว `+
`(โดยจะระบุว่าเป็น นโยบาย “ฉบับปรับปรุงล่าสุด” ที่ใช้บังคับ) ทั้งนี้การแก้ไขหรือเปลี่ยนแปลงนโยบายฉบับนี้ `+
`จะมีผลทันที เมื่อเว็บไซต์ทำการลงประกาศนโยบายฉบับปรับปรุงล่าสุดในเว็บไซต์ `+
`<br />`+
`<br />`+
`เว็บไซต์สนับสนุน+แนะนำ ให้ท่านอ่านและทำความเข้าใจนโยบายฉบับนี้โดยละเอียด `+
`เพื่อให้ท่านได้รับทราบสิทธิและวิธีการดำเนินงานต่างๆ ของเว็บไซต์ `+
`โปรดทราบว่าเมื่อเว็บไซต์ได้แจ้งให้ท่านรับทราบการเปลี่ยนแปลงแก้ไขนโยบายของเว็บไซต์แล้ว `+
`และท่านได้กดตกลงรับทราบการปรับปรุงนโยบายฉบับล่าสุดที่มีการลงประกาศในเว็บไซต์ นี้แล้ว `+
`พร้อมกับการที่ท่านเข้าเยี่ยมชมหรือใช้บริการเว็บไซต์ต่อไป `+
`กรณีดังกล่าวแสดงว่าท่านได้สมัครใจยินยอม และตกลงให้ใช้นโยบายฉบับปรับปรุงล่าสุด`+
` ของเว็บไซต์`+
`<p><h3>ปรับปรุงล่าสุดเมื่อ 1 ตุลาคม 2565</h3></p>`

}


export function URL_BASE_API(){
    return this.API_SERVER+'/api/v1'
}


export function API_CLIENT_ID(){
    return 'client-'+RandomStr(10);
}


export function URL_SHARE_BLOG(blogID){
    // http://localhost:8080/blog-item/65082200003
    //console.log("blog-item/"+this.blog_id)
    return this.API_SERVER+this.URL_ForShare_BlogItem+"/"+blogID
}



export function URL_IMG_FOLDER(){
    return this.API_SERVER+'/images'
}


export function URL_IMG_AVATAR(){
    return this.URL_IMG_FOLDER()+'/avatars';
}


export function URL_IMG_AUTHOR(){
    //return this.URL_IMG_FOLDER()+'/authors';
    return this.URL_IMG_FOLDER()+'/avatars';
}


export function URL_IMG_GROUP(){
    return this.URL_IMG_FOLDER()+'/groups';
}

export function URL_IMG_CAPTCHA(){
    return this.URL_IMG_FOLDER()+'/captcha';
}