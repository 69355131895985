<template >
    <!-- /* พื้นหลัง */ -->
    <div class="dlg-mask" v-if="showDialog==true">

        <!-- Wrapper Auto-size -->
        <div class="dlg-wrapper">

            <!-- Container -->
            <div class="dlg-container" :style= "customContainerStyle">

                <!-- Header -->
                <div class="dlg-header" :style="customHeaderStyle" v-show="showHeader">
                    <slot name="header" >
                        <span v-html="dialogHeader"></span>
                    </slot>
                </div>

                <!-- Body -->
                <div class="dlg-content" :style="customContentStyle">
                    <slot name="body">
                        <span v-html="dialogContent"></span>
                    </slot>
                </div>

                <!-- Footer -->
                <div class="dlg-footer" :style="customFooterStyle" v-show="showFooter">
                    <slot name="footer">
                        <span v-html="dialogFooter"></span>
                    </slot>

                    <span class="div-btnCancel" v-show="showBtnCancel">
                    <button class="dlg-cancel-button" @click="btnCancelClick" :style="customCancelStyle">
                        {{ btnCancelCaption }}
                    </button>
                    </span>

                    <span class="div-btnOk" v-show="showBtnOk">
                    <button class="dlg-ok-button" @click="btnOkClick" :style="customOkStyle">
                        {{ btnOkCaption }}
                    </button>
                    </span>
                </div>

            </div>

        </div>

    </div>
</template>

<script>

    export default {
        emits:['cbCancelClick','cbOkClick',],
        props: {
            dialogID: {type:String ,default: "0000"},
            dialogHeader:{ type:String },
            dialogContent:{ type:String },
            dialogFooter:{ type:String },
            btnOkCaption:{ type:String ,default: "ตกลง"},
            btnCancelCaption:{ type:String ,default: "ยกเลิก"},
            showDialog:{ type:Boolean ,default: false},
            showBtnCancel:{ type:Boolean ,default: false},
            showBtnOk:{ type:Boolean ,default: true},
            showHeader:{ type:Boolean ,default: true},
            showFooter:{ type:Boolean ,default: true},

            styleContainer:{type: Object,},
            styleHeader:{type:Object,},
            styleContent:{type:Object,},
            styleFooter:{type:Object,},
            styleBtnOk:{type:Object,},
            styleBtnCancel:{type:Object,},

        },
        computed: {
            customContainerStyle () {
                return  this.styleContainer;
            },
            customHeaderStyle(){
                return this.styleHeader;
            },
            customContentStyle(){
                return this.styleContent;
            },
            customFooterStyle(){
                return this.styleFooter;
            },
            customCancelStyle(){
                return this.styleBtnCancel;
            },
            customOkStyle(){
                return this.styleBtnOk;
            },

        },
        methods: {
            btnCancelClick(){
                this.$emit('cbCancelClick')
            },
            btnOkClick(){
                this.$emit('cbOkClick');
            },

        },
    }
</script>

<style>
/* ใช้สำหรับ สร้าง Scroll ให้กับ HTML-Text */
.scroller {
    margin: auto;
    margin-left: 20px;
    text-align: left;
    padding: 4px;
    width: 100%;
    height: 310px;
    overflow-y: scroll;
    scrollbar-color:#0f50a5 #a2cafd;
    scrollbar-width: thin;
}

h3.topic1{
    color: rgb(5, 34, 129);
}

h3.topic2{
    color: rgb(4, 23, 87);
}
</style>

<style scoped>
.dlg-mask {
    display: table;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}

.dlg-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.dlg-container {
    color: black;
    width: 450px;
    height: 300px;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-radius: 10px;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.dlg-header{
    font-size: 18px;
    border-radius: 10px 10px 0px 0px;
    background-color: #6cdbf7;
    padding: 10px;
    text-align: center;
}

.dlg-content {
    margin: 20px;
    margin-bottom: 0px;
    height: auto;
    width: 91%;
    min-height: 70px;
    font-size: 16px;
    text-align: center;
}

.dlg-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    min-height: 10px;
    border-radius: 0px 0px 10px 10px;
    background-color: #e9ecec;
    text-align: center;
}

button {
    font-size: 16px;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
}

button:hover {
    filter: brightness(80%);
}

.dlg-ok-button{
    background-color: #04AA6D;
    width: 40%;
}

.dlg-cancel-button {
    background-color: #f44336;
    width: 40%;
}

.dlg-ok-button, .dlg-cancel-button
{
    width: 40%;
    margin: 5px;
}

</style>