<template>
    <div>
        <slot></slot>
    </div>
</template>

<script >
import { decrypt, encrypt } from './lib-encrypt';

export default {

        props:{
            reload:{type:Boolean}
        },
        data() {
            return {
                LocalData: {
                    user_id : 0,
                    user_name : "",
                    user_email : "",
                    user_avatar_url : "",
                    user_signedUp: false,
                    user_signedIn: false,

                }
            }
        },
        mounted() {
            this.loadFromLocal();
        },
        watch: {
            reload(){
                this.loadFromLocal();
            },
            LocalData(newValue){
                //console.log('watch-LocalData',newValue);
                this.$emit('cbDataChanged',newValue);
            },

        },
        methods: {
            loadFromLocal(){
                // Decrypt
                let _decText  = decrypt(localStorage.udata);
                if ((_decText != undefined) && (_decText != "")){
                    this.LocalData = JSON.parse(_decText);
                    //console.log('U-LocalData Loaded',this.LocalData);
                    this.$emit('cbDataLoaded',this.LocalData);
                } else {
                    console.log('U-LocalData = ไม่มีข้อมูล',);
                }
            },
            saveToLocal(JsonObj){
                // Encrypt
                //console.log('U-saveToLocal',);
                const _encText =  encrypt(JSON.stringify(JsonObj));
                localStorage.setItem('udata',_encText);
            },
            getValue(key){
                return this.LocalData[key];
            },
            setValue(key,value){
                this.LocalData[key] = value;
                this.saveToLocal(this.LocalData);
            },

        },

    }
</script>