<template>
  <div class="pagenodata">
    <h1 class="h1-big1">204</h1>
    <h1 class="h1-big2">No Content</h1>
    <p>ในหมวดนี้ ยังไม่มีการโพสต์ข้อมูล ครับ </p>
    <p>
      <br/>
      <router-link to="/"> กลับหน้าแรก Home </router-link>
    </p>
  </div>
</template>

<script>
    export default {
        datas(){
            return {
                canShow: false,
            }
        },

        mounted() {
            this.canShow = true
        },

        unmounted() {
            this.canShow = false
        },

        methods: {

        },


    }
</script>

<style scoped>

.pagenodata {
  margin: auto;
  text-align: center;
  min-height: 420px;
}
.h1-big1{
  font-size: 24vh;
  color: brown;
}

.h1-big2{
  font-size: 6vh;
  color: rgb(20, 5, 105);
}

a{
  margin: 10px;
  padding: 8px;
  border-radius: 8px;
  outline: 5px solid rgba(4, 91, 253, 0.5);
  outline-offset: 0px;
  color: black;
}

a:hover{
  margin: 10px;
  padding: 8px;
  border-radius: 8px;
  outline: 5px solid #f86dbe;
  outline-offset: 0px;
  background-color: #f8cfe7;
}

</style>