<template>

    <router-link
        :to="{ name: `blogs.view`, params: { blog_id: blog_id } }" >
        <div class="myrow">
            <div class="mycol p-posts">
                <div class="p-img-mini">
                    <img
                        v-bind:src="blog_image_url"
                        alt="blog-image-top10"
                        class="blog-image-top10"
                    />
                </div>

                <div class="p-text">
                    <h5 class="blog-group"> {{ blog_group_code }} </h5>
                    <p class="blog-title"> {{ blog_title }} </p>
                </div>
            </div>
        </div>

    </router-link>

</template>

<script>

    export default {
        props: {
            blog_id: { type: Number, required: true },
            blog_title: { type: String, required: true },
            blog_image_url: { type: String, required: true },
            blog_group_code: { type: String, required: true },
            blog_count_views: { type: Number, required: true },
        },

    };

</script>

<style scoped>
  @import "@/css/main.css";

  .blog-image-top10{
    width: 150px;
  }

  .mycol {
    padding: 10px;
    margin-bottom: 0px;
  }

  .mycol.p-posts{
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .blog-group{
    margin-left: 10px;
    font-size: 16px;
  }

  .blog-title{
    margin-left: 10px;
    font-size: 16px;
}

 </style>