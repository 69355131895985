<template>
    <CpnDataAuthor @cbDataLoaded="getAuthorDataLoaded" />

    <div class="sub-nav">
    <div class="sub-links">
        <!--
        * if status == loading > Show loading *
        -->
        <CpnLoader3Ball
                :loading="pageloading"
                :loadingtext="loadingtext" />

        <span class="group-links" v-for="(group,index) in groups" :key="index">

            <span v-if="this.work_group_code != group.group_code">
                <span v-if="group.is_link_external == 'Y'" >
                    <CpnAppLink :thisLink_is_ExternalLink=true  :to="group.url_external_link" > {{ group.group_name }}
                    </CpnAppLink>
                </span>
                <span v-else>
                    <CpnAppLink :to="{ name: 'group.sub' ,
                            params: {group_code: group.group_code ,
                                    group_owner: group.group_owner,
                                    key: index } }"
                            >
                            {{ group.group_name }}
                    </CpnAppLink>
                </span>
            </span>
            <span v-else class="active-link">
                <slot class="slot-header" >{{ group.group_name }}</slot>
            </span>
        </span>

        {{ AuthorData.author_signed_in }}
        <span class="contrainer-right" v-show="AuthorData.author_signedIn == true">
            <a href="/blogcontrol">จัดการแชร์ข้อมูล / เนื้อหา</a>
        </span>


    </div>
</div>

</template>



<script>
    import CpnAppLink from './cpn-applink.vue';
    import { HttpGet, ValidateToken,} from "@/components/lib-http-helper";
    import apiConfig from "@/components/api-config";
    import CpnLoader3Ball from '@/components/cpn-loader-3ball.vue';
    import CpnDataAuthor from './cpn-data-author.vue';

    export default {
        components: { CpnAppLink, CpnLoader3Ball, CpnDataAuthor },
        props:{
            group_code : {type: String},
            group_owner: {type: String},
            group_title: {type: String},
        },

        data() {
            return {
                AuthorData: Object,
                groups: [],
                loadingtext: "SubGroup Navigator",
                pageloading: false,
                work_group_owner: "",
                work_group_code: "",
            }
        },

        mounted() {
            //console.log("mounted-params-subgroup ",this.$route.params);
            this.work_group_code  = this.$route.params.group_code
            this.work_group_owner = this.$route.params.group_owner
            //console.log("mounted-params-nav-subgroup ",this.work_group_owner,this.work_group_code);
            this.getSubMenu();
        },
        watch:{
            group_code(newvalue){
                console.log('watch - group_code >> ',newvalue);
                this.getSubMenu();
            },
        },
        methods: {
            getAuthorDataLoaded(data){
                this.AuthorData = data;
                //console.log('getAuthorDataLoaded >> ',data);
            },

            async getSubMenu() {
                this.pageloading = true;
                await ValidateToken('nav-getSubMenu').then((success) => {
                    if (success){
                        //console.log("nav-getSubMenu -start",this.work_group_owner+'/'+this.work_group_code);
                        HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_SubGroup+'/'+this.work_group_owner+'/'+this.group_code ,'All SubGroup')
                            .then(
                                (response) => {
                                    //console.log("result nav-getSubMenu >> ",response.data);
                                    this.groups = response.data;
                                    this.pageloading = false;
                                }
                            );

                    }
                });
            },
        },
    }
</script>

<style >
    @import "@/css/main.css";

    .sub-nav{
        min-height: 50px;
        position: fixed;
        top: 50px;
        width: 100%;
    }

    .contrainer-right{
        position: absolute;
        /* margin: auto; */
        margin-right: 10px;
        right: 0px;
    }

    .active-link{
        color: red;
        border: 2px solid transparent;
        border-bottom-color: white;
        padding: 5px;
        font-size: 18px;
    }
</style>