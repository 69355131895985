<template>
    <CpnDataUser ref="UserDataCPN" @cbDataLoaded="getUserDataLoaded" />

    <CpnDialogService ref="DialogService"
        @cbDeleteAccount="getDeleteUserAccountOK"
        @cbPolicyOK="getPolicyOK"
        @cbPolicyCancel = "getPolicyCancel"
        @cbPopUpClosed = "getPopUpClosed"
        />

    <CpnCpnLoader3Ball
        :loading="pageloading"
        :loadingtext="loadingtext" />

    <div class="wrapper">
        <form  @submit.prevent="TryUpdateData" class="signup-form">
            <div class="signup-container">
                                <div class="box-small" >

                    <CpnToggle :value="IsEditMode" name="Edit"
                        callbackEvent="cbEditmodeChange"
                        @cbEditValueChange="getEditmodeChang">แก้ไขข้อมูล</CpnToggle>

                    <button type="button" class="btndelete" @click="showDlgDeleteAccount">ลบแอคเคาน์</button>
                </div>
                <h1>ข้อมูลสมาชิก</h1>
                <hr>

                <div class="box40per" >
                    <div class="box-avatar-img">
                        <p> รูป Avatar ปัจจุบัน</p>

                        <img :src="calc_avatar_url" class="img_avatar" alt="member avatar"/>
                    </div>
                </div>

                <div class="box50per" >
                    <CpnSlideshows
                        :slideDatas="slides"
                        @userClickAvatar="doUserClickAvatar"
                        />
                </div>

                <div class="box100" ></div>

                <div class="box1" >
                <label for="username"><b>User Name</b></label>
                <input type="text" v-model="UserData.user_name"
                        :readonly="!IsEditMode"
                        @change="usernameChange(UserData.user_name)"
                        placeholder="Enter User Name" name="username" required >
                </div>

                <div class="box2" >
                <label for="psw"><b>Password (!= '' ? NeedUpdate)</b></label>
                <input type="password" v-model="UserData.user_psw"
                        :readonly="!IsEditMode"
                        @change="userpasswordChange(UserData.user_psw)"
                        placeholder="ถ้าไม่เท่ากับ '' = ต้องการอัพเดต" name="psw">
                </div>


                <div class="box3" >
                <label for="email"><b>Email</b></label>
                <input type="text" v-model="UserData.user_email"
                        :readonly="!IsEditMode"
                        @change="useremailChange(UserData.user_email)"
                        placeholder="Enter Email" name="email" required>
                </div>

                <div class="clearfix">
                    <button type="button" class="btncancel" @click="backToCurPage">ปิด</button>
                    <button type="submit" :disabled="!IsEditMode" class="btnsubmit">อัพเดต</button>
                </div>

            </div>
        </form>
        <br/>

            <p class="cookies-text">เว็บไซต์นี้ใช้ข้อมูลคุกกี้ เพื่อเพิ่มประสิทธิภาพในการทำงาน เพื่อทำข้อมูลสถิติ และ การตลาด อ่าน
                <a @click="showDlgPolicy" style="color:dodgerblue;cursor: pointer;">
                    ข้อตกลงและนโยบายเกี่ยวกับการใช้งานคุกกี้</a></p>

    </div>
    <div class="div-reserved-layout">
        </div>
</template>

<script>
    import apiConfig from '@/components/api-config';
    import libCommon, { isTrue } from '@/components/lib-common';
    import { HttpPost , HttpDelete, ValidateToken, HttpPut } from '@/components/lib-http-helper';
    import FormMail, { SendEmail } from '@/components/lib-sendemail';
    import CpnSlideshows from '@/components/cpn-slideshows.vue';
    import CpnCpnLoader3Ball from '@/components/cpn-loader-3ball.vue';
    import CpnToggle from '@/components/cpn-toggle.vue';
    import CpnDataUser from '@/components/cpn-data-user.vue';
    import CpnDialogService from '@/components/cpn-dialog-service.vue';



    export default {
        emits: ['cbDeleteAccount'],
        components: { CpnSlideshows, CpnCpnLoader3Ball, CpnToggle, CpnDataUser, CpnDialogService, },
        data() {
            return {
                UserData: Object,
                IsEditMode: false,
                pageloading: false,
                loadingtext: "อัพเดตข้อมูล ...",
                base_user_name: null,
                base_user_email: null,
                base_user_avatar_url: null,
                base_user_psw: null,

                update_user_name: null,
                update_user_email: null,
                update_user_avatar_url: null,
                update_user_psw: null,

            };
        },
        mounted(){
            console.log('mounted',this.UserData.user_signedIn);
            if (isTrue(this.UserData.user_signedIn) == false){
                console.log('redirect >> User not Login ');
                this.$router.push('home')
            }
        },
        methods: {
            getPolicyCancel(){
                this.$refs.UserDataCPN.setValue('accept_cookies',false);
                window.close();
            },
            getPolicyOK(){
                this.$refs.UserDataCPN.setValue('accept_cookies',true);
            },
            getEditmodeChang(value){
                this.IsEditMode = value;
                // console.log('getEditmodeChang',value);
                if (value == true){
                    this.$refs.DialogService.showPopUp("<h3>เข้าสู่โหมดแก้ไขข้อมูล</h3>",1500);
                }
            },
            showDlgPolicy() {
                this.$refs.DialogService.showDlgPolicy('cbPolicyOK','cbPolicyCancel');
            },
            backToCurPage() {
                this.$router.back();
            },

            doUserClickAvatar(url){
                if (this.IsEditMode){
                    console.log('doUserClickAvatar',url)
                    this.UserData.user_avatar_url = url;
                    this.update_user_avatar_url = url;
                }
            },

            usernameChange(newValue){
                console.log('usernameChange',newValue)
                if (newValue != ""){
                    this.update_user_name = newValue;
                }
            },

            userpasswordChange(newValue){
                console.log('userpasswordChange',newValue)
                if (newValue != ""){
                    this.update_user_psw = newValue;
                }
            },

            useremailChange(newValue){
                console.log('useremailChange',newValue)
                if (newValue != ""){
                    this.update_user_email = newValue;
                }
            },

            TryUpdateData(){
                /* Keep Change fields */
                if (this.UserData.user_name !=  this.base_user_name){
                    this.update_user_name = this.UserData.user_name;
                }

                if (this.UserData.user_email != this.base_user_email){
                    this.update_user_email = this.UserData.user_email;
                }

                if (this.UserData.user_psw != this.base_user_psw){
                    this.update_user_psw = this.UserData.user_psw;
                }

                if (this.UserData.user_avatar_url != this.base_user_avatar_url){
                    this.update_user_avatar_url = this.UserData.user_avatar_url;
                }

                //console.log('TryUpdateData name ',this.UserData.user_name);
                //console.log('TryUpdateData name ',this.base_user_name);
                this.checkAvailableUserName(this.UserData.user_name);
            },

            async checkAvailableUserName() {
                this.IsEditMode = false;
                this.pageloading = true;
                return await ValidateToken('user-info').then((success) => {
                    console.log("ValidateToken >> ", success);

                    /* มีการแก้ไขข้อมูล ? */
                    if ((this.UserData.user_name != this.base_user_name) ||
                        (this.UserData.user_email != this.base_user_email) ||
                        (this.UserData.user_psw != this.base_user_psw) ||
                        (this.UserData.user_avatar_url != this.base_user_avatar_url)){

                        // #1 CheckName
                        if (this.UserData.user_name == this.base_user_name){
                            // TEST updateData
                            this.updateUserData(
                                this.update_user_name,
                                this.update_user_email,
                                this.update_user_psw ,
                                this.update_user_avatar_url);

                            //this.TestSendMailAndUpdate();
                        } else
                        {
                            //checkNameOK = false;
                            let formdata = new FormData();
                            formdata.append("user_name", this.UserData.user_name);

                            // # CheckName If Request Update
                            HttpPost(apiConfig.URL_BASE_API() + apiConfig.EndPoint_CheckUserNameAvailable, formdata)
                                .then((response) => {
                                    //console.log("check-userName result >> ", response.data["result"]);
                                    if (response.status == 200){
                                    if (response.data["result"] == "available") {
                                        //console.log("checkNameOK>> ", checkNameOK);

                                        // TEST updateData
                                        this.updateUserData(
                                            this.update_user_name,
                                            this.update_user_email,
                                            this.update_user_psw ,
                                            this.update_user_avatar_url);

                                        //this.TestSendMailAndUpdate();
                                    }}
                                });
                        }

                    } else {
                        this.pageloading = false;
                        this.IsEditMode  = false;
                        this.$refs.DialogService.showPopUp("<h3>ไม่มีรายการอัพเดต</h3>",1500);
                    }
                });
            },

            async TestSendMailAndUpdate(){
                // #2 sendEmail
                this.message = "อัพเดตข้อมูล แอคเคาน์";
                SendEmail(FormMail.MEMBER,this.UserData.user_email, this.UserData.user_name, this.UserData.user_psw, this.message, this.from_name)
                                .then((success) => {
                                    console.log("sendEmail >> ", success);
                                    if (success) {
                                        // #3 updateData
                                        this.updateUserData(
                                            this.update_user_name,
                                            this.update_user_email,
                                            this.update_user_psw ,
                                            this.update_user_avatar_url);
                                    }
                                });

            },

            async updateUserData(update_user_name, update_user_email, update_user_psw,update_user_avatar_url) {

                //console.log("updateUser >> ");
                let formdata = new FormData();

                formdata.append("user_id", this.$refs.UserDataCPN.getValue('user_id'));
                formdata.append("user_name", update_user_name);
                formdata.append("user_email", null);
                formdata.append("user_password", null);
                formdata.append("user_avatar_url", null);

                if (update_user_name != "" && update_user_name != null){
                    formdata.set("user_name", update_user_name);
                }

                if (update_user_email != "" && update_user_email != null ){
                    formdata.set("user_email", update_user_email);
                }

                if (update_user_psw != "" && update_user_psw != null ){
                    formdata.set("user_password", update_user_psw);
                }

                if (update_user_avatar_url != "" && update_user_avatar_url != null ){
                    formdata.set("user_avatar_url", update_user_avatar_url);
                }

                //console.log("formdata >> ",formdata);
                //console.log("Post UpdateUser >> ");
                await HttpPut(apiConfig.URL_BASE_API() + apiConfig.EndPoint_UpdateUser, formdata)
                    .then((response) => {
                        console.log("UpdateUser response>> ",response);
                        if (response.status == 200){
                        if (response.data["result"] == "success") {
                            console.log("UpdateUser >> success");

                            this.$refs.UserDataCPN.saveToLocal(this.UserData);

                            this.IsEditMode = false;
                            this.pageloading= false,
                            this.showDlgPopUp();
                        }
                        else {
                            this.IsEditMode = false;
                            this.pageloading= false,
                            this.$refs.DialogService.showPopUp("อัพเดตข้อมูลสมาชิก [ไม่สำเร็จ] !!",2000)
                        }}
                    });
            },
            showDlgDeleteAccount(){
                this.$refs.DialogService.showDlgConfirm("ลบแอคเคาน์","ยืนยันการลบแอคเคาน์ ?","cbDeleteAccount");
            },
            async showDlgPopUp(){
                this.$refs.DialogService.showPopUp("อัพเดตข้อมูลสมาชิก [สำเร็จ] <br />ลงชื่อออกอัตโนมัติ",1500,'update');
            },
            getPopUpClosed(result){
                console.log("getPopUpClosed",result);
                if (result == "update"){
                    this.$router.push({ path: "/#"+ libCommon.RandomStr(10) });
                }
            },
            getDeleteUserAccountOK() {

                console.log('ConfirmDeleteAcc >> True');
                HttpDelete(apiConfig.URL_BASE_API()+apiConfig.EndPoint_DeleteUser+'/'+this.UserData.user_id)
                    .then((response)=>{

                        console.log('Delete User >> ',response);
                        if (response.status == 200){
                            if (response.data['result'] == 'success'){
                                localStorage.clear();
                                this.$router.push({ path: "/#"+ libCommon.RandomStr(10) });
                            }
                        }
                    })
            },
            getUserDataLoaded(data){
                console.log('user-info-loaded',data);
                this.UserData = data;
                this.base_user_name  = data.user_name;
                this.base_user_email = data.user_email;
                this.base_user_avatar_url = data.avatar_url;
                this.base_user_psw   = data.user_psw;

            },

        },
        computed: {
            calc_avatar_url(){
                console.log('UserData.user_avatar_url',apiConfig.URL_IMG_AVATAR() + "/" +this.UserData.user_avatar_url);
                return apiConfig.URL_IMG_AVATAR() + "/" + this.UserData.user_avatar_url;
            }
        },
    }
</script>

<style lang="css" scoped>

.box-small{
    max-width: 70%;
    width: 200px;
    float: right;
    margin: 5px;
}

.signup-form{
    margin-top: 50px;
}

.signup-container{
    display: block;
    margin:auto;
    padding: 20px;
    max-width: 50%;
    align-items: center;
    align-content: center;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px grey;
    background-color: #ddd;
}


* {box-sizing: border-box}

.box-avatar-img{
  max-width: 300px;
  position: relative;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 50px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid rgb(69, 69, 70);
  background-color: #a7d0fa;
}

.box40per {
width: 45%;
float: left;
margin-right: 10px;
margin-top: 22px;
}

.box50per {
width: 50%;
float: left;
}

.box1 {
width: 50%;
float: left;
}

.box2 {
width: 48%;
float: left;
margin-left: 5px;
}

.box3 {
width: 100%;
float: left;
}

.box100{
    width: 100%;
    float: left;
    height: 10px;
}


.cookies-text{
  margin: auto;
  text-align: center;
}

input {
width: 50%;
}

/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #fff;
}

input[type=text]:focus, input[type=password]:focus {
  background-color: #e4f4fc;
  outline: none;
}

input:read-only{
    background-color:#f0eeee;
}

hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}


input[type=checkbox]{
    width: 20px;
}

/* Set a style for all buttons */
button {
  background-color: #04AA6D;
  font-size: 16px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

button:hover {
  opacity:1;
}

/* Extra styles for the cancel button */
.btncancel ,.btndelete{
  padding: 14px 20px;
  background-color: #f44336;
}

/* Float cancel and signup buttons and add an equal width */
.btncancel, .btnsubmit {
  float: left;
  width: 50%;
}

.btnsubmit:disabled{
    background-color: grey;
    cursor: not-allowed;
}

/* Add padding to container elements */
.container {
  padding: 16px;
}

/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/* Change styles for cancel button and signup button on extra small screens */
@media screen and (max-width: 300px) {
  .btncancel, .btnsubmit {
     width: 100%;
  }
}

.img_avatar{
    width: 100px;
    border-radius: 50%;
}

.div-reserved-layout{
    height: 30px;
}

pre.policy-body{
    font-size: 16px;
}

.checkbox-container{
    text-align: left;
}

</style>