<template>
    <div class="wrapper">
        <CpnDataUser ref="UserDataCPN" @cbDataLoaded="getUserDataLoaded" />
        <div class="comment-container">

            <div class="tabs">
                <div class="tab">
                    <input checked type="radio" name="css-tabs" id="tab-1" class="tab-switch" >
                    <label for="tab-1" class="tab-label">แสดงความคิดเห็น</label>
                    <div class="tab-content">
                        <div class="comment-text-block-header">
                            <h3>แสดงความคิดเห็น</h3>
                            <div class="comment-text-block-header2">
                                <span>Reply : {{this.reply_to }} </span> &nbsp;
                                <span class="only-member">เฉพาะสมาชิกเท่านั้น !!</span>
                            </div>
                        </div>
                        <p> ลิงค์รูปได้จาก 3 เวบเท่านั้น
                            <a href="https://picz.in.th">https://picz.in.th </a>
                            <a href="https://pixabay.com">https://pixabay.com</a>
                            <a href="https://img.freepik.com">https://img.freepik.com</a>
                        </p>

                            <!-- /* Post New Comment */ -->
                            <md-editor v-model="textComment" language="en-US"
                                :toolbars= "['bold', 'underline',  'italic',  '-',  'strikeThrough',  'title','sub',
                                            'sup', 'quote',  'unorderedList',  'orderedList',  '-',  'codeRow',  'code',
                                            'link',  'image',  'table',  'mermaid',  'katex',  '-','fullscreen']"
                                previewTheme="github"
                                value=""
                                :showCodeRowNumber="false"
                                @onChange="onTextChange"
                                :placeholder= "this.calcComment_Data"
                            />

                            <div class="comment-buttons-box" v-show="calcuser_signedIn">
                                <button type="button" class="comment-btn-reset" @click="btnCommentCancel($event)" > ยกเลิก </button>
                                <button type="button" class="comment-btn-save" @click="btnCommentPostClick($event)"> บันทึก </button>
                            </div>
                            <!-- /* Post New Comment */ -->

                    </div>
                </div>

                <div class="tab">
                    <input type="radio" name="css-tabs" id="tab-2" class="tab-switch">
                    <label for="tab-2" class="tab-label">กฎ กติกา มารยาท การใช้เว็บบอร์ด</label>
                    <div class="tab-content">
                        <div class="rule-text-block">
                            <!-- คอมเมนต์ / โพสต์ -->
                            <h3>กฎ กติกา มารยาท การใช้เว็บบอร์ด</h3>
                            <ol>
                                <li><p>1. ห้ามโพสต์ข้อความ รูปภาพ หรือ สื่อใดๆ กล่าวพาดพิงหรือว่ากล่าวหรือกล่าวหา ชาติ ศาสนา พระมหากษัตริย์</p></li>
                                <li><p>2. ผู้มีสิทธิ์โพสต์ หรือ คอมเมนต์ ต้องสมัครเป็นสมาชิก โดยอ้างอิงจากอีเมลที่ใช้งานได้</p></li>
                                <li><p>3. ข้อมูลที่โพสต์ เป็นความรับผิดชอบของผู้โพสต์ ตามกฎหมายแต่เพียงผู้เดียว ไม่เกี่ยวกับทางเวบไซต์</p></li>
                                <li><p>4. ห้ามใช้คำหยาบคาย เสียดสี ว่ากล่าวให้ร้ายผู้อื่น</p></li>
                                <li><p>5. ห้ามโพสต์ ข้อมูลบิดเบือน ข่าวบิดเบือน โฆษณาชวนเชื่อ หลอกลวง ขายของ ชวนเล่นการพนัน ชวนลงทุน หรือ <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;แปะลิงค์ไปยังเว็บไซต์อื่นที่ไม่เกี่ยวข้อง</p></li>
                                <li><p>6. ห้ามโพสต์ หรือ คอมเมนต์ ด้วยข้อความ รูปภาพ หรือ สื่อใดๆ ที่ทำให้เกิดความเข้าใจผิด แตกแยก ชวนทะเลาะ <br/></p></li>
                                <li><p>7. ข้อความต่างๆ ในคอมเมนต์เกิดจากสมาชิก บันทึกข้อมูลเข้าในระบบ (บันทึกอัตโนมัติ) หากตรวจพบว่าไม่เป็น<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ไปตามกฎ กติกา มารยาท ในการใช้เว็บบอร์ด ผู้ดูแลระบบ จะดำเนินการแก้ไขหรือลบออกโดยเร็วที่สุด <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อตรวจพบหรือหลังได้รับแจ้ง</p></li>
                                <li><p>8. เคารพความเห็นของผู้อื่น ยึดหลักข้อเท็จจริง ตามหลักสากล หากมีข้อพิพาทเกิดขึ้น การตัดสินของ ผู้ดูแลระบบ ถือเป็นที่สุด</p></li>
                                <li><p>9. กฎกติกาอื่นๆ จะเป็นไปตาม `กฏระเบียบ/ข้อบังคับ` ของเว็บไซต์</p></li>
                            </ol>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>


</template>

<script>
    import { ref } from 'vue';
    import MdEditor from 'md-editor-v3';
    import 'md-editor-v3/lib/style.css';
    import { HttpPost, ValidateToken } from './lib-http-helper';
    import {GetDateTimeNowStr} from './lib-common';
    import apiConfig from './api-config';
    import CpnDataUser from './cpn-data-user.vue';

    export default {
        emits: ['cbCommentPostFinish','cbCommentPostCancel'],
        setup() {
            const textComment = ref("");
            return {textComment}
        },
        props:{
            listenToEvent: {type: String ,default:"cbCommentPostFinish", required: true},
            blog_id:{type: Number},
            author_id:{type: String},
            reply_to:{type: Number},
        },
        data() {
            return {
                UserData : Object,
            }
        },
        components:{ MdEditor, CpnDataUser },
        methods: {
            onTextChange(value){
                this.textComment = value;
                console.log('change-textComment',this.textComment)
            },
            btnCommentCancel(){
                this.textComment = "";
                this.$emit('cbCommentPostCancel',this.comment_code);
            },
            async btnCommentPostClick() {
                //console.log('post-textComment',this.textComment)

                if (this.textComment.length > 0){
                    await ValidateToken('comment-editor').then((success) => {
                        if (success){
                            let _comment_id = GetDateTimeNowStr();

                            let formdata = new FormData();
                            formdata.append("blog_id",parseInt(this.blog_id))
                            formdata.append("author_id",parseInt(this.author_id));
                            formdata.append("reply_to",parseInt(this.reply_to));
                            formdata.append("user_id",this.UserData.user_id);
                            formdata.append("comment_content",this.textComment);
                            formdata.append("comment_id",parseInt(_comment_id));

                            console.log("formdata",formdata)

                            HttpPost(apiConfig.URL_BASE_API()+apiConfig.EndPoint_PostComment,formdata)
                                .then((response)=>{
                                    console.log('response',response)
                                    this.textComment = "";
                                    if (response.status == 200){
                                        console.log('emit',this.listenToEvent);
                                        this.$emit(this.listenToEvent,response);
                                    }
                                });
                        }
                    });
                }
                else
                { alert('กรอบข้อความด้านซ้ายมือ ยังไม่มีข้อความ !!') }
            },
            getUserDataLoaded(data){
                this.UserData = data;
                // console.log('getUserDataLoaded--')
            },

        },
        computed: {
            calcuser_signedIn(){
                return this.UserData.user_signedIn;
            },
            calcComment_Data(){
                return "พิมพ์เนื้อหาตรงนี้ format MarkDown/HTML `comment`";
            },

        },
        mounted() {
            this.new_comment_id = GetDateTimeNowStr();
        },
    }
</script>

<style scoped>
.container{
  margin: auto;
  width: auto;
  padding: 10px;
  display: block;
  min-height: 300px;
}

.markdown-logo{
  float: left;
  width: 100px;
  margin: 10px;
  filter: drop-shadow(1px 2px 2px #04f8f8);
  -webkit-filter: drop-shadow(1px 2px 2px #04f8f8);

}

.text-block{
  display: block;
  line-height:80%;
}

.text-red{
  color: red;
}

.comment-container{

  background-color: #c6e1fc;
  border: 2px solid #87c1fc;
  border-radius: 10px;
  box-shadow: 4px 4px 7px 4px rgb(0 0 0 / 30%);
  padding: 20px;
  height: 700px;
}

.comment-text-block-header {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 0.5fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  text-align: left;
  margin: auto;
  align-items: baseline;
}

.comment-text-block-header2 {
    color: #000;
    text-align: right;
}

.only-member {
  background-color: #219ebc;
  color: white;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  justify-content: right;
}

.comment-buttons-box{
    height: 50px;
    margin-top: 10px;
    align-items: center;
    align-content: center;
    text-align: center;
    font-size: 16px;
}

.comment-btn-reset{
    width: 100px;
    height: 48px;
    margin: auto;
    font-size: 16px;
    background-color: #f44336;
    border: 0px;
    margin-right: 10px;
    cursor: pointer;
}

.comment-btn-save{
    width: 200px;
    height: 48px;
    margin: auto;
    font-size: 16px;
    background-color: #04AA6D;
    border: 0px;
    cursor: pointer;
}

.tab-header{
    width:100%;
    overflow:hidden;
    display:inline-block;
    background-color:#000!important;
    padding: 5px;
}

.tab-header:hover{
    color:#fff!important;
    background-color:#000!important
}

.rule-text-block{
    min-height: 0.5fr;
}


.tabs {
  position: relative;
  margin: 0;
  background: #c6e1fc;
  height: 14.75rem;
}
.tabs::before,
.tabs::after {
  content: "";
  display: table;
}
.tabs::after {
  clear: both;
}
.tab {
  float: left;
}
.tab-switch {
  display: none;
}
.tab-label {
  position: relative;
  display: block;
  line-height: 2.75em;
  height: 3em;
  padding: 0 1.618em;
  background: #a7d0fa;
  border-right: 0.125rem solid #979797;
  color: #000;
  cursor: pointer;
  top: 0;
  transition: all 0.25s;
}
.tab-label:hover {
  top: -0.25rem;
  transition: top 0.25s;
}
.tab-content {
  /* height: 12rem; */
  position: absolute;
  z-index: 1;
  top: 2.75em;
  left: 0;
  padding: 1.618rem;
  background: #fff;
  color: #2c3e50;
  border-bottom: 0.25rem solid #bdc3c7;
  opacity: 0;
  transition: all 0.35s;
  width: 100%;
}
.tab-switch:checked + .tab-label {
  background: #3c8fe2;
  color: #fff;
  border-bottom: 0;
  border-right: 0.125rem solid #87c1fc;
  transition: all 0.35s;
  z-index: 1;
  top: -0.0625rem;
}
.tab-switch:checked + label + .tab-content {
  z-index: 2;
  opacity: 1;
  transition: all 0.35s;
}


.tab-1{
    content: "checked";
}
.rule-text-block{
    width: 100%;

}
</style>

```
# Github
https://github.com/imzbf/md-editor-v3

Toolbar
    bold?: string;
    underline?: string;
    italic?: string;
    strikeThrough?: string;
    title?: string;
    sub?: string;
    sup?: string;
    quote?: string;
    unorderedList?: string;
    orderedList?: string;
    codeRow?: string;
    code?: string;
    link?: string;
    image?: string;
    table?: string;
    mermaid?: string;
    katex?: string;
    revoke?: string;
    next?: string;
    save?: string;
    prettier?: string;
    pageFullscreen?: string;
    fullscreen?: string;
    preview?: string;
    htmlPreview?: string;
    catalog?: string;
    github?: string;
    '-'?: string;
    '='?: string;

```
