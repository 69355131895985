<template>
    <CpnDialogService ref="DialogService"
        @cbPolicyOK="getPolicyOK"
        @cbPolicyCancel= "getPolicyCancel"
        @cbPopUpClosed= "getPopUpClosed"
    />

    <div class="wrapper">
        <form  @submit.prevent="TryAddNewAuthor" class="signup-form">
            <div class="signup-container">
                <h1>สมัครผู้แชร์ข้อมูล</h1><span>กรุณากรอกข้อมูลให้ครบถ้วน</span>
                <hr>

                <div class="container-avatar" >
                    <div class="box40per" >
                        <div class="box-avatar-img">
                            <p> รูป Avatar ปัจจุบัน</p>
                            <img :src="avatar_url" class="img_avatar" alt="avatar"/>
                        </div>
                    </div>

                    <div class="box50per" >
                        <CpnSlideshows  @userClickAvatar="doAuthorClickAvatar" />
                    </div>
                </div>

                <div class="container-input">
                    <div class="container-50x2" >
                    <div class="box45per" >
                    <label for="editAuthorname"><b>Name</b></label>
                    <input type="text" v-model="author_name" placeholder="Enter Author Name" name="editauthorname" required >
                    </div>

                    <div class="box45per" >
                    <label for="editemail"><b>Email</b> : ** ต้องใช้งานได้ !! **</label>
                    <input type="text" v-model="author_email" placeholder="Enter Email" name="editemail" required>
                    </div>
                    </div>

                    <div class="container-50x2" >
                        <div class="box45per" >
                            <label for="editpsw"><b>Password</b></label>
                            <input type="password" v-model="author_psw" placeholder="Enter Password" name="editpsw" required>
                        </div>

                        <div class="box45per" >
                            <label for="editpsw-repeat"><b>Repeat Password</b></label>
                            <input type="password" v-model="author_psw_repeat" placeholder="Repeat Password" name="editpsw-repeat" required>
                        </div>
                    </div>
                </div>

                <CpnImgCaptcha @cbNewImageCaptCHA="getCaptCHANewImage"  @cbInputTextChanged="getCaptCHAInputText" />

                <p>เว็บไซต์นี้ใช้ข้อมูลคุกกี้ เพื่อเพิ่มประสิทธิภาพในการทำงาน เพื่อทำข้อมูลสถิติ และ การตลาด <br />อ่าน <a @click="showDlgPolicy" style="color:dodgerblue;cursor: pointer;">ข้อตกลงและนโยบายการจัดการข้อมูล</a></p>

                <div class="container-footer">
                    <button type="button" class="btncancel" @click="backToCurPage">ยกเลิก</button>

                    <button type="submit" class="btnsubmit" >สมัครผู้แชร์ข้อมูล</button>
                </div>
            </div>
        </form>
    </div>
    <div class="div-reserved-layout">
        </div>
</template>

<script>
    import apiConfig from '@/components/api-config';
    import { HttpPost,ValidateToken } from '@/components/lib-http-helper';
    import FormMail, { SendEmail } from '@/components/lib-sendemail';
    import libCommon, { RandomStr } from '@/components/lib-common';
    import CpnSlideshows from '@/components/cpn-slideshows.vue';
    import CpnImgCaptcha from '@/components/cpn-img-captcha.vue';
    import CpnDialogService from '@/components/cpn-dialog-service.vue';

    export default {
        emits: ['cbPolicyOK','cbPolicyCancel','cbNewImageCaptCHA','cbInputTextChanged'],
        data() {
            return {
                author_name: "",
                author_email: "",
                author_psw: "",
                author_psw_repeat: "",
                author_avatar_url: "av01.png",
                new_opt: "",
                email_message: apiConfig.GREETING_NEW_USER,
                email_from_name: apiConfig.WEBSITE_NAME,
                CaptCHACodeGen: "",
                CaptCHAInputText: "",

            };
        },
        methods: {
            doAuthorClickAvatar(url){
                //console.log('doAuthorClickAvatar',url)
                this.author_avatar_url = url;
            },
            TryAddNewAuthor() {
                console.log("enter TryAddNewAuthor");

                if (this.CaptCHAInputText != this.CaptCHACodeGen){
                    this.$refs.DialogService.showDlgWarning("ระบบตรวจสอบ","ระบุข้อความไม่ตรงกับข้อความในภาพ");
                    return ;
                }

                if ((this.author_psw_repeat != this.author_psw) || (this.author_psw_repeat == "" || this.author_psw == "")) {
                    this.$refs.DialogService.showPopUp("รหัสผ่านหลัก และ รหัสผ่านยืนยันต้องเหมือนกัน !!");
                    return;
                }
                this.checkAvailableAuthorName(this.author_name);
            },
            async checkAvailableAuthorName() {
                console.log("enter checkAvailableAuthorName");
                return await ValidateToken('sign-up').then((success) => {
                    console.log("ValidateToken >> ", success);
                    /* Check Author-available */
                    let formdata = new FormData();
                    formdata.append("author_name", this.author_name);
                    formdata.append("author_email", this.author_email);

                    return HttpPost(apiConfig.URL_BASE_API() + apiConfig.EndPoint_CheckAuthorNameAvailable,formdata)
                        .then((response) => {
                            console.log("available-aname >> ", response);
                            console.log("result >> ", response.data["result"]);
                            if (response.status == 200){
                            if (response.data["result"] == "available") {
                                console.log("try sendEmail >> ");
                                this.new_opt = RandomStr(10);

                                /* Check Mail */
                                SendEmail(FormMail.MEMBER,this.author_email, this.author_name, this.author_psw, this.email_message, this.email_from_name ,this.new_opt)
                                    .then((success) => {
                                        console.log("sendEmail >> ", success);
                                        if (success) {
                                            this.addNewAuthor(this.author_name, this.author_email, this.author_psw ,this.author_avatar_url,this.new_opt);
                                        }
                                    });
                            }}
                        });
                });
            },
            addNewAuthor(author_name, author_email, author_psw , author_avatar_url ,otp) {
                console.log("addNewAuthor >> ");
                let formdata = new FormData();
                formdata.append("author_id", "NEW");
                formdata.append("author_name", author_name);
                formdata.append("author_email", author_email);
                formdata.append("author_password", author_psw);
                formdata.append("author_avatar_url", author_avatar_url);
                formdata.append("author_otp", otp);

                HttpPost(apiConfig.URL_BASE_API() + apiConfig.EndPoint_AddAuthor, formdata)
                    .then((response) => {
                        if (response.status == 200){
                        if (response.data["result"] == "success") {
                            localStorage.setItem("author_id", response.data["id"]);
                            this.$refs.DialogService.showPopUp("<h3>สร้างข้อมูลผู้แชร์ข้อมูล [เสร็จแล้ว]</h3>",1500,"AddAuthor");
                        }
                        else {
                            this.$refs.DialogService.showPopUp("<h3>สร้างข้อมูลผู้แชร์ข้อมูล [ไม่สำเร็จ]</h3>",1500);
                        }}
                    });
            },
            backToCurPage() {
                this.$router.back();
            },
            showDlgPolicy() {
                this.$refs.DialogService.showDlgPolicy('cbPolicyOK','cbPolicyCancel');
            },
            getPolicyCancel(){
                localStorage.setItem('accept_cookies',false);
                console.log("getPolicyCancel");
                window.close();
            },
            getPolicyOK(){
                localStorage.setItem('accept_cookies',true);
                console.log("getPolicyOK");
            },
            getPopUpClosed(result){
                console.log("getPopUpClosed",result);
                if (result == "AddAuthor"){
                    this.$router.push({ path: "/#"+ libCommon.RandomStr(10) });
                }
            },
            getCaptCHANewImage(code){
                this.CaptCHACodeGen = code;
            },
            getCaptCHAInputText(text){
                this.CaptCHAInputText = text;
            },
        },
        components: { CpnSlideshows, CpnImgCaptcha, CpnDialogService }
        ,
        computed: {
            avatar_url(){
                return apiConfig.URL_IMG_AVATAR() + "/" + this.author_avatar_url;
            }
        },
    }
</script>

<style lang="css" scoped>

.signup-form{
    margin-top: 50px;
    background-color: transparent;
}

.signup-container{
    display: block;
    margin:auto;
    padding: 20px;
    max-width: 50%;
    width: 45%;
    /* height: 1220px; */
    align-items: center;
    align-content: center;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0px 4px 5px 0px grey;
    background-color: rgb(221, 221, 221);
}



* {box-sizing: border-box}


.box-avatar-img{
  max-width: 300px;
  position: relative;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 50px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid rgb(69, 69, 70);
  background-color: #a7d0fa;
}


.box40per {
width: 40%;
float: left;
margin-right: 10px;
}


.box45per {
width: 45%;
float: left;
margin-right: 10px;
}


.box50per {
width: 50%;
float: left;
}


.container-avatar{
    width: 100%;
}


.container-input{
    display: grid;
    width: 100%;
}


.img_avatar{
    width: 100px;
    border-radius: 50%;
}


/* Full-width input fields */
input[type=text] ,input[type=password]{
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #fff;
}


input[type=text]:focus, input[type=password]:focus {
  background-color: #c7ebfd;
  outline: none;
}


hr {
  border: 1px solid #d1cfcf;
  margin-bottom: 25px;
}


/* Set a style for all buttons */
button {
  background-color: #04AA6D;
  font-size: 16px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  margin-left: 15px;
  border: none;
  cursor: pointer;
  width: 40%;
  opacity: 0.9;
}

button:hover {
  opacity:1;
}

/* Extra styles for the cancel button */
.btncancel {
  padding: 14px 20px;
  background-color: #f44336;
}


/* Clear floats */
.container-footer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color:#f1f1f1;
    align-content: center;
    text-align: center;
}

/* Change styles for cancel button and signup button on extra small screens */
@media screen and (max-width: 300px) {
    .btncancel, .btnsubmit {
        width: 100%;
    }
}

.div-reserved-layout{
    height: 30px;
}

</style>