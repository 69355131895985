<template>
    <div class="main-container">

        <div class="slide-header"> {{ textHeader }}</div>
        <div class="slideshow-container">


            <div class="slide-btn">
                <p>
                    <a class="prev" @click="plusSlides(-1)">&#10094;</a>
                </p>
            </div>

            <div class="slideControl fade" v-for="(slide,index) in slideDatas" :key="index">

                <img :src= "getImageFromServer(slide.url)"  @click="avatarClick(slide.url)" class="slide-img" style="height:110px">
                <p>[{{ index+1 }} / {{ slideDatas.length }}] :: ID-{{ slide.title }}</p>
            </div>

            <div class="slide-btn">
                <p>
                    <a class="next" @click="plusSlides(1)">&#10095;</a>
                </p>
            </div>

        </div>
        <div class="slide-footer"></div>
    </div>

    <!-- <br> -->
    <!-- The dots/circles -->
    <!-- <div style="text-align:center">
    <span class="dot" @click="currentSlide(1)"></span>
    <span class="dot" @click="currentSlide(2)"></span>
    <span class="dot" @click="currentSlide(3)"></span>
    </div> -->

</template>

<script>
    import apiConfig from './api-config';
    import {ref} from 'vue';

    export default {
        data() {
            return {
                textHeader: "เลือกรูป Avatar (เลื่อน & คลิ๊ก)",
                slideIndex: ref(1),
                slideDatas: [
                    {"url":'av01.png',"title":"01"},{"url":'av02.png',"title":"02"},
                    {"url":'av03.png',"title":"03"},{"url":'av04.png',"title":"04"},
                    {"url":'av05.png',"title":"05"},{"url":'av06.png',"title":"06"},
                    {"url":'av07.png',"title":"07"},{"url":'av08.png',"title":"08"},
                    {"url":'av09.png',"title":"09"},{"url":'av10.png',"title":"10"},
                    {"url":'av11.png',"title":"11"},{"url":'av12.png',"title":"12"},
                    {"url":'av13.png',"title":"13"},{"url":'av14.png',"title":"14"},
                    {"url":'av15.png',"title":"15"},{"url":'av16.png',"title":"16"},
                ]
            }
        },
        mounted() {
            this.showSlides(1)
        },
        methods: {
            getImageFromServer(img){
                return apiConfig.URL_IMG_AVATAR()+'/'+img;
            },

            plusSlides(n) {
                this.showSlides(this.slideIndex += n);
            },

            currentSlide(n) {
                this.showSlides(this.slideIndex = n);
            },

            showSlides(n) {
                let i;
                let slide = document.getElementsByClassName("slideControl");
                // let dots = document.getElementsByClassName("dot");
                if (n > this.slideDatas.length) {
                    this.slideIndex = 1
                }

                if (n < 1) {
                    this.slideIndex = this.slideDatas.length
                }

                for (i = 0; i < this.slideDatas.length; i++) {
                    slide[i].style.display = "none";
                }

                // for (i = 0; i < dots.length; i++) {
                //     dots[i].className = dots[i].className.replace(" active", "");
                // }
                slide[this.slideIndex-1].style.display = "block";
                // dots[this.slideIndex-1].className += " active";
            },

            avatarClick(url){
                this.$emit('userClickAvatar', url);
            }
        },
    }
</script>

<style lang="css" scoped>
* {box-sizing:border-box}

.main-container {
  max-width: 350px;
  position: relative;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid rgb(200, 200, 200);
}

/* Slideshow container */
.slideshow-container {
display: grid;
max-width: 350px;
position: relative;
padding: 10px;
padding-bottom: 0px;
grid-template-columns: 0.5fr 2fr 0.5fr;
grid-template-rows: 0.5fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
}

.slideControl {
  display: none;
  cursor: pointer;
}

.slide-img{
    border-radius: 50%;
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
}

.slide-header{
    padding: 8px;
}

/* Hide the images by default */

.slide-btn {
    align-self: center;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  /* position: absolute; */
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: rgb(4, 83, 252);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(107, 106, 106, 0.8);
}


/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
</style>