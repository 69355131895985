<template>
    <div class="wrapper">
        <CpnDataAuthor ref="AuthorDataObj" @cbDataLoaded="getAuthorDataLoaded"  />
        <CpnDialogService ref="DialogService" @cbPopUpClosed="getPopUpClosed" />

        <div class="blog-container">

            <div class="tabs">
                <div class="tab">
                    <input checked type="radio" name="css-tabs" id="tab-1" class="tab-switch" >
                    <label for="tab-1" class="tab-label">แก้ไขบทความ/โพสต์</label>
                    <div class="tab-content">

                        <div class="blog-text-block-header">
                            <h3>แก้ไขบทความ/โพสต์</h3>
                            <div class="blog-text-block-header2">
                                <span class="only-author">เฉพาะผู้แชร์ข้อมูลเท่านั้น !!</span>
                            </div>
                        </div>

                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_title"><b>หัวข้อ / Title </b></label>
                            </div>
                            <div class="column70" >
                                <input v-model="blog_datas.blog_title" class="box-input">
                            </div>
                        </div>

                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_subtitle"><b>หัวข้อย่อย / SubTitle </b></label>
                            </div>
                            <div class="column70" >
                                <input v-model="blog_datas.blog_subtitle" class="box-input">
                            </div>
                        </div>


                        <div class="row-input">
                            <div class="column30" >
                                <label for="main_groups"><b>หมวดหมู่เนื้อหา (หลัก) </b></label>
                            </div>
                            <div class="column70" >
                                <select id="main_groups" name="blog_group_main"
                                    @change="onMainGroupChange"
                                    v-model="blog_datas.blog_group_owner">
                                    <!-- v-model="selected_main_group"> -->
                                    <option v-for="row in group_mains" class="select-option"
                                        :key="row.group_code"
                                        :value="row.group_code">
                                        {{ row['group_name'] }}
                                    </option>
                                </select>
                            </div>
                        </div>


                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_group_code"><b>หมวดหมู่เนื้อหา (รอง) </b></label>
                            </div>
                            <div class="column70" >
                                <select id="sub_groups" name="blog_group_code"
                                @change="onSubGroupChange"
                                v-model="blog_datas.blog_group_code">

                                    <option v-for="row in group_owners" class="select-option"
                                        :key="row.group_code"
                                        :value="row.group_code">
                                        {{ row['group_name'] }}
                                    </option>
                                </select>
                            </div>
                        </div>


                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_can_comment"><b>อนุญาตให้คอมเมนต์ </b></label>
                            </div>
                            <div class="column70" >
                                <select id="can_comment"
                                    @change="onCanCommentChange"
                                    v-model="blog_datas.blog_can_comment">
                                >
                                        <option value="Y">ได้</option>
                                        <option value="N">ไม่ได้</option>
                                </select>
                            </div>
                        </div>


                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_image_url"><b>URL ภาพหัวข้อ </b></label>
                            </div>
                            <div class="column70" >
                                <input v-model="blog_datas.blog_image_url" class="box-input">
                            </div>
                        </div>

                        <p class="container-img-link"> ลิงค์รูปได้จาก 3 เวบเท่านั้น &nbsp;* ให้ใช้ลิงค์ที่อ้างถึง นามสกุลไฟล์ได้ยิ่งดี *
                            <br />
                                    <span> <a href="https://picz.in.th" target="_blank" rel="noopener" class="customlink">https://picz.in.th (upload ได้) &nbsp;&nbsp;</a> </span>
                                    <span> <a href="https://pixabay.com" target="_blank" rel="noopener" class="customlink">https://pixabay.com &nbsp;&nbsp;</a> </span>
                                    <span> <a href="https://www.freepik.com/" target="_blank" rel="noopener" class="customlink">https://freepik.com</a> </span>
                                </p>

                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_tag"><b>แท็ก / Tag </b></label>
                            </div>
                            <div class="column70" >
                                <input v-model="blog_datas.blog_tag" class="box-input">
                            </div>
                        </div>



                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_source"><b>อ้างอิงแหล่งข้อมูล / Source </b></label>
                            </div>
                            <div class="column70" >
                                <input v-model="blog_datas.blog_source" class="box-input">
                            </div>
                        </div>



                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_package_code"><b>รหัสแพ็กเกจ (กรณีเก็บเงิน) </b></label>
                            </div>
                            <div class="column70" >
                                <input v-model="blog_datas.blog_package_code" class="box-input">
                            </div>
                        </div>


                        <div class="row-input">
                            <div class="column30" >
                                <label for="blog_playlist"><b>รหัสซีรี่ย์ / บทความชุด </b></label>
                            </div>
                            <div class="column70" >
                                <input v-model="blog_datas.blog_playlist" class="box-input">
                            </div>
                        </div>

                        <!-- /* Post New Blog */ -->
                        <div class="md-editor">
                        <md-editor v-model="blog_datas.blog_content" language="en-US"
                                :toolbars= "['bold', 'underline',  'italic',  '-',  'strikeThrough',  'title','sub',
                                            'sup', 'quote',  'unorderedList',  'orderedList',  '-',  'codeRow',  'code',
                                            'link',  'image',  'table',  'mermaid',  'katex',  '-','fullscreen','preview']"
                                previewTheme="github"
                                :showCodeRowNumber="false"
                                :showAdjust = "true"
                                @onChange="onTextChange"
                                :placeholder= "this.calcComment_Data"
                        />
                        </div>

                        <div class="blog-buttons-box" v-show="calcauthor_signedIn == true">
                                <button type="button" class="blog-btn-reset" @click="btnPostcancel($event)" > ยกเลิก </button>
                                <button type="button" class="blog-btn-submit"  @click="btnPostSubmit($event)"> อัพเดต </button>
                        </div>
                            <!-- /* Post New Comment */ -->
                    </div>
                </div>

                <div class="tab">
                    <input type="radio" name="css-tabs" id="tab-2" class="tab-switch">
                    <label for="tab-2" class="tab-label">กฎ กติกา มารยาท การใช้เว็บบอร์ด</label>
                    <div class="tab-content">
                        <div class="rule-text-block">
                            <!-- คอมเมนต์ / โพสต์ -->
                            <h3>กฎ กติกา มารยาท การใช้เว็บบอร์ด</h3>
                            <ol>
                                <li><p>1. ห้ามโพสต์ข้อความ รูปภาพ หรือ สื่อใดๆ กล่าวพาดพิงหรือว่ากล่าวหรือกล่าวหา ชาติ ศาสนา พระมหากษัตริย์</p></li>
                                <li><p>2. ผู้มีสิทธิ์โพสต์ หรือ คอมเมนต์ ต้องสมัครเป็นสมาชิก โดยอ้างอิงจากอีเมลที่ใช้งานได้</p></li>
                                <li><p>3. ข้อมูลที่โพสต์ เป็นความรับผิดชอบของผู้โพสต์ ตามกฎหมายแต่เพียงผู้เดียว ไม่เกี่ยวกับทางเวบไซต์</p></li>
                                <li><p>4. ห้ามใช้คำหยาบคาย เสียดสี ว่ากล่าวให้ร้ายผู้อื่น</p></li>
                                <li><p>5. ห้ามโพสต์ ข้อมูลบิดเบือน ข่าวบิดเบือน โฆษณาชวนเชื่อ หลอกลวง ขายของ ชวนเล่นการพนัน ชวนลงทุน หรือ <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;แปะลิงค์ไปยังเว็บไซต์อื่นที่ไม่เกี่ยวข้อง</p></li>
                                <li><p>6. ห้ามโพสต์ หรือ คอมเมนต์ ด้วยข้อความ รูปภาพ หรือ สื่อใดๆ ที่ทำให้เกิดความเข้าใจผิด แตกแยก ชวนทะเลาะ <br/></p></li>
                                <li><p>7. ข้อความต่างๆ ในคอมเมนต์เกิดจากสมาชิก บันทึกข้อมูลเข้าในระบบ (บันทึกอัตโนมัติ) หากตรวจพบว่าไม่เป็น<br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ไปตามกฎ กติกา มารยาท ในการใช้เว็บบอร์ด ผู้ดูแลระบบ จะดำเนินการแก้ไขหรือลบออกโดยเร็วที่สุด <br/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อตรวจพบหรือหลังได้รับแจ้ง</p></li>
                                <li><p>8. เคารพความเห็นของผู้อื่น ยึดหลักข้อเท็จจริง ตามหลักสากล หากมีข้อพิพาทเกิดขึ้น บนเว็บไซต์การตัดสินของ ผู้ดูแลระบบ ถือเป็นที่สุด</p></li>
                                <li><p>9. กฎกติกาอื่นๆ จะเป็นไปตาม `กฏระเบียบ/ข้อบังคับ` ของเว็บไซต์</p></li>
                            </ol>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import MdEditor from 'md-editor-v3';
    import 'md-editor-v3/lib/style.css';
    import apiConfig from './api-config';
    import { HttpGet, HttpPut, ValidateToken } from './lib-http-helper';
    import { RandomStr} from './lib-common';
    import CpnDataAuthor from './cpn-data-author.vue';
    import CpnDialogService from './cpn-dialog-service.vue';


    export default {
        emits: ['onPostBlogFinish','onPostBlogCancel'],
         props:{
            comment_code: {type: String},
            blog_id:{type: String},
            auth:{type: String},
            author_id:{type: String},
            reply_to:{type: Number},
        },
        data() {
            return {
                AuthorData : Object,
                group_mains: Object,
                group_owners: Object,
                blog_datas: Object,
                key4Edit: "",
            }
        },
        created(){
            document.addEventListener('beforeunload', this.handler);
        },
        mounted() {
            this.key4Edit = this.$refs.AuthorDataObj.getValue('keyedit');
            console.log('mounted getcurkey',this.key4Edit);
            console.log('params',this.$route.params);
            if (this.key4Edit == this.$route.params['key_id']){
                this.getGroupMain();
            }
            else
            {
                console.log('redirect >> Invalid keyID');
                this.$router.push('home')
            }
        },
        beforemounted(){
            // Create NewKey for re-use
            this.$refs.AuthorDataObj.newkeyEdit();
            this.$refs.AuthorDataObj.newkeyDelete();
        },
        components:{ MdEditor, CpnDataAuthor, CpnDialogService },
        methods: {
            doBeforeClose(){
                this.$refs.AuthorDataObj.newkeyEdit();
            },
            handler: function handler() {
                this.$refs.AuthorDataObj.newkeyEdit();
            },
            getKeyEdit(data){
                this.key4Edit = data;
                console.log('callback key4Edit',data);
                console.log('param',this.$route.params['key_id']);

                if ((this.key4Edit != this.$route.params['key_id']) || (this.key4Edit == undefined) || (this.key4Edit == "")){
                    console.log('Auth-key != key');
                    this.$router.replace({ path: "/#"+ RandomStr()});
                }
            },
            getPopUpClosed(trackValue){
                if (trackValue == "NewBlog"){
                    this.$router.replace({ path: "/#"+ RandomStr()});
                }
                if (trackValue == "invalid_token"){
                    this.$router.replace({ path: "/#"+ RandomStr()});
                }
            },
            onMainGroupChange(){
                // const value = document.getElementById('main_groups').value;
                // console.log('mainGroupChange-getvalue',value);
                console.log('selected_main_group',this.blog_datas.blog_group_owner);
                this.getGroupSub(this.blog_datas.blog_group_owner);
            },
            onSubGroupChange(){
                // const value = document.getElementById('main_groups').value;
                // console.log('mainGroupChange-getvalue',value);
                console.log('selected_sub_group',this.blog_datas.blog_group_code);

            },
            onCanCommentChange(){
                console.log('onCanCommentChange',this.blog_datas.blog_can_comment);
            },
            onTextChange(value){
                this.blog_datas.blog_content = value;
                console.log('change-blog_content',value)
            },

            btnPostcancel(){
                this.$emit('onPostBlogCancel',this.comment_code);
                this.$router.back();
            },
            async btnPostSubmit() {

                if (this.blog_datas.blog_content.length > 0){
                    await ValidateToken('blog-editor').then((success) => {
                        if (success){

                            let formdata = new FormData();

                            formdata.append("author_id",this.blog_datas.author_id);
                            formdata.append("blog_id",this.blog_datas.blog_id);
                            formdata.append("blog_date",this.blog_datas.blog_date);
                            formdata.append("blog_time",this.blog_datas.blog_time);
                            formdata.append("blog_title",this.blog_datas.blog_title);
                            formdata.append("blog_subtitle",this.blog_datas.blog_subtitle);
                            formdata.append("blog_content",this.blog_datas.blog_content);
                            formdata.append("blog_group_code",this.blog_datas.blog_group_code);
                            formdata.append("blog_group_owner",this.blog_datas.blog_group_owner);
                            formdata.append("blog_status",this.blog_datas.blog_status);
                            formdata.append("blog_can_comment",this.blog_datas.blog_can_comment);
                            formdata.append("blog_image_url",this.blog_datas.blog_image_url);
                            formdata.append("blog_tag",this.blog_datas.blog_tag);
                            formdata.append("blog_source",this.blog_datas.blog_source);
                            formdata.append("blog_package_code",this.blog_datas.blog_package_code);
                            formdata.append("blog_recommend",this.blog_datas.blog_recommend);
                            formdata.append("blog_playlist",this.blog_datas.blog_playlist);
                            // formdata.append("blog_playlist_order",0);


                            // console.log("author_id",this.AuthorData.author_id);
                            // console.log("blog_id",parseInt(_blog_id));
                            // console.log("blog_date",_blog_date);
                            // console.log("blog_time",_blog_time);
                            // console.log("blog_title",this.blog_title);
                            // console.log("blog_subtitle",this.blog_subtitle);
                            // console.log("blog_content",this.textInEditor);
                            // console.log("blog_group_code",this.selected_main_group);
                            // console.log("blog_group_owner",this.selected_sub_group);
                            // console.log("blog_status",'Y');
                            // console.log("blog_can_comment",this.selected_canComment);
                            // console.log("blog_image_url",this.blog_image_url);
                            // console.log("blog_tag",this.blog_tag);
                            // console.log("blog_source",this.blog_source);
                            // console.log("blog_package_code",this.blog_package_code);
                            // console.log("blog_recommend",'N');
                            // console.log("blog_playlist",this.blog_playlist);
                            // console.log("blog_playlist_order",0);


                            HttpPut(apiConfig.URL_BASE_API()+apiConfig.EndPoint_PostEditBlog,formdata)
                                .then((response)=>{
                                    console.log('response',response)
                                    if (response.status == 200){
                                        if (response.data["result"] == "success") {
                                            this.$refs.DialogService.showPopUp("<h3>สร้างบทความ/แชร์ข้อมูล [สำเร็จ]</h3>",1500,"NewBlog");
                                        } else {
                                            this.$refs.DialogService.showDlgError("สร้างบทความ/แชร์ข้อมูล","ไม่สำเร็จ !! "+response.data);
                                        }
                                    }
                                });
                        }
                    });
                }
                else
                { alert('กรอบข้อความด้านซ้ายมือ ยังไม่มีข้อความ !!') }
            },
            getAuthorDataLoaded(data){
                this.AuthorData = data;
                //console.log('getAuthorDataLoaded',data);
            },
            async getGroupMain(){
                // Check Token
                const url = apiConfig.URL_BASE_API()+apiConfig.EndPoint_MainGroup;
                await HttpGet(url,'getGroupMain').then((response)=>{
                    console.log('response > ',response.data);
                    this.group_mains = response.data;
                    //this.selected_group_main = "";
                    if (response.status == 200){
                        if (response.data.length > 0){
                            //this.selected_group_main = response.data[0]['group_code'];
                            //console.log('selected [0]> ',this.selected_group_main);

                            // Get Blog
                            this.getBlogDetail();

                            //this.getGroupSub(this.selected_group_main);
                        }
                    } else {
                        // Error Token
                        this.$refs.DialogService.showPopUp("ขั้นตอนการแก้ไขไม่ถูกต้อง !!",2000,"invalid_token");
                    }
                });
            },
            loadSubGroup(){
                //this.getGroupSub(this.selected_group_main);
            },
            async getGroupSub(group){
                console.log('getGroupSub > ',group);
                const url = apiConfig.URL_BASE_API()+apiConfig.EndPoint_SubGroup+'/all/'+group;
                await HttpGet(url).then((response)=>{
                    console.log('response > ',response.data);
                    this.group_owners = response.data;
                    this.selected_group_sub = "";
                    if (response.status == 200){
                        if (response.data.length > 0){
                            this.selected_group_sub = response.data[0]['group_code'];
                            console.log('selected sub [0]> ',this.selected_group_sub);
                        }
                    }
                });
            },
            async getBlogDetail(){
                console.log('getBlogDetail > ',);
                const url = apiConfig.URL_BASE_API()+apiConfig.EndPoint_blog_item_detail+'/'+this.blog_id;
                await HttpGet(url).then((response)=>{
                    console.log('response > ',response.data);
                    this.group_owners = response.data;
                    this.selected_group_sub = "";
                    if (response.status == 200){
                        // Array[0]
                        this.blog_datas = response.data[0];
                    }
                });
            },
         },
        computed: {
            calcauthor_signedIn(){
                return this.AuthorData.author_signedIn;
            },
            calcComment_Data(){
                return "พิมพ์เนื้อหาตรงนี้ format MarkDown/HTML `blog-edit`";
            },

        },
    }
</script>


<style scoped>
@import "@/css/custom-markdown2.css";

.container{
  margin: auto;
  width: auto;
  padding: 10px;
  display: block;
  min-height: 300px;
}

.markdown-logo{
  float: left;
  width: 100px;
  margin: 10px;
  filter: drop-shadow(1px 2px 2px #04f8f8);
  -webkit-filter: drop-shadow(1px 2px 2px #04f8f8);

}

.text-block{
  display: block;
  line-height:80%;
}

.text-red{
  color: red;
}

.blog-container{

    position: flex;
    margin: auto;
    margin-top: 50px;

    background-color: #c6e1fc;
    border: 2px solid #87c1fc;
    border-radius: 10px;
    box-shadow: 4px 4px 7px 4px rgb(0 0 0 / 30%);
    padding: 20px;
    width: 65%;
    height: 1200px;
    align-items: center;

}

.blog-text-block-header {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 0.5fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  text-align: left;
  margin: auto;
  align-items: baseline;
}

.blog-text-block-header2 {
    color: #000;
    text-align: right;
}

.only-author {
  background-color: #219ebc;
  color: white;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  justify-content: right;
}

.blog-buttons-box{

    height: 50px;
    margin-top: 10px;
    align-items: center;
    align-content: center;
    text-align: center;
    font-size: 16px;
}

.blog-btn-reset{
    width: 100px;
    height: 48px;
    margin: auto;
    font-size: 16px;
    background-color: #f44336;
    border: 0px;
    margin-right: 10px;
    cursor: pointer;
}

.blog-btn-submit{
    width: 200px;
    height: 48px;
    margin: auto;
    font-size: 16px;
    background-color: #04AA6D;
    border: 0px;
    cursor: pointer;
}

.tab-header{
    width:100%;
    overflow:hidden;
    display:inline-block;
    background-color:#000!important;
    padding: 5px;
}

.tab-header:hover{
    color:#fff!important;
    background-color:#000!important
}

.rule-text-block{
    min-height: 0.5fr;
}


.tabs {
  position: relative;
  margin: 0;
  background: #c6e1fc;
  height: 14.75rem;
}
.tabs::before,
.tabs::after {
  content: "";
  display: table;
}
.tabs::after {
  clear: both;
}
.tab {
  float: left;
}
.tab-switch {
  display: none;
}
.tab-label {
  position: relative;
  display: block;
  line-height: 2.75em;
  height: 3em;
  padding: 0 1.618em;
  background: #a7d0fa;
  border-right: 0.125rem solid #979797;
  color: #000;
  cursor: pointer;
  top: 0;
  transition: all 0.25s;
}
.tab-label:hover {
  top: -0.25rem;
  transition: top 0.25s;
}
.tab-content {
  position: absolute;
  z-index: 1;
  top: 2.75em;
  left: 0;
  padding: 1.618rem;
  background: #fff;
  color: #2c3e50;
  border-bottom: 0.25rem solid #bdc3c7;
  opacity: 0;
  transition: all 0.35s;
  width: 100%;
}
.tab-switch:checked + .tab-label {
  background: #3c8fe2;
  color: #fff;
  border-bottom: 0;
  border-right: 0.125rem solid #87c1fc;
  transition: all 0.35s;
  z-index: 1;
  top: -0.0625rem;
}
.tab-switch:checked + label + .tab-content {
  z-index: 2;
  opacity: 1;
  transition: all 0.35s;
}


.tab-1{
    content: "checked";
}
.rule-text-block{
    width: 100%;

}

.row-input {
  display: flex;
  margin: 10px;
  margin-left: 0px;
}


.column30 {
  flex: 30%;
}

.column70 {
  flex: 70%;
  font-size: 16px;
  padding: 5px;
}

.box-input{
    width: 80%;
    height: 30px;
    font-size: 16px;
}

select {
    width: 200px;
    height: 30px;
    line-height: 50px;
    font-size: 16px;
}

.select-option{
    font-size: 16px;
}

.customlink{
    color: #000;
}

</style>
