<template>
    <CpnDataUser ref="UserDataCPN" @cbDataLoaded="getUserDataLoaded"  >
        </CpnDataUser>

    <CpnDataAuthor @cbDataLoaded="getAuthorDataLoaded" >
        </CpnDataAuthor>

    <CpnOverlayBox />
    <CpnOverlayBoxUser />
    <CpnOverlayBoxAuthor />

    <CpnBannerBetaTest />

    <section id="home">
        <div class="myrow">
            <div class="mycol">
                <header class="top-intro">
                    <h1 class="page-header">
                        เรียนรู้ - ลอง - รัน - ปรับใช้
                    </h1>
                    <p>L2D : Learning { "Development" : "Testing & Running" } Deployment</p>
                </header>
            </div>
        </div>

        <section class="hero">
            <img src="@/assets/background.png" class="home-background">
        </section>

    </section>

    <div class="myrow-uneven">
            <div class="mycol-left">

                <!-- * MAIN - BLOG * -->
                <div class="container-blog">

                    <!-- * if status == loading > Show loading * -->
                    <CpnLoader3Ball
                        :loading="pageloading"
                        :loadingtext="loadingtext" />

                    <!-- * Loop ข้อมูลจากเซิฟ * -->
                    <div
                        class="blogs"
                        v-for="blog_1row in blog_datas"
                        :key="blog_1row['blog_id']"
                        >
                        <!-- blog_1row == {{ blog_1row }} -->
                        <CpnBlockBlogMain
                            :blog_title="blog_1row['blog_title']"
                            :blog_subtitle="blog_1row['blog_subtitle']"
                            :blog_content="blog_1row['blog_content']"
                            :blog_image_url="blog_1row['blog_image_url']"
                            :blog_id="blog_1row['blog_id']"
                            :blog_group_code="blog_1row['blog_group_code']"
                            :blog_date="blog_1row['blog_date']"
                            :blog_count_views="blog_1row['blog_count_views']"
                            :author_name="blog_1row['author_name']"
                            :author_avatar_url="blog_1row['author_avatar_url']"
                            />

                    </div>
                </div>
            </div>

            <!-- SIDEBAR -->
            <div class="mycol-right">

                <section class="popular-posts">
                    <header class="sidebar-header">
                        <h3 class="header-white">แนะนำ</h3>
                    </header>
                        <!-- * Top10 -- Loop ข้อมูลจากเซิฟ * -->
                        <div class="blogs-top10"
                            v-for="blog_top10 in blogTop10_datas" :key="blog_top10['blog_id']">
                            <CpnBlockBlogRecommend
                                :blog_id = "blog_top10['blog_id']"
                                :blog_title = "blog_top10['blog_title']"
                                :blog_image_url = "blog_top10['blog_image_url']"
                                :blog_group_code = "blog_top10['blog_group_code']"
                                :blog_count_views = "blog_top10['blog_count_views']"
                            />
                        </div>
                </section>


                <section class="recommend2">
                    <header class="sidebar-header">
                        <h3 class="header-white">บทความเป็นชุด</h3>
                    </header>

                </section>
            </div>

    </div>

</template>


<script>

    import CpnBlockBlogMain from '@/components/cpn-blockblog-main.vue';
    import CpnBlockBlogRecommend from '@/components/cpn-block-show-recommends.vue';
    import apiConfig from "@/components/api-config";
    import { HttpGet,ValidateToken, } from "@/components/lib-http-helper";
    import CpnLoader3Ball from '@/components/cpn-loader-3ball.vue';
    import CpnOverlayBox from '@/components/cpn-menu-overlay-box.vue';
    import CpnOverlayBoxUser from '@/components/cpn-menu-overlay-user.vue';
    import CpnOverlayBoxAuthor from '@/components/cpn-menu-overlay-author.vue';
    import CpnDataUser from '@/components/cpn-data-user.vue';
    import CpnDataAuthor from '@/components/cpn-data-author.vue';
    import CpnBannerBetaTest from '@/components/cpn-banner-betatest.vue';

    export default {
        components: { CpnBlockBlogMain, CpnLoader3Ball, CpnBlockBlogRecommend, CpnOverlayBox,
                      CpnOverlayBoxUser,CpnOverlayBoxAuthor, CpnDataUser, CpnDataAuthor, CpnBannerBetaTest },
        data() {
            return {
                blog_datas: [],
                blogTop10_datas: [],
                errors: [],
                table_blogs: "TBBlogs",
                table_authors: "TBAuthors",
                loadingtext: "โปรดรอ กำลังโหลดข้อมูล ...",
                pageloading: false,
                newRandomID:"",
                userLogin:null,
                UserData : Object,
                AuthorData: Object,
            };
        },
        props : {value : {type: String} },

        created() {

            this.getValues();
        },

        mounted() {

        },
        methods: {
            async getValues() {
                console.log('home get Token');
                await ValidateToken('home')
                    .then((success) => {
                    //console.log('home reseponse Token',success);
                    if (success) {
                        this.getAllBlogs();

                        this.getBlogsTop10AllGroup();
                    }

                    this.pageloading = false;
                });
            },

            async getAllBlogs() {

                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_BlogsHome ,'All Blog @Home').then(
                    (response) => {
                        this.blog_datas = response.data;
                    }
                );
            },

            async getBlogsTop10AllGroup() {
                this.blogTop10_datas = [];
                HttpGet(apiConfig.URL_BASE_API() + apiConfig.EndPoint_blog_top10+'/main/*','All Top10 @Home').then(
                    (response) => {
                        this.blogTop10_datas = response.data;
                    }
                );
            },
            getUserDataLoaded(data){
                //console.log('home-user-loaded-2',data);
                this.UserData = data;
            },

            getAuthorDataLoaded(data){
                //console.log('home-user-loaded-2',data);
                this.AuthorData = data;
            },

        },
    };

</script>

<style>
  @import "@/css/main.css";
  @import "@/css/custom-markdown.css";

  h1,h2,h3,h4,h5,h6{
    color: black;
  }
  .header-white{
    color: white;
  }

  div.blogs{
    background-color: white;
    margin-bottom: 20px;
  }

  .post-btm-bar{
    padding: 10px;
  }

  /* .blog-content-subtitle{
    background-color: #FAFAFA;
  }

  .blog-content-markdown{
    background-color: #FAFAFA;
  } */

  .blog-group{
    color: black;
  }

  .blog-title{
    color: black;
  }

  .popular-posts{
      margin: 0px;
      display: block;
  }
</style>ฟ